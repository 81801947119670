/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./integration-resource-list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/yti-common-ui/components/expandable-text.component.ngfactory";
import * as i3 from "yti-common-ui/components/expandable-text.component";
import * as i4 from "../../../../node_modules/yti-common-ui/components/status.component.ngfactory";
import * as i5 from "yti-common-ui/components/status.component";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./integration-resource-list-item-component";
import * as i9 from "../../services/configuration.service";
import * as i10 from "../../services/language.service";
var styles_IntegrationResourceListItemComponent = [i0.styles];
var RenderType_IntegrationResourceListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegrationResourceListItemComponent, data: {} });
export { RenderType_IntegrationResourceListItemComponent as RenderType_IntegrationResourceListItemComponent };
function View_IntegrationResourceListItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "description-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-expandable-text", [], null, null, null, i2.View_ExpandableTextComponent_0, i2.RenderType_ExpandableTextComponent)), i1.ɵdid(2, 49152, null, 0, i3.ExpandableTextComponent, [], { text: [0, "text"], rows: [1, "rows"], captureClick: [2, "captureClick"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.ngIf; var currVal_1 = 2; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_IntegrationResourceListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "resource-result"]], [[8, "id", 0], [2, "last", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "scrollable-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-status", [["class", "status"]], null, null, null, i4.View_StatusComponent_0, i4.RenderType_StatusComponent)), i1.ɵdid(3, 49152, null, 0, i5.StatusComponent, [], { status: [0, "status"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emitSelectResourceEvent(_co.resource) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntegrationResourceListItemComponent_2)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(10, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Last modification"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [": ", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "a", [["class", "uri"], ["rel", "noopener noreferrer"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.resource.status; _ck(_v, 3, 0, currVal_2); var currVal_4 = _co.resource.getDescription(_co.languageService, true); _ck(_v, 7, 0, currVal_4); var currVal_5 = ""; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.resource.id + "_resource_link"), ""); var currVal_1 = _co.theLast; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.resource.getDisplayName(_co.languageService, true); _ck(_v, 5, 0, currVal_3); var currVal_6 = _co.resource.modifiedDisplayValue; _ck(_v, 13, 0, currVal_6); var currVal_7 = i1.ɵinlineInterpolate(1, "", _co.configurationService.getUriWithEnv(_co.resource.uri), ""); _ck(_v, 15, 0, currVal_7); var currVal_8 = _co.configurationService.getUriWithEnv(_co.resource.uri); _ck(_v, 16, 0, currVal_8); }); }
export function View_IntegrationResourceListItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_IntegrationResourceListItemComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resource.expanded; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IntegrationResourceListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-integration-resource-list-item", [], null, null, null, View_IntegrationResourceListItemComponent_0, RenderType_IntegrationResourceListItemComponent)), i1.ɵdid(1, 245760, null, 0, i8.IntegrationResourceListItemComponent, [i9.ConfigurationService, i10.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntegrationResourceListItemComponentNgFactory = i1.ɵccf("app-integration-resource-list-item", i8.IntegrationResourceListItemComponent, View_IntegrationResourceListItemComponent_Host_0, { resource: "resource", theLast: "theLast", expanded: "expanded", selectedResources$: "selectedResources$" }, { selectResourceEvent: "selectResourceEvent" }, []);
export { IntegrationResourceListItemComponentNgFactory as IntegrationResourceListItemComponentNgFactory };
