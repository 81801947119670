
    <dl *ngIf="editing || selectableOrganizations.length > 0">
      <dt>
        <label>{{label}}</label>
        <app-information-symbol [infoText]="infoText"></app-information-symbol>
        <app-required-symbol *ngIf="required && editing"></app-required-symbol>
      </dt>
      <dd>
        <div *ngIf="!editing">
          <div *ngFor="let organization of selectableOrganizations">
            <span>{{organization.prefLabel | translateValue:true}}</span>
          </div>
        </div>
        <div *ngIf="editing">
          <div *ngFor="let organization of selectableOrganizations">
            <a class="removal-X">
              <i [id]="'remove_' + organization.getIdIdentifier(languageService) + '_organization_link'"
                 class="fa fa-times"
                 (click)="removeOrganization(organization)"></i>
            </a>
            <span>{{organization.getDisplayName(languageService, true)}}</span>
          </div>
          <app-error-messages id="organization_error_messages" [control]="parentControl"></app-error-messages>
        </div>

        <button id="add_organization_button"
                type="button"
                class="btn btn-sm btn-action mt-2"
                *ngIf="editing"
                (click)="addOrganization()">
          <span translate>Add organization</span>
        </button>
      </dd>
    </dl>
  