import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, concat } from 'rxjs';
import { LanguageService } from '../../services/language.service';
import { contains } from 'yti-common-ui/utils/array';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { DataService } from '../../services/data.service';
import { debounceTime, map, skip, take, tap } from 'rxjs/operators';
import { containerTypes } from '../common/containertypes';
import { TranslateService } from '@ngx-translate/core';
import { allStatuses } from 'yti-common-ui/entities/status';
import { ConfigurationService } from '../../services/configuration.service';
import { comparingLocalizable, comparingPrimitive } from 'yti-common-ui/utils/comparator';
var SearchLinkedContainerModalComponent = /** @class */ (function () {
    function SearchLinkedContainerModalComponent(configurationService, modal, languageService, dataService, translateService) {
        this.configurationService = configurationService;
        this.modal = modal;
        this.languageService = languageService;
        this.dataService = dataService;
        this.translateService = translateService;
        this.status$ = new BehaviorSubject(null);
        this.containerType$ = new BehaviorSubject(null);
        this.search$ = new BehaviorSubject('');
        this.searchResults$ = new BehaviorSubject([]);
        this.loading = false;
    }
    SearchLinkedContainerModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchLabel = this.translateService.instant('Search term');
        this.containerTypeOptions = [null].concat(containerTypes).map(function (containerType) { return ({
            value: containerType,
            name: function () { return _this.translateService.instant(containerType ? containerType : 'Select tool'); },
            idIdentifier: function () { return containerType ? containerType : 'select_source_container_type'; }
        }); });
        this.statusOptions = [null].concat(allStatuses).map(function (status) { return ({
            value: status,
            name: function () { return _this.translateService.instant(status ? status : 'All statuses'); },
            idIdentifier: function () { return status ? status : 'all_selected'; }
        }); });
        this.titleLabel = this.translateService.instant('Select source');
        this.instructionText = this.translateService.instant('HELP_TEXT_COMMENTROUND_SOURCE_MODAL_INSTRUCTION');
        this.containerType$.subscribe(function (selectedContainerType) {
            if (selectedContainerType != null) {
                _this.loading = true;
                _this.dataService.getContainers(selectedContainerType, _this.languageService.language).subscribe(function (resources) {
                    _this.resources = resources;
                    _this.filterResources();
                });
            }
        });
    };
    Object.defineProperty(SearchLinkedContainerModalComponent.prototype, "hasContent", {
        get: function () {
            return this.searchResults$.getValue().length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchLinkedContainerModalComponent.prototype, "hasContainerType", {
        get: function () {
            return this.containerType$.getValue() != null || this.containerType != null;
        },
        enumerable: true,
        configurable: true
    });
    SearchLinkedContainerModalComponent.prototype.filterResources = function () {
        var _this = this;
        this.loading = true;
        this.searchResults$.next([]);
        var initialSearch = this.search$.pipe(take(1));
        var debouncedSearch = this.search$.pipe(skip(1), debounceTime(500));
        combineLatest(this.status$, concat(initialSearch, debouncedSearch))
            .pipe(tap(function () { return _this.loading = false; }), map(function (_a) {
            var status = _a[0], search = _a[1];
            return _this.resources.filter(function (integrationResource) {
                var label = _this.languageService.translate(integrationResource.prefLabel, true);
                var searchMatches = !search ||
                    label.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (integrationResource.uri !== undefined && integrationResource.uri.toLowerCase().indexOf(search.toLowerCase()) !== -1);
                var isNotRestricted = !contains(_this.restricts, integrationResource.uri);
                var statusMatches = !status || integrationResource.status === status;
                return searchMatches && isNotRestricted && statusMatches;
            }).sort(comparingPrimitive(function (integrationResource) { return _this.languageService.isLocalizableEmpty(integrationResource.prefLabel); })
                .andThen(comparingPrimitive(function (integrationResource) {
                return _this.languageService.isLocalizableEmpty(integrationResource.prefLabel) ? integrationResource.uri.toLowerCase() : null;
            }))
                .andThen(comparingLocalizable(_this.languageService, function (integrationResource) { return integrationResource.prefLabel ? integrationResource.prefLabel : {}; })));
        })).subscribe(function (results) {
            _this.searchResults$.next(results);
        });
    };
    SearchLinkedContainerModalComponent.prototype.select = function (resource) {
        resource.type = this.containerType$.value ? this.containerType$.value : undefined;
        this.modal.close(resource);
    };
    SearchLinkedContainerModalComponent.prototype.ngAfterViewInit = function () {
        this.searchInput.nativeElement.focus();
    };
    Object.defineProperty(SearchLinkedContainerModalComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    SearchLinkedContainerModalComponent.prototype.cancel = function () {
        this.modal.dismiss('cancel');
    };
    return SearchLinkedContainerModalComponent;
}());
export { SearchLinkedContainerModalComponent };
var SearchLinkedContainerModalService = /** @class */ (function () {
    function SearchLinkedContainerModalService(modalService) {
        this.modalService = modalService;
    }
    SearchLinkedContainerModalService.prototype.open = function (containerType, restrictedResourceUris, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        var modalRef = this.modalService.open(SearchLinkedContainerModalComponent, { size: 'lg' });
        var instance = modalRef.componentInstance;
        instance.containerType = containerType;
        instance.restricts = restrictedResourceUris;
        instance.useUILanguage = useUILanguage;
        return modalRef.result;
    };
    return SearchLinkedContainerModalService;
}());
export { SearchLinkedContainerModalService };
