
    <dl *ngIf="editing || resource">
      <dt>
        <label>{{label}}</label>
        <app-information-symbol [infoText]="infoText"></app-information-symbol>
        <app-required-symbol *ngIf="required && editing"></app-required-symbol>
      </dt>
      <dd>
        <div *ngIf="!editing && resource">
          <app-literal-multilanguage [value]="resource.prefLabel"></app-literal-multilanguage>
        </div>
        <div *ngIf="editing && resource">
          <a class="removal-X">
            <i id="remove_resource_link"
               class="fa fa-times"
               (click)="removeSource()"></i>
          </a>
          <span>{{resource.getDisplayName(languageService, false)}}</span>
        </div>

        <app-error-messages id="source_error_messages" [control]="parentControl"></app-error-messages>

        <button *ngIf="editing"
                id="add_resource_button"
                type="button"
                class="btn btn-sm btn-action mt-2"
                (click)="selectSource()">
          <span translate>Select resource</span>
        </button>
      </dd>
    </dl>
  