
    <dl>
      <dt>
        <label>{{label}}</label>
        <app-information-symbol [infoText]="infoText"></app-information-symbol>
        <app-required-symbol *ngIf="required && editing"></app-required-symbol>
      </dt>
      <dd>
        <div *ngIf="!editing">
          <div *ngFor="let tempUser of tempUsers">
            <span *ngIf="tempUser.email != null">{{tempUser.getDisplayName()}}</span>
          </div>
        </div>
        <div *ngIf="editing">
          <div *ngFor="let tempUser of tempUsers">
            <div *ngIf="tempUser.email != null">
              <a class="removal-X">
                <i [id]="'remove_' + tempUser.email + '_tempuser_link'"
                   class="fa fa-times"
                   (click)="removeTempUser(tempUser)"></i>
              </a>
              <span>{{tempUser.getDisplayName()}}</span>
            </div>
          </div>
          <app-error-messages id="tempuser_error_messages" [control]="parentControl"></app-error-messages>
        </div>
        <button id="add_tempuser_button"
                type="button"
                class="btn btn-sm btn-action mt-2"
                *ngIf="editing"
                (click)="addTempUser()">
          <span translate>Add user</span>
        </button>
      </dd>
    </dl>
  