import { EventEmitter, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { CommentSimple } from '../../entities/comment-simple';
import { DataService } from '../../services/data.service';
import { v4 as uuid } from 'uuid';
import { CommentsErrorModalService } from './error-modal.service';
import { BehaviorSubject } from 'rxjs';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { CommentsConfirmationModalService } from './confirmation-modal.service';
import { AuthorizationManager } from '../../services/authorization-manager';
import { CommentRound } from '../../entities/commentround';
import { TranslateService } from '@ngx-translate/core';
var HierarchicalCommentListitemComponent = /** @class */ (function () {
    function HierarchicalCommentListitemComponent(languageService, translateService, dataService, errorModalService, confirmationModalService, authorizationManager) {
        this.languageService = languageService;
        this.translateService = translateService;
        this.dataService = dataService;
        this.errorModalService = errorModalService;
        this.confirmationModalService = confirmationModalService;
        this.authorizationManager = authorizationManager;
        this.activeCommentId$ = new BehaviorSubject(null);
        this.refreshComments = new EventEmitter();
        this.expandComment = new EventEmitter();
        this.collapseComment = new EventEmitter();
        this.commenting = false;
        this.commentContent = '';
        this.updating = false;
    }
    HierarchicalCommentListitemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activeCommentId$.subscribe(function (activeCommentId) {
            if (activeCommentId !== _this.comment.id) {
                _this.commentContent = '';
                _this.commenting = false;
            }
        });
    };
    HierarchicalCommentListitemComponent.prototype.toggleCommenting = function () {
        if (this.commenting) {
            this.commentContent = '';
        }
        if (!this.commenting) {
            this.commentContent = '';
            this.activeCommentId$.next(this.comment.id);
        }
        this.commenting = !this.commenting;
    };
    HierarchicalCommentListitemComponent.prototype.toggleUpdatingComment = function () {
        if (this.updating) {
            this.commentContent = '';
        }
        if (!this.updating) {
            this.commentContent = '';
            this.activeCommentId$.next(this.comment.id);
        }
        this.updating = !this.updating;
    };
    HierarchicalCommentListitemComponent.prototype.updateComment = function () {
        var _this = this;
        var commentToUpdate = {
            commentThread: { id: this.commentThreadId },
            content: this.comment.content,
            parentComment: this.comment.parentComment ? this.comment.parentComment.serialize() : null,
            id: this.comment.id
        };
        this.dataService.updateComment(this.commentRound.id, commentToUpdate).subscribe(function (updatedComment) {
            _this.toggleUpdatingComment();
            _this.comments.push(updatedComment);
            _this.refreshComments.emit(_this.commentThreadId);
        }, function (error) {
            _this.errorModalService.openSubmitError(error);
        });
    };
    HierarchicalCommentListitemComponent.prototype.deleteComment = function () {
        var _this = this;
        this.confirmationModalService.deleteComment()
            .then(function () {
            var newComment = {
                commentThread: { id: _this.commentThreadId },
                content: _this.commentContent,
                parentComment: _this.comment.parentComment ? _this.comment.parentComment.serialize() : null,
                id: _this.comment.id
            };
            _this.dataService.deleteComment(_this.commentRound.id, newComment).subscribe(function () {
                _this.refreshComments.emit(_this.commentThreadId);
            }, function (error) {
                _this.errorModalService.openSubmitError(error);
            });
        }, ignoreModalClose);
    };
    HierarchicalCommentListitemComponent.prototype.sendComment = function () {
        var _this = this;
        var id = uuid();
        var newComment = {
            commentThread: { id: this.commentThreadId },
            content: this.commentContent,
            parentComment: this.comment.serialize(),
            id: id
        };
        this.dataService.createComment(this.commentRound.id, newComment).subscribe(function (createdComment) {
            _this.toggleCommenting();
            _this.comments.push(createdComment);
            _this.refreshComments.emit(_this.commentThreadId);
            _this.emitExpandComment(_this.comment.id);
        }, function (error) {
            _this.errorModalService.openSubmitError(error);
        });
    };
    Object.defineProperty(HierarchicalCommentListitemComponent.prototype, "hasChildComments", {
        get: function () {
            return this.childComments && this.childComments.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HierarchicalCommentListitemComponent.prototype, "childComments", {
        get: function () {
            var _this = this;
            return this.comments.filter(function (comment) { return comment.parentComment != null && comment.parentComment.id === _this.comment.id; });
        },
        enumerable: true,
        configurable: true
    });
    HierarchicalCommentListitemComponent.prototype.canModifyOrDeleteInlineComment = function (comment) {
        return (this.authorizationManager.user.id === comment.user.id) &&
            this.commentRound.status === 'INPROGRESS';
    };
    HierarchicalCommentListitemComponent.prototype.commentIdentity = function (index, item) {
        return item.id;
    };
    HierarchicalCommentListitemComponent.prototype.isSameMoment = function (moment1, moment2) {
        return moment1.valueOf() === moment2.valueOf();
    };
    Object.defineProperty(HierarchicalCommentListitemComponent.prototype, "expanded", {
        get: function () {
            return this.comment.expanded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HierarchicalCommentListitemComponent.prototype, "collapsed", {
        get: function () {
            return !this.expanded;
        },
        enumerable: true,
        configurable: true
    });
    HierarchicalCommentListitemComponent.prototype.expand = function () {
        this.emitExpandComment(this.comment.id);
    };
    HierarchicalCommentListitemComponent.prototype.collapse = function () {
        this.emitCollapseComment(this.comment.id);
    };
    HierarchicalCommentListitemComponent.prototype.emitRefreshComments = function () {
        this.refreshComments.emit(this.commentThreadId);
    };
    HierarchicalCommentListitemComponent.prototype.emitExpandComment = function (commentId) {
        this.expandComment.emit(commentId);
    };
    HierarchicalCommentListitemComponent.prototype.emitCollapseComment = function (commentId) {
        this.collapseComment.emit(commentId);
    };
    HierarchicalCommentListitemComponent.prototype.getCommentUserDisplayName = function () {
        if (this.comment && this.comment.user) {
            var userDisplayName = this.comment.user.getDisplayName();
            if (userDisplayName.length > 0) {
                return userDisplayName;
            }
        }
        return this.translateService.instant('Removed user');
    };
    return HierarchicalCommentListitemComponent;
}());
export { HierarchicalCommentListitemComponent };
