
    <button id="editable_save_button"
            [hidden]="!editing"
            type="button"
            [disabled]="!canSave() || operationPending || invalid"
            class="btn btn-action float-right ml-3"
            (click)="save()">
      <span translate>Save</span>
    </button>

    <button id="editable_cancel_button"
            [hidden]="!editing"
            type="button"
            [disabled]="operationPending"
            class="btn btn-link float-right"
            (click)="cancel()">
      <span translate>Cancel</span>
    </button>

    <button id="editable_edit_button"
            [hidden]="editing"
            type="button"
            class="btn btn-action float-right ml-3"
            *ngIf="canEdit()"
            (click)="edit()">
      <span translate>Edit</span>
    </button>

    <app-ajax-loading-indicator-small class="ml-3 float-right" *ngIf="operationPending"></app-ajax-loading-indicator-small>
  