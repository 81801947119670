import { EditableService } from '../../services/editable.service';
import { FormControl, NgControl } from '@angular/forms';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { formatDisplayDate, fromPickerDate, toPickerDate } from '../../utils/date';
var DateInputComponent = /** @class */ (function () {
    function DateInputComponent(parentControl, editableService) {
        var _this = this;
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.restrict = false;
        this.required = false;
        this.control = new FormControl();
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        this.control.valueChanges.subscribe(function () {
            _this.propagateChange(_this.value);
        });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
    }
    Object.defineProperty(DateInputComponent.prototype, "show", {
        get: function () {
            return this.editing || this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing && !this.restrict;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateInputComponent.prototype, "invalid", {
        get: function () {
            return typeof this.control.value === 'string';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateInputComponent.prototype, "value", {
        get: function () {
            if (this.invalid) {
                return undefined;
            }
            return fromPickerDate(this.control.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateInputComponent.prototype, "displayName", {
        get: function () {
            var value = this.value;
            return value ? formatDisplayDate(value) : '';
        },
        enumerable: true,
        configurable: true
    });
    DateInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(toPickerDate(obj));
    };
    DateInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    DateInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return DateInputComponent;
}());
export { DateInputComponent };
