<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="tab-container">
  <div *ngIf="canCreateCommentThread" class="row float-right mb-3 mr-1">
    <div>
      <button *ngIf="showCreateThreadButton"
              id="create_commentthread_button"
              type="button"
              class="btn btn-secondary-action"
              (click)="addCommentThreadToCommentRound()">
        <span>＋ </span>
        <span translate>Add new resource</span>
      </button>

      <app-editable-buttons *ngIf="showEditableButtons"
                            [form]="form"
                            [entity]="commentRound"></app-editable-buttons>
    </div>
  </div>
  <form [formGroup]="commentThreadForm" #form="ngForm">
    <div *ngIf="commentThreadForms.controls.length === 0">
      <span translate>This comment round does not have any resources.</span>
    </div>
    <div *ngIf="commentThreadForms.controls.length > 0" formArrayName="commentThreads">
      <table>
        <thead>
        <tr>
          <th id="inline_bubble_header" style="width: 7%">
            <span translate>Comments</span>
          </th>
          <th id="resource_name_header" style="width: 15%">
            <div class="sort-content" (click)="sortContent('alphabetical')">
              <span translate>Resource</span>
              <span [class.sort-content-active]="sortOption === 'alphabetical'"> ▼</span>
            </div>
          </th>
          <th id="proposed_comment_header" style="width: 20%" translate>Proposer's comment</th>
          <th id="added_header" style="width: 10%">
            <div class="sort-content" (click)="sortContent('created')">
              <span translate>Added</span>
              <span [class.sort-content-active]="sortOption === 'created'"> ▼</span>
            </div>
          </th>
          <th id="added_by_header" *ngIf="!commentRound.fixedThreads" style="width: 10%" translate>Added by</th>
          <th id="description_header" [ngClass]="isEditing ? '' : 'description'" style="width: 15%" translate>Description</th>
          <th id="current_status_header" style="width: 15%" translate>Current status</th>
          <th id="proposed_status_header" style="width: 10%" translate>Proposed status</th>
          <th id="actions_header" *ngIf="showActions" style="width: 3%" translate>Actions</th>
          <th id="results_header" *ngIf="showResults" style="width: 15%"><span translate>Proposed statuses</span></th>
        </tr>
        </thead>

        <tbody>
        <ng-container *ngFor="let commentThread of commentThreadForms.controls; let i = index" [formGroupName]="i">
          <tr>
            <td style="width: 7%">
              <div *ngIf="commentThread.value.commentCount != null && commentThread.value.commentCount > 0">
                        <span id="view_commentthread_{{i}}_comments_button"
                              class="fa-stack fa-1x icon h5"
                              (click)="toggleShowThreadComments(commentThread.value.sequenceId, i)">
                          <a><i class="fa icon-bubble fa-stack-2x icon-blue comment-icons"></i></a>
                          <span class="fa fa-stack-1x comment-count h6">{{commentThread.value.commentCount}}</span>
                        </span>
              </div>
            </td>
            <td style="width: 15%">
              <a *ngIf="commentThread.value.label && commentThread.value.resourceUri"
                 [id]="'commentthread_link_' + i"
                 [href]="getCommentThreadResourceUri(commentThread.value)"
                 target="_blank"
                 rel="noopener noreferrer">
                <div ngbPopover="{{ commentThread.value.description | translateValue:true }}" triggers="mouseenter:mouseleave" placement="right">
                  <app-literal-multilanguage *ngIf="hasLocalization(commentThread.value.label)" [value]="commentThread.value.label"></app-literal-multilanguage>
                  <app-literal *ngIf="!hasLocalization(commentThread.value.label) && commentThread.value.localName" [value]="commentThread.value.localName"></app-literal>
                  <app-literal *ngIf="!hasLocalization(commentThread.value.label) && commentThread.value.localName == null"
                               [value]="commentThread.value.resourceUri"></app-literal>
                </div>
              </a>
              <app-localizable-undefined-textarea *ngIf="!commentThread.value.resourceUri"
                                                  [required]=true
                                                  [restrict]="!isNewResource(commentThread.value.id)"
                                                  [showRequired]=false
                                                  [id]="'commenthread_' + i + '_label_text_input'"
                                                  [formControlName]="'label'"></app-localizable-undefined-textarea>
            </td>
            <td style="width: 15%">
              <app-literal-textarea *ngIf="commentThread.value.resourceUri && isNewResource(commentThread.value.id)"
                                    [required]=false
                                    [showRequired]=false
                                    [id]="'commentthread_proposed_text_input_' + i"
                                    [formControlName]="'proposedText'"></app-literal-textarea>
              <span *ngIf="!isNewResource(commentThread.value.id)">{{ commentThread.value.proposedText ? commentThread.value.proposedText : '-' }}</span>
            </td>
            <td style="width: 10%">
              <span>{{ formatDisplayDate(commentThread.value.created) }}</span>
            </td>
            <td style="width: 10%" *ngIf="!commentRound.fixedThreads">
              <span>{{ getCommentThreadUserDisplayName(commentThread.value.user) }}</span>
            </td>
            <td [ngClass]="isEditing ? '' : 'description'" style="width: 15%">
                      <span class="wrap"
                            *ngIf="commentThread.value.resourceUri && !languageService.isLocalizableEmpty(commentThread.value.description)">{{ commentThread.value.description | translateValue }}</span>
              <span *ngIf="commentThread.value.resourceUri && languageService.isLocalizableEmpty(commentThread.value.description)">-</span>
              <app-localizable-undefined-textarea *ngIf="!commentThread.value.resourceUri"
                                                  [required]=true
                                                  [restrict]="!isNewResource(commentThread.value.id)"
                                                  [showRequired]=false
                                                  [id]="'commenthread_' + i + '_description_text_input'"
                                                  [formControlName]="'description'"></app-localizable-undefined-textarea>
            </td>
            <td style="width: 10%">
              <app-status *ngIf="commentThread.value.currentStatus" class="status" [status]="commentThread.value.currentStatus"></app-status>
              <span *ngIf="!commentThread.value.currentStatus">-</span>
            </td>
            <td style="width: 10%">
              <app-proposed-status-table-dropdown *ngIf="isNewResource(commentThread.value.id) && commentThread.value.resourceUri != null"
                                                  [restrict]="commentRound.status !== 'INCOMPLETE' && commentRound.status !== 'AWAIT' && commentRound.status !== 'INPROGRESS' && isNewResource(commentThread.value.id)"
                                                  [id]="i"
                                                  [defaultStatus]="commentThread.value.proposedStatus"
                                                  [formControlName]="'proposedStatus'"></app-proposed-status-table-dropdown>
              <app-status *ngIf="(isNewResource(commentThread.value.id) && commentThread.value.resourceUri == null) || !isNewResource(commentThread.value.id)" class="status"
                          [status]="commentThread.value.proposedStatus !== 'NOSTATUS' ? commentThread.value.proposedStatus : 'SUGGESTED'"></app-status>
            </td>
            <td style="width: 3%" *ngIf="showActions">
              <button *ngIf="((commentRound.status === 'INCOMPLETE' && isEditorOrSuperUser) || isNewResource(commentThread.value.id))"
                      [id]="'remove_commentthread_' + i + '_button'"
                      type="button"
                      class="icon icon-trash"
                      (click)="removeCommentThread(i)"></button>
            </td>
            <td style="width: 15%" *ngIf="showResults && commentThread.value.results && commentThread.value.results.length > 0">
              <div *ngFor="let result of commentThread.value.results">
                <div>
                  <span>{{ result.status | translate }}</span>: <span>{{ result.count }}</span> (<span>{{ result.percentage }}</span> %)
                </div>
              </div>
            </td>
            <td *ngIf="showResults && (!commentThread.value.results || commentThread.value.results.length === 0)">
              <span>-</span>
            </td>
          </tr>
          <tr *ngIf="enableShowComments(commentRound.sequenceId, commentThread.value.sequenceId, i)">
            <td colspan="8">
              <div class="comments col-md-12">
                <div class="row">
                  <div *ngIf="allowExpandAllAndCollapseAll()" class="col-md-6 float-left">
                    <button id="expand_all_button"
                            *ngIf="showExpandAll()"
                            type="button"
                            class="btn btn-action ml-3 float-left"
                            (click)="expandAll()"
                            translate>Expand all</button>
                    <button id="collapse_all_button"
                            *ngIf="showCollapseAll()"
                            type="button"
                            class="btn btn-action ml-3 float-left"
                            (click)="collapseAll()"
                            translate>Collapse all</button>
                  </div>
                  <div class="h4 comment-icons col-md-6 float-right">
                    <div>
                      <a class="action-button">
                        <i id="close_commentthread_{{i}}_comments_button"
                           class="fa fa-times"
                           (click)="toggleShowThreadComments(commentThread.value.sequenceId, i)"></i>
                      </a>
                      <a class="action-button">
                        <i id="refresh_commentthread_{{i}}_comments_button"
                           class="fa fa-undo icon-blue"
                           (click)="refreshComments(commentThread.value.id)"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div id="comments_list_div" class="col-md-12">
                  <ul id="comments_list">
                    <li *ngFor="let comment of filterTopLevelComments(activeThreadComments); trackBy: commentIdentity">
                      <app-hierarchical-comment *ngIf="!comment.parentComment"
                                                (refreshComments)="refreshComments($event)"
                                                (expandComment)="expandComment($event)"
                                                (collapseComment)="collapseComment($event)"
                                                [id]="comment.id"
                                                [comment]="comment"
                                                [comments]="activeThreadComments"
                                                [commentRound]="commentRound"
                                                [commentThreadId]="commentThread.value.id"
                                                [activeCommentId$]="activeCommentId$"
                                                [canComment]="canInlineComment"
                                                [canModifyOrDeleteComment]="canModifyOrDeleteInlineComment(comment)"></app-hierarchical-comment>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </form>
</div>
