import { UserService } from 'yti-common-ui/services/user.service';
var AuthorizationManager = /** @class */ (function () {
    function AuthorizationManager(userService) {
        this.userService = userService;
    }
    Object.defineProperty(AuthorizationManager.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    AuthorizationManager.prototype.canEdit = function (editableEntity) {
        if (this.user.superuser) {
            return true;
        }
        if (editableEntity.allowUserEdit() && this.user.id === editableEntity.getUser().id) {
            return true;
        }
        if (editableEntity.allowOrganizationEdit()) {
            if (this.user.tokenRole === 'MEMBER' && this.user.containerUri === editableEntity.getContainerUri()) {
                return true;
            }
            return this.user.isInOrganization(editableEntity.getOwningOrganizationIds(), ['ADMIN', 'CODE_LIST_EDITOR', 'TERMINOLOGY_EDITOR', 'DATA_MODEL_EDITOR', 'MEMBER']);
        }
        return false;
    };
    AuthorizationManager.prototype.canCreateCommentRound = function () {
        return this.user.superuser ||
            this.user.isInRoleInAnyOrganization(['ADMIN', 'CODE_LIST_EDITOR', 'TERMINOLOGY_EDITOR', 'DATA_MODEL_EDITOR']);
    };
    AuthorizationManager.prototype.canCreateCommentThread = function (editableEntity) {
        if (this.user.superuser || this.user.id === editableEntity.getUser().id) {
            return true;
        }
        if (editableEntity.allowOrganizationEdit()) {
            if (this.user.tokenRole === 'MEMBER' && this.user.containerUri === editableEntity.getContainerUri()) {
                return true;
            }
            return this.user.isInOrganization(editableEntity.getOwningOrganizationIds(), ['ADMIN', 'CODE_LIST_EDITOR', 'TERMINOLOGY_EDITOR', 'DATA_MODEL_EDITOR', 'MEMBER']);
        }
        return false;
    };
    AuthorizationManager.prototype.canCreateComment = function (editableEntity) {
        if (this.user.superuser || this.user.id === editableEntity.getUser().id) {
            return true;
        }
        if (editableEntity.allowOrganizationComment()) {
            if (this.user.tokenRole === 'MEMBER' && this.user.containerUri === editableEntity.getContainerUri()) {
                return true;
            }
            return this.user.isInOrganization(editableEntity.getOwningOrganizationIds(), ['ADMIN', 'CODE_LIST_EDITOR', 'TERMINOLOGY_EDITOR', 'DATA_MODEL_EDITOR', 'MEMBER']);
        }
        return false;
    };
    AuthorizationManager.prototype.canDeleteCommentRound = function (commentRound) {
        return this.user.superuser || this.user.id === commentRound.user.id;
    };
    AuthorizationManager.prototype.canExportExcel = function (commentRound) {
        if (this.user.superuser) {
            return true;
        }
        if (this.user.tokenRole === 'MEMBER' && this.user.containerUri === commentRound.getContainerUri()) {
            return true;
        }
        return this.user.isInOrganization(commentRound.getOwningOrganizationIds(), ['ADMIN', 'CODE_LIST_EDITOR', 'TERMINOLOGY_EDITOR', 'DATA_MODEL_EDITOR', 'MEMBER']);
    };
    return AuthorizationManager;
}());
export { AuthorizationManager };
