var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { formatDateTime, formatDisplayDateTime } from '../utils/date';
import { utc } from 'moment';
import { AbstractResource } from './abstract-resource';
import { User } from './user';
var CommentSimple = /** @class */ (function (_super) {
    __extends(CommentSimple, _super);
    function CommentSimple(data) {
        var _this = _super.call(this, data) || this;
        _this.created = null;
        _this.modified = null;
        if (data.user) {
            _this.user = new User(data.user);
        }
        _this.content = data.content;
        _this.proposedStatus = data.proposedStatus;
        _this.endStatus = data.endStatus;
        if (data.created) {
            _this.created = utc(data.created);
        }
        if (data.modified) {
            _this.modified = utc(data.modified);
        }
        if (data.parentComment) {
            _this.parentComment = new CommentSimple(data.parentComment);
        }
        _this.expanded = true;
        return _this;
    }
    Object.defineProperty(CommentSimple.prototype, "createdDisplayValue", {
        get: function () {
            return formatDisplayDateTime(this.created);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentSimple.prototype, "modifiedDisplayValue", {
        get: function () {
            return formatDisplayDateTime(this.modified);
        },
        enumerable: true,
        configurable: true
    });
    CommentSimple.prototype.serialize = function () {
        return {
            id: this.id,
            url: this.url,
            uri: this.uri,
            sequenceId: this.sequenceId,
            user: this.user ? this.user.serialize() : undefined,
            content: this.content,
            proposedStatus: this.proposedStatus,
            endStatus: this.endStatus,
            created: formatDateTime(this.created),
            modified: formatDateTime(this.modified)
        };
    };
    CommentSimple.prototype.clone = function () {
        return new CommentSimple(this.serialize());
    };
    return CommentSimple;
}(AbstractResource));
export { CommentSimple };
