import { EditableService } from '../../services/editable.service';
import { NgControl } from '@angular/forms';
var LocalizableUndefinedTextareaComponent = /** @class */ (function () {
    function LocalizableUndefinedTextareaComponent(parentControl, editableService) {
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.restrict = false;
        this.required = false;
        this.showRequired = true;
        this.value = {};
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
    }
    Object.defineProperty(LocalizableUndefinedTextareaComponent.prototype, "valid", {
        get: function () {
            return !this.parentControl || this.parentControl.valid;
        },
        enumerable: true,
        configurable: true
    });
    LocalizableUndefinedTextareaComponent.prototype.onChange = function (value) {
        this.value[this.contentLanguage] = value;
        this.propagateChange(this.value);
    };
    Object.defineProperty(LocalizableUndefinedTextareaComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing && !this.restrict;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocalizableUndefinedTextareaComponent.prototype, "contentLanguage", {
        get: function () {
            return 'und';
        },
        enumerable: true,
        configurable: true
    });
    LocalizableUndefinedTextareaComponent.prototype.writeValue = function (obj) {
        this.value = Object.assign({}, obj);
    };
    LocalizableUndefinedTextareaComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    LocalizableUndefinedTextareaComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return LocalizableUndefinedTextareaComponent;
}());
export { LocalizableUndefinedTextareaComponent };
