/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inline-clipboard.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/yti-common-ui/components/information-symbol.component.ngfactory";
import * as i3 from "yti-common-ui/components/information-symbol.component";
import * as i4 from "@angular/common";
import * as i5 from "@ng-bootstrap/ng-bootstrap/popover/popover";
import * as i6 from "@ng-bootstrap/ng-bootstrap/popover/popover-config";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@ng-bootstrap/ng-bootstrap/tooltip/tooltip";
import * as i9 from "@ng-bootstrap/ng-bootstrap/tooltip/tooltip-config";
import * as i10 from "ngx-clipboard";
import * as i11 from "./inline-clipboard";
var styles_InlineClipboardComponent = [i0.styles];
var RenderType_InlineClipboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InlineClipboardComponent, data: {} });
export { RenderType_InlineClipboardComponent as RenderType_InlineClipboardComponent };
function View_InlineClipboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["rel", "noopener noreferrer"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.value, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.value; _ck(_v, 1, 0, currVal_1); }); }
function View_InlineClipboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 1, 0, currVal_0); }); }
export function View_InlineClipboardComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { tooltip: 0 }), i1.ɵqud(402653184, 2, { popover: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 16, "dl", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-information-symbol", [], null, null, null, i2.View_InformationSymbolComponent_0, i2.RenderType_InformationSymbolComponent)), i1.ɵdid(7, 49152, null, 0, i3.InformationSymbolComponent, [], { infoText: [0, "infoText"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 10, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InlineClipboardComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InlineClipboardComponent_2)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 16777216, null, null, 5, "img", [["class", "svg-icon clipboard"], ["ngxClipboard", ""]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.clickToolTip() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 737280, [[2, 4], ["p", 4]], 0, i5.NgbPopover, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i6.NgbPopoverConfig, i1.NgZone], { ngbPopover: [0, "ngbPopover"] }, null), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), i1.ɵdid(16, 212992, [[1, 4], ["t", 4]], 0, i8.NgbTooltip, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i9.NgbTooltipConfig, i1.NgZone], { ngbTooltip: [0, "ngbTooltip"] }, null), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), i1.ɵdid(18, 212992, null, 0, i10.ClipboardDirective, [i10.ClipboardService], { targetElm: [0, "targetElm"], cbContent: [1, "cbContent"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.infoText; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.showAsLink; _ck(_v, 10, 0, currVal_2); var currVal_3 = !_co.showAsLink; _ck(_v, 12, 0, currVal_3); var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("Copied to clipboard")), ""); _ck(_v, 14, 0, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("Copy value to clipboard", _co.translateParams)), ""); _ck(_v, 16, 0, currVal_6); var currVal_7 = ""; var currVal_8 = _co.value; _ck(_v, 18, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 5, 0, currVal_0); var currVal_4 = _co.clippyImage; _ck(_v, 13, 0, currVal_4); }); }
export function View_InlineClipboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inline-clipboard", [], null, null, null, View_InlineClipboardComponent_0, RenderType_InlineClipboardComponent)), i1.ɵdid(1, 49152, null, 0, i11.InlineClipboardComponent, [], null, null)], null, null); }
var InlineClipboardComponentNgFactory = i1.ɵccf("app-inline-clipboard", i11.InlineClipboardComponent, View_InlineClipboardComponent_Host_0, { label: "label", value: "value", showAsLink: "showAsLink", infoText: "infoText" }, {}, []);
export { InlineClipboardComponentNgFactory as InlineClipboardComponentNgFactory };
