/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-linked-organization-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./search-linked-organization-modal.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i7 from "../../services/language.service";
var styles_SearchLinkedOrganizationModalComponent = [i0.styles];
var RenderType_SearchLinkedOrganizationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchLinkedOrganizationModalComponent, data: {} });
export { RenderType_SearchLinkedOrganizationModalComponent as RenderType_SearchLinkedOrganizationModalComponent };
function View_SearchLinkedOrganizationModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "search-result"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "content"]], [[2, "last", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "title"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.getIdIdentifier(_co.languageService) + "_organization_link"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.last; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.getDisplayName(_co.languageService, _co.useUILanguage); _ck(_v, 2, 0, currVal_2); }); }
export function View_SearchLinkedOrganizationModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { searchInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-times"], ["id", "close_modal_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 16, "div", [["class", "modal-body full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "row mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "input-group input-group-lg input-group-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["searchInput", 1]], null, 5, "input", [["class", "form-control"], ["id", "search_linked_organization_input"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.search = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(14, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(16, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "row full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [["class", "search-results"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SearchLinkedOrganizationModalComponent_1)), i1.ɵdid(22, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(24, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 3, "button", [["class", "btn btn-link cancel"], ["id", "cancel_modal_button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(27, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.search; _ck(_v, 14, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform(_co.searchResults$)); _ck(_v, 22, 0, currVal_10); var currVal_11 = ""; _ck(_v, 27, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleLabel; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.searchLabel; var currVal_2 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 16).ngClassValid; var currVal_7 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_SearchLinkedOrganizationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-linked-organization-modal", [], null, null, null, View_SearchLinkedOrganizationModalComponent_0, RenderType_SearchLinkedOrganizationModalComponent)), i1.ɵdid(1, 4308992, null, 0, i5.SearchLinkedOrganizationModalComponent, [i6.NgbActiveModal, i7.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchLinkedOrganizationModalComponentNgFactory = i1.ɵccf("app-search-linked-organization-modal", i5.SearchLinkedOrganizationModalComponent, View_SearchLinkedOrganizationModalComponent_Host_0, { restricts: "restricts", titleLabel: "titleLabel", searchLabel: "searchLabel", organizations$: "organizations$", useUILanguage: "useUILanguage" }, {}, []);
export { SearchLinkedOrganizationModalComponentNgFactory as SearchLinkedOrganizationModalComponentNgFactory };
