/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/yti-common-ui/components/required-symbol.component.ngfactory";
import * as i2 from "yti-common-ui/components/required-symbol.component";
import * as i3 from "@angular/common";
import * as i4 from "./error-messages.component.ngfactory";
import * as i5 from "./error-messages.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../node_modules/yti-common-ui/components/information-symbol.component.ngfactory";
import * as i8 from "yti-common-ui/components/information-symbol.component";
import * as i9 from "./temp-user-input.component";
import * as i10 from "../../services/editable.service";
import * as i11 from "./add-temp-users-modal.component";
import * as i12 from "yti-common-ui/components/confirmation-modal.component";
import * as i13 from "../../services/language.service";
var styles_TempUserInputComponent = [];
var RenderType_TempUserInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TempUserInputComponent, data: {} });
export { RenderType_TempUserInputComponent as RenderType_TempUserInputComponent };
function View_TempUserInputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-required-symbol", [], null, null, null, i1.View_RequiredSymbolComponent_0, i1.RenderType_RequiredSymbolComponent)), i0.ɵdid(1, 49152, null, 0, i2.RequiredSymbolComponent, [], null, null)], null, null); }
function View_TempUserInputComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.getDisplayName(); _ck(_v, 1, 0, currVal_0); }); }
function View_TempUserInputComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TempUserInputComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.email != null); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TempUserInputComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TempUserInputComponent_3)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tempUsers; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TempUserInputComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "removal-X"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-times"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeTempUser(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (("remove_" + _v.parent.context.$implicit.email) + "_tempuser_link"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.getDisplayName(); _ck(_v, 4, 0, currVal_1); }); }
function View_TempUserInputComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TempUserInputComponent_7)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.email != null); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TempUserInputComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TempUserInputComponent_6)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-error-messages", [["id", "tempuser_error_messages"]], null, null, null, i4.View_ErrorMessagesComponent_0, i4.RenderType_ErrorMessagesComponent)), i0.ɵdid(4, 49152, null, 0, i5.ErrorMessagesComponent, [], { control: [0, "control"], id: [1, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tempUsers; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.parentControl; var currVal_2 = "tempuser_error_messages"; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_TempUserInputComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-sm btn-action mt-2"], ["id", "add_tempuser_button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addTempUser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Add user"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TempUserInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "dl", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-information-symbol", [], null, null, null, i7.View_InformationSymbolComponent_0, i7.RenderType_InformationSymbolComponent)), i0.ɵdid(5, 49152, null, 0, i8.InformationSymbolComponent, [], { infoText: [0, "infoText"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TempUserInputComponent_1)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 6, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TempUserInputComponent_2)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TempUserInputComponent_5)), i0.ɵdid(12, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TempUserInputComponent_8)), i0.ɵdid(14, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.infoText; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.required && _co.editing); _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.editing; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.editing; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.editing; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); }); }
export function View_TempUserInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-temp-user-input", [], null, null, null, View_TempUserInputComponent_0, RenderType_TempUserInputComponent)), i0.ɵdid(1, 49152, null, 0, i9.TempUserInputComponent, [[8, null], i10.EditableService, i6.TranslateService, i11.AddTempUsersModalService, i12.ConfirmationModalService, i13.LanguageService], null, null)], null, null); }
var TempUserInputComponentNgFactory = i0.ɵccf("app-temp-user-input", i9.TempUserInputComponent, View_TempUserInputComponent_Host_0, { label: "label", restrict: "restrict", required: "required", infoText: "infoText", commentRound: "commentRound" }, {}, []);
export { TempUserInputComponentNgFactory as TempUserInputComponentNgFactory };
