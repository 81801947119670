
      <virtual-scroller #scroll [items]="buffer" (vsEnd)="fetchMore($event)" [enableUnequalChildrenSizes]="true">
        <app-integration-resource-list-item (selectResourceEvent)="emitSelectResourceEvent($event)"
                                            *ngFor="let item of scroll.viewPortItems; let last = last" [theLast]="last"
                                            [resource]="item" [expanded]="item.expanded"
                                            [selectedResources$]="selectedResources$"></app-integration-resource-list-item>
        <div *ngIf="loading">
          <app-ajax-loading-indicator></app-ajax-loading-indicator>
        </div>
        <div *ngIf="this.buffer.length == 0 && !loading">
          <span class="infoText" translate>Search did not find in any resources for commenting.</span>
        </div>
      </virtual-scroller>
  