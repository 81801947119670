import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { CommentRound } from '../../entities/commentround';
import { AuthorizationManager } from '../../services/authorization-manager';
import { LanguageService } from '../../services/language.service';
import { hasLocalization } from 'yti-common-ui/utils/localization';
import { ConfigurationService } from '../../services/configuration.service';
import { FormControl, FormGroup } from '@angular/forms';
import { validDateRange } from '../../utils/date';
import { requiredList } from 'yti-common-ui/utils/validator';
import { tap } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { EditableService } from '../../services/editable.service';
var CommentRoundInformationComponent = /** @class */ (function () {
    function CommentRoundInformationComponent(languageService, configurationService, authorizationManager, dataService, editableService) {
        var _this = this;
        this.languageService = languageService;
        this.configurationService = configurationService;
        this.authorizationManager = authorizationManager;
        this.dataService = dataService;
        this.editableService = editableService;
        this.changeTabControl = new EventEmitter();
        this.refreshCommentRound = new EventEmitter();
        this.commentRoundForm = new FormGroup({
            label: new FormControl(''),
            description: new FormControl(''),
            localName: new FormControl(),
            fixedThreads: new FormControl(),
            openThreads: new FormControl(),
            validity: new FormControl({ start: null, end: null }, validDateRange),
            status: new FormControl('INCOMPLETE'),
            organizations: new FormControl([], [requiredList]),
            tempUsers: new FormControl([])
        }, null);
        this.editSubscription = editableService.edit$.subscribe(function () { return _this.changeTabControl.emit(true); });
        this.cancelSubscription = editableService.cancel$.subscribe(function () {
            _this.changeTabControl.emit(false);
            _this.reset();
        });
        editableService.onSave = function (formValue) { return _this.save(formValue); };
    }
    CommentRoundInformationComponent.prototype.ngOnInit = function () {
        this.reset();
    };
    CommentRoundInformationComponent.prototype.ngOnDestroy = function () {
        this.cancelSubscription.unsubscribe();
    };
    CommentRoundInformationComponent.prototype.ngOnChanges = function (changes) {
        var commentRoundChange = changes['commentRound'];
        if (commentRoundChange && !commentRoundChange.isFirstChange()) {
            this.commentRound = commentRoundChange.currentValue;
            this.reset();
        }
        var commentThreadsChange = changes['commentThreads'];
        if (commentThreadsChange && !commentThreadsChange.isFirstChange()) {
            this.commentThreads = commentThreadsChange.currentValue;
        }
    };
    CommentRoundInformationComponent.prototype.reset = function () {
        if (this.loading) {
            return;
        }
        var _a = this.commentRound, label = _a.label, description = _a.description, fixedThreads = _a.fixedThreads, openThreads = _a.openThreads, startDate = _a.startDate, endDate = _a.endDate, organizations = _a.organizations, status = _a.status, tempUsers = _a.tempUsers;
        this.commentRoundForm.reset({
            label: label,
            description: description,
            fixedThreads: fixedThreads,
            openThreads: openThreads,
            validity: { start: startDate, end: endDate },
            organizations: organizations.map(function (organization) { return organization.clone(); }),
            tempUsers: tempUsers.map(function (tempUser) { return tempUser.clone(); }),
            status: status
        });
    };
    Object.defineProperty(CommentRoundInformationComponent.prototype, "loading", {
        get: function () {
            return this.commentRound == null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundInformationComponent.prototype, "isEditorOrSuperUser", {
        get: function () {
            return this.authorizationManager.user.superuser || this.commentRound.user.id === this.authorizationManager.user.id;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundInformationComponent.prototype.hasLocalization = function (localizable) {
        return hasLocalization(localizable);
    };
    Object.defineProperty(CommentRoundInformationComponent.prototype, "toolType", {
        get: function () {
            return this.commentRound.source.containerType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundInformationComponent.prototype, "getResourceUri", {
        get: function () {
            return this.configurationService.getUriWithEnv(this.commentRound.source.containerUri);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundInformationComponent.prototype, "hasCommentThreads", {
        get: function () {
            return this.commentThreads.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundInformationComponent.prototype, "commentRoundCountTranslateParams", {
        get: function () {
            return {
                value: this.commentThreads.length
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundInformationComponent.prototype, "showModifyButton", {
        get: function () {
            return this.isEditorOrSuperUser;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundInformationComponent.prototype.save = function (formData) {
        var _this = this;
        var label = formData.label, description = formData.description, fixedThreads = formData.fixedThreads, openThreads = formData.openThreads, validity = formData.validity, organizations = formData.organizations, tempUsers = formData.tempUsers, status = formData.status;
        var updatedCommentRound = this.commentRound.clone();
        Object.assign(updatedCommentRound, {
            label: label,
            description: description,
            fixedThreads: fixedThreads,
            openThreads: openThreads,
            startDate: validity.start,
            endDate: validity.end,
            organizations: organizations,
            tempUsers: tempUsers,
            source: this.commentRound.source,
            sourceLocalName: this.commentRound.sourceLocalName,
            sourceLabel: this.commentRound.sourceLabel,
            status: status,
            commentThreads: null
        });
        var save = function () {
            return _this.dataService.updateCommentRound(updatedCommentRound.serialize()).pipe(tap(function () {
                _this.changeTabControl.emit(false);
                _this.refreshCommentRound.emit();
            }));
        };
        return save();
    };
    Object.defineProperty(CommentRoundInformationComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundInformationComponent.prototype.getCommentRoundUri = function () {
        return this.configurationService.getUriWithEnv(this.commentRound.uri);
    };
    return CommentRoundInformationComponent;
}());
export { CommentRoundInformationComponent };
