var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { labelNameToResourceIdIdentifier } from 'yti-common-ui/utils/resource';
var OrganizationSimple = /** @class */ (function () {
    function OrganizationSimple(data) {
        this.id = data.id;
        this.prefLabel = data.prefLabel || {};
        this.description = data.description || {};
        this.url = data.url;
    }
    OrganizationSimple.prototype.getDisplayName = function (localizer, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        return localizer.translate(this.prefLabel, useUILanguage);
    };
    OrganizationSimple.prototype.getIdIdentifier = function (localizer) {
        var prefLabel = localizer.translate(this.prefLabel);
        return "" + labelNameToResourceIdIdentifier(prefLabel);
    };
    OrganizationSimple.prototype.serialize = function () {
        return {
            id: this.id,
            url: this.url,
            prefLabel: __assign({}, this.prefLabel),
            description: __assign({}, this.description)
        };
    };
    OrganizationSimple.prototype.clone = function () {
        return new OrganizationSimple(this.serialize());
    };
    return OrganizationSimple;
}());
export { OrganizationSimple };
