
    <dl *ngIf="show">
      <dt *ngIf="!editing">
        <label>{{label}}</label>
        <app-information-symbol [infoText]="infoText"></app-information-symbol>
        <app-required-symbol *ngIf="required && editing"></app-required-symbol>
      </dt>
      <dd>
        <div *ngIf="editing">

          <div class="row">
            <div class="col-md-6">
              <app-date-input [id]="'start_date_input'"
                              [showLabel]="true"
                              [label]="startLabel"
                              [infoText]="startDateInfoText"
                              [formControl]="startControl"></app-date-input>
            </div>

            <div class="col-md-6">
              <app-date-input [id]="'end_date_input'"
                              [label]="endLabel"
                              [showLabel]="true"
                              [infoText]="endDateInfoText"
                              [formControl]="endControl"></app-date-input>
            </div>
          </div>

          <app-error-messages id="date_range_error_messages" [control]="parentControl"></app-error-messages>
        </div>

        <span *ngIf="!editing">{{displayName}}</span>
      </dd>
    </dl>
  