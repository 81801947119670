import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, concat } from 'rxjs';
import { LanguageService } from '../../services/language.service';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { DataService } from '../../services/data.service';
import { debounceTime, skip, take, tap } from 'rxjs/operators';
import { IntegrationResource } from '../../entities/integration-resource';
import { TranslateService } from '@ngx-translate/core';
import { allStatuses } from 'yti-common-ui/entities/status';
import { ConfigurationService } from '../../services/configuration.service';
import { IntegrationResourceVirtualScrollerComponent } from './integration-resource-virtual-scroller-component';
var SearchLinkedIntegrationResourceMultiModalComponent = /** @class */ (function () {
    function SearchLinkedIntegrationResourceMultiModalComponent(configurationService, modal, languageService, dataService, translateService) {
        this.configurationService = configurationService;
        this.modal = modal;
        this.languageService = languageService;
        this.dataService = dataService;
        this.translateService = translateService;
        this.selectedResources = [];
        this.loading = false;
        this.virtualScrollerInstanceToggle = true; // This solution is needed to reset the virtual scroller because there is no API to do a reset.
        this.status$ = new BehaviorSubject(null);
        this.search$ = new BehaviorSubject('');
        this.searchResults$ = new BehaviorSubject([]);
        this.selectedResources$ = new BehaviorSubject(this.selectedResources);
    }
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchLabel = this.translateService.instant('Search term');
        this.statusOptions = [null].concat(allStatuses).map(function (status) { return ({
            value: status,
            name: function () { return _this.translateService.instant(status ? status : 'All statuses'); },
            idIdentifier: function () { return status ? status : 'all_selected'; }
        }); });
        this.titleLabel = this.translateService.instant('Select resource');
        this.instructionText = this.translateService.instant('HELP_TEXT_COMMENTTHREAD_RESOURCE_MODAL_INSTRUCTION');
        this.loading = true;
        this.filterResources();
    };
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.filterResources = function () {
        var _this = this;
        this.loading = true;
        this.searchResults$.next([]);
        var initialSearch = this.search$.pipe(take(1));
        var debouncedSearch = this.search$.pipe(skip(1), debounceTime(500));
        combineLatest(this.status$, concat(initialSearch, debouncedSearch))
            .pipe(tap(function () {
            _this.loading = false;
            _this.virtualScrollerInstanceToggle = !_this.virtualScrollerInstanceToggle;
        })).subscribe();
    };
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.isResourceSelected = function (resource) {
        var isSelected = false;
        for (var _i = 0, _a = this.selectedResources; _i < _a.length; _i++) {
            var selectedResource = _a[_i];
            if (resource.uri === selectedResource.uri) {
                isSelected = true;
                break;
            }
        }
        for (var _b = 0, _c = this.restricts; _b < _c.length; _b++) {
            var selectedResourceUri = _c[_b];
            if (resource.uri === selectedResourceUri) {
                isSelected = true;
                break;
            }
        }
        return isSelected;
    };
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.selectResource = function (resource) {
        if (!this.isResourceSelected(resource)) {
            resource.type = this.containerType;
            this.selectedResources.push(resource);
        }
    };
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.removeResource = function (resource) {
        var index = this.selectedResources.indexOf(resource);
        if (index !== -1) {
            this.selectedResources.splice(index, 1);
        }
        this.selectedResources$.next(this.selectedResources);
    };
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.select = function () {
        this.modal.close(this.selectedResources);
    };
    // pick all search results as selections
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.pickAll = function () {
        this.virtualScrollerComponent.pickAll();
    };
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.ngAfterViewInit = function () {
        this.searchInput.nativeElement.focus();
    };
    Object.defineProperty(SearchLinkedIntegrationResourceMultiModalComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.cancel = function () {
        this.modal.dismiss('cancel');
    };
    SearchLinkedIntegrationResourceMultiModalComponent.prototype.createEmptyResource = function () {
        var integrationResourceType = {
            type: this.containerType
        };
        var resource = new IntegrationResource(integrationResourceType);
        this.selectedResources.push(resource);
        this.modal.close(this.selectedResources);
    };
    return SearchLinkedIntegrationResourceMultiModalComponent;
}());
export { SearchLinkedIntegrationResourceMultiModalComponent };
var SearchLinkedIntegrationResourceMultiModalService = /** @class */ (function () {
    function SearchLinkedIntegrationResourceMultiModalService(modalService) {
        this.modalService = modalService;
    }
    SearchLinkedIntegrationResourceMultiModalService.prototype.open = function (containerType, containerUri, openThreads, restrictedResourceUris, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        var modalRef = this.modalService.open(SearchLinkedIntegrationResourceMultiModalComponent, { size: 'lg' });
        var instance = modalRef.componentInstance;
        instance.containerType = containerType;
        instance.containerUri = containerUri;
        instance.openThreads = openThreads;
        instance.restricts = restrictedResourceUris;
        instance.useUILanguage = useUILanguage;
        return modalRef.result;
    };
    return SearchLinkedIntegrationResourceMultiModalService;
}());
export { SearchLinkedIntegrationResourceMultiModalService };
