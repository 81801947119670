import { ControlValueAccessor } from '@angular/forms';
import { allCommentRoundStatuses } from '../../entities/comment-round-status';
var CommentRoundStatusDropdownComponent = /** @class */ (function () {
    function CommentRoundStatusDropdownComponent() {
        this.placement = 'bottom-left';
        this.restrict = false;
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
    }
    Object.defineProperty(CommentRoundStatusDropdownComponent.prototype, "options", {
        get: function () {
            return allCommentRoundStatuses;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundStatusDropdownComponent.prototype.isSelected = function (option) {
        return this.selection === option;
    };
    CommentRoundStatusDropdownComponent.prototype.select = function (option) {
        this.selection = option;
        this.propagateChange(option);
    };
    CommentRoundStatusDropdownComponent.prototype.writeValue = function (obj) {
        this.selection = obj;
    };
    CommentRoundStatusDropdownComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    CommentRoundStatusDropdownComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return CommentRoundStatusDropdownComponent;
}());
export { CommentRoundStatusDropdownComponent };
