import { EditableService } from '../../services/editable.service';
import { FormControl, NgControl } from '@angular/forms';
var LiteralTextareaComponent = /** @class */ (function () {
    function LiteralTextareaComponent(parentControl, editableService) {
        var _this = this;
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.restrict = false;
        this.showRequired = true;
        this.required = false;
        this.isEditing = false;
        this.control = new FormControl();
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
    }
    Object.defineProperty(LiteralTextareaComponent.prototype, "valid", {
        get: function () {
            return !this.parentControl || this.parentControl.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiteralTextareaComponent.prototype, "show", {
        get: function () {
            return this.editing || this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiteralTextareaComponent.prototype, "editing", {
        get: function () {
            return (this.editableService.editing || this.isEditing) && !this.restrict;
        },
        enumerable: true,
        configurable: true
    });
    LiteralTextareaComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    LiteralTextareaComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    LiteralTextareaComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return LiteralTextareaComponent;
}());
export { LiteralTextareaComponent };
