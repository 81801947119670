/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/yti-common-ui/components/information-symbol.component.ngfactory";
import * as i2 from "yti-common-ui/components/information-symbol.component";
import * as i3 from "../../../../node_modules/yti-common-ui/components/required-symbol.component.ngfactory";
import * as i4 from "yti-common-ui/components/required-symbol.component";
import * as i5 from "@angular/forms";
import * as i6 from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-service";
import * as i7 from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar";
import * as i8 from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-i18n";
import * as i9 from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-input";
import * as i10 from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-parser-formatter";
import * as i11 from "@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter";
import * as i12 from "@angular/common";
import * as i13 from "./error-messages.component.ngfactory";
import * as i14 from "./error-messages.component";
import * as i15 from "./date-input.component";
import * as i16 from "../../services/editable.service";
var styles_DateInputComponent = [];
var RenderType_DateInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DateInputComponent, data: {} });
export { RenderType_DateInputComponent as RenderType_DateInputComponent };
function View_DateInputComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_DateInputComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-information-symbol", [], null, null, null, i1.View_InformationSymbolComponent_0, i1.RenderType_InformationSymbolComponent)), i0.ɵdid(1, 49152, null, 0, i2.InformationSymbolComponent, [], { infoText: [0, "infoText"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoText; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DateInputComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-required-symbol", [], null, null, null, i3.View_RequiredSymbolComponent_0, i3.RenderType_RequiredSymbolComponent)), i0.ɵdid(1, 49152, null, 0, i4.RequiredSymbolComponent, [], null, null)], null, null); }
function View_DateInputComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 16777216, null, null, 8, "input", [["class", "form-control"], ["ngbDatepicker", ""], ["placeholder", "yyyy-mm-dd"]], [[8, "id", 0], [8, "disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 5).manualDateChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("change" === en)) {
        var pd_5 = (i0.ɵnov(_v, 5).manualDateChange($event.target.value, true) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 5).onBlur() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i5.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(512, null, i6.NgbDatepickerService, i6.NgbDatepickerService, [i7.NgbCalendar, i8.NgbDatepickerI18n]), i0.ɵdid(5, 671744, [[1, 4], ["date", 4]], 0, i9.NgbInputDatepicker, [i10.NgbDateParserFormatter, i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i0.ComponentFactoryResolver, i0.NgZone, i6.NgbDatepickerService, i7.NgbCalendar, i11.NgbDateAdapter, i12.DOCUMENT], null, null), i0.ɵprd(1024, null, i5.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i9.NgbInputDatepicker]), i0.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i5.DefaultValueAccessor, i9.NgbInputDatepicker]), i0.ɵdid(8, 540672, null, 0, i5.FormControlDirective, [[6, i5.NG_VALIDATORS], [8, null], [6, i5.NG_VALUE_ACCESSOR], [2, i5.ɵangular_packages_forms_forms_j]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i5.NgControl, null, [i5.FormControlDirective]), i0.ɵdid(10, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 0, "button", [["class", "input-group-addon icon-calendar calendar-selector"], ["type", "button"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "app-error-messages", [], null, null, null, i13.View_ErrorMessagesComponent_0, i13.RenderType_ErrorMessagesComponent)), i0.ɵdid(13, 49152, null, 0, i14.ErrorMessagesComponent, [], { control: [0, "control"], id: [1, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.control; _ck(_v, 8, 0, currVal_9); var currVal_11 = _co.parentControl; var currVal_12 = (_co.id + "_error_messages"); _ck(_v, 13, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = i0.ɵnov(_v, 5).disabled; var currVal_2 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 10).ngClassValid; var currVal_7 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = (_co.id + "_toggle_calendar_button"); _ck(_v, 11, 0, currVal_10); }); }
function View_DateInputComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayName; _ck(_v, 1, 0, currVal_0); }); }
function View_DateInputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "dl", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateInputComponent_2)), i0.ɵdid(3, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateInputComponent_3)), i0.ɵdid(5, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateInputComponent_4)), i0.ɵdid(7, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 4, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateInputComponent_5)), i0.ɵdid(10, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateInputComponent_6)), i0.ɵdid(12, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLabel; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showLabel; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.required && _co.editing); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.editing; _ck(_v, 10, 0, currVal_3); var currVal_4 = !_co.editing; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_DateInputComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { date: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DateInputComponent_1)), i0.ɵdid(2, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DateInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-date-input", [], null, null, null, View_DateInputComponent_0, RenderType_DateInputComponent)), i0.ɵdid(1, 49152, null, 0, i15.DateInputComponent, [[8, null], i16.EditableService], null, null)], null, null); }
var DateInputComponentNgFactory = i0.ɵccf("app-date-input", i15.DateInputComponent, View_DateInputComponent_Host_0, { label: "label", showLabel: "showLabel", restrict: "restrict", required: "required", id: "id", infoText: "infoText" }, {}, []);
export { DateInputComponentNgFactory as DateInputComponentNgFactory };
