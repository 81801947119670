/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/yti-common-ui/components/required-symbol.component.ngfactory";
import * as i2 from "yti-common-ui/components/required-symbol.component";
import * as i3 from "yti-common-ui/pipes/translate-value.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./error-messages.component.ngfactory";
import * as i6 from "./error-messages.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../node_modules/yti-common-ui/components/information-symbol.component.ngfactory";
import * as i9 from "yti-common-ui/components/information-symbol.component";
import * as i10 from "./organizations-input.component";
import * as i11 from "../../services/editable.service";
import * as i12 from "../../services/data.service";
import * as i13 from "./search-linked-organization-modal.component";
import * as i14 from "../../services/language.service";
var styles_OrganizationsInputComponent = [];
var RenderType_OrganizationsInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OrganizationsInputComponent, data: {} });
export { RenderType_OrganizationsInputComponent as RenderType_OrganizationsInputComponent };
function View_OrganizationsInputComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-required-symbol", [], null, null, null, i1.View_RequiredSymbolComponent_0, i1.RenderType_RequiredSymbolComponent)), i0.ɵdid(1, 49152, null, 0, i2.RequiredSymbolComponent, [], null, null)], null, null); }
function View_OrganizationsInputComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i3.TranslateValuePipe, [i3.LOCALIZER])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_v.context.$implicit.prefLabel, true)); _ck(_v, 2, 0, currVal_0); }); }
function View_OrganizationsInputComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OrganizationsInputComponent_4)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectableOrganizations; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OrganizationsInputComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "removal-X"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-times"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeOrganization(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("remove_" + _v.context.$implicit.getIdIdentifier(_co.languageService)) + "_organization_link"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.getDisplayName(_co.languageService, true); _ck(_v, 4, 0, currVal_1); }); }
function View_OrganizationsInputComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OrganizationsInputComponent_6)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-error-messages", [["id", "organization_error_messages"]], null, null, null, i5.View_ErrorMessagesComponent_0, i5.RenderType_ErrorMessagesComponent)), i0.ɵdid(4, 49152, null, 0, i6.ErrorMessagesComponent, [], { control: [0, "control"], id: [1, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectableOrganizations; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.parentControl; var currVal_2 = "organization_error_messages"; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_OrganizationsInputComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-sm btn-action mt-2"], ["id", "add_organization_button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addOrganization() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Add organization"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OrganizationsInputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "dl", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-information-symbol", [], null, null, null, i8.View_InformationSymbolComponent_0, i8.RenderType_InformationSymbolComponent)), i0.ɵdid(5, 49152, null, 0, i9.InformationSymbolComponent, [], { infoText: [0, "infoText"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OrganizationsInputComponent_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 6, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OrganizationsInputComponent_3)), i0.ɵdid(10, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OrganizationsInputComponent_5)), i0.ɵdid(12, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OrganizationsInputComponent_7)), i0.ɵdid(14, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.infoText; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.required && _co.editing); _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.editing; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.editing; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.editing; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); }); }
export function View_OrganizationsInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_OrganizationsInputComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.editing || (_co.selectableOrganizations.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OrganizationsInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-organizations-input", [], null, null, null, View_OrganizationsInputComponent_0, RenderType_OrganizationsInputComponent)), i0.ɵdid(1, 49152, null, 0, i10.OrganizationsInputComponent, [[8, null], i11.EditableService, i7.TranslateService, i12.DataService, i13.SearchLinkedOrganizationModalService, i14.LanguageService], null, null)], null, null); }
var OrganizationsInputComponentNgFactory = i0.ɵccf("app-organizations-input", i10.OrganizationsInputComponent, View_OrganizationsInputComponent_Host_0, { label: "label", restrict: "restrict", required: "required", infoText: "infoText" }, {}, []);
export { OrganizationsInputComponentNgFactory as OrganizationsInputComponentNgFactory };
