import { EventEmitter, OnDestroy } from '@angular/core';
import { UserService } from 'yti-common-ui/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { CommentsErrorModalService } from '../components/common/error-modal.service';
import { isModalClose } from 'yti-common-ui/utils/modal';
var EditableService = /** @class */ (function () {
    function EditableService(errorModalService, userService) {
        var _this = this;
        this.errorModalService = errorModalService;
        this.editing$ = new BehaviorSubject(false);
        this.saving$ = new BehaviorSubject(false);
        this.cancel$ = new EventEmitter();
        this.edit$ = new EventEmitter();
        this.loggedInSubscription = userService.loggedIn$.subscribe(function (loggedIn) {
            if (!loggedIn && _this.editing) {
                _this.cancel();
            }
        });
    }
    Object.defineProperty(EditableService.prototype, "onSave", {
        set: function (value) {
            if (this._onSave) {
                throw new Error('Save handler already set');
            }
            this._onSave = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableService.prototype, "editing", {
        get: function () {
            return this.editing$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableService.prototype, "saving", {
        get: function () {
            return this.saving$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    EditableService.prototype.edit = function () {
        this.editing$.next(true);
        this.edit$.next();
    };
    EditableService.prototype.cancel = function () {
        this.editing$.next(false);
        this.cancel$.next();
    };
    EditableService.prototype.save = function (formValue) {
        if (!this._onSave) {
            throw new Error('Save handler missing');
        }
        var that = this;
        this.saving$.next(true);
        this._onSave(formValue).subscribe({
            next: function () {
                that.saving$.next(false);
                that.editing$.next(false);
            },
            error: function (error) {
                that.saving$.next(false);
                if (!isModalClose(error)) {
                    that.errorModalService.openSubmitError(error);
                }
            }
        });
    };
    EditableService.prototype.ngOnDestroy = function () {
        this.loggedInSubscription.unsubscribe();
    };
    return EditableService;
}());
export { EditableService };
