import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getFromLocalStorage, setToLocalStorage } from 'yti-common-ui/utils/storage';
var LanguageService = /** @class */ (function () {
    function LanguageService(translateService) {
        var _this = this;
        this.translateService = translateService;
        this.language$ = new BehaviorSubject(getFromLocalStorage(LanguageService.LANGUAGE_KEY, LanguageService.LANGUAGE_FI));
        this.contentLanguage$ = new BehaviorSubject(getFromLocalStorage(LanguageService.CONTENT_LANGUAGE_KEY, LanguageService.LANGUAGE_FI));
        this.translateLanguage$ = new BehaviorSubject(this.language);
        translateService.addLangs([LanguageService.LANGUAGE_FI, LanguageService.LANGUAGE_EN]);
        translateService.use(LanguageService.LANGUAGE_FI);
        translateService.setDefaultLang(LanguageService.LANGUAGE_EN);
        this.language$.subscribe(function (lang) { return _this.translateService.use(lang); });
        combineLatest(this.language$, this.contentLanguage$)
            .subscribe(function (_a) {
            var lang = _a[0], contentLang = _a[1];
            return _this.translateLanguage$.next(contentLang || lang);
        });
    }
    Object.defineProperty(LanguageService.prototype, "language", {
        get: function () {
            return this.language$.getValue();
        },
        set: function (language) {
            if (this.language !== language) {
                this.language$.next(language);
                setToLocalStorage(LanguageService.LANGUAGE_KEY, language);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LanguageService.prototype, "contentLanguage", {
        get: function () {
            return this.contentLanguage$.getValue();
        },
        set: function (language) {
            if (this.contentLanguage !== language) {
                this.contentLanguage$.next(language);
                setToLocalStorage(LanguageService.CONTENT_LANGUAGE_KEY, language);
            }
        },
        enumerable: true,
        configurable: true
    });
    LanguageService.prototype.translate = function (localizable, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        if (!localizable) {
            return '';
        }
        var primaryLocalization = localizable[useUILanguage ? this.language : this.contentLanguage];
        if (primaryLocalization) {
            return primaryLocalization;
        }
        else {
            // FIXME: dummy fallback
            for (var _i = 0, _a = Object.entries(localizable); _i < _a.length; _i++) {
                var _b = _a[_i], language = _b[0], value = _b[1];
                if (value) {
                    return value + " (" + language + ")";
                }
            }
            return '';
        }
    };
    LanguageService.prototype.isLocalizableEmpty = function (localizable) {
        if (!localizable) {
            return true;
        }
        for (var prop in localizable) {
            if (localizable.hasOwnProperty(prop)) {
                return false;
            }
        }
        return JSON.stringify(localizable) === JSON.stringify({});
    };
    LanguageService.prototype.translateToGivenLanguage = function (localizable, languageToUse) {
        if (languageToUse === void 0) { languageToUse = LanguageService.LANGUAGE_FI; }
        if (!localizable || !languageToUse) {
            return '';
        }
        var primaryLocalization = localizable[languageToUse];
        if (primaryLocalization) {
            return primaryLocalization;
        }
        else {
            var fallbackValue = this.checkForFallbackLanguages(localizable);
            if (fallbackValue != null) {
                return fallbackValue;
            }
            for (var _i = 0, _a = Object.entries(localizable); _i < _a.length; _i++) {
                var _b = _a[_i], language = _b[0], value = _b[1];
                if (value) {
                    return value + " (" + language + ")";
                }
            }
            return '';
        }
    };
    LanguageService.prototype.checkForFallbackLanguages = function (localizable) {
        var fallbackLanguages = [LanguageService.LANGUAGE_EN, LanguageService.LANGUAGE_FI, LanguageService.LANGUAGE_SV];
        for (var _i = 0, fallbackLanguages_1 = fallbackLanguages; _i < fallbackLanguages_1.length; _i++) {
            var language = fallbackLanguages_1[_i];
            if (this.hasLocalizationForLanguage(localizable, language)) {
                return this.fallbackLocalization(localizable, language);
            }
        }
        return null;
    };
    LanguageService.prototype.hasLocalizationForLanguage = function (localizable, language) {
        var value = localizable[language];
        return value != null && value !== '';
    };
    LanguageService.prototype.fallbackLocalization = function (localizable, language) {
        var value = localizable[language];
        return value + " (" + language + ")";
    };
    LanguageService.LANGUAGE_KEY = 'yti-comments-ui.language-service.language';
    LanguageService.CONTENT_LANGUAGE_KEY = 'yti-comments-ui.language-service.content-language';
    LanguageService.LANGUAGE_FI = 'fi';
    LanguageService.LANGUAGE_EN = 'en';
    LanguageService.LANGUAGE_SV = 'sv';
    return LanguageService;
}());
export { LanguageService };
