import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../services/language.service';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../entities/user';
import { EditableService } from '../../services/editable.service';
import { contains } from 'yti-common-ui/utils/array';
var AddTempUsersModalComponent = /** @class */ (function () {
    function AddTempUsersModalComponent(modal, editableService, languageService) {
        this.modal = modal;
        this.editableService = editableService;
        this.languageService = languageService;
        this.emailValidator = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';
        this.tempUsersForm = new FormGroup({
            tempUsers: new FormArray([])
        });
        this.editableService.edit();
        this.addNewRowToUserForm();
    }
    AddTempUsersModalComponent.prototype.removeRow = function (i) {
        this.tempUserForms.removeAt(i);
    };
    Object.defineProperty(AddTempUsersModalComponent.prototype, "tempUserForms", {
        get: function () {
            return this.tempUsersForm.get('tempUsers');
        },
        enumerable: true,
        configurable: true
    });
    AddTempUsersModalComponent.prototype.addNewRowToUserForm = function () {
        // email: new FormControl('', [Validators.required, this.validateEmail.bind(this)], this.asyncFilterEmails().bind(this))
        var userFormGroup = new FormGroup({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, this.validateEmail.bind(this), this.filterEmails.bind(this)])
        });
        this.tempUserForms.push(userFormGroup);
    };
    AddTempUsersModalComponent.prototype.addUsers = function () {
        var users = [];
        var tempUsers = this.tempUserForms;
        tempUsers.controls.forEach(function (tempUserFormGroup) {
            var userType = {
                firstName: tempUserFormGroup.value.firstName,
                lastName: tempUserFormGroup.value.lastName,
                email: tempUserFormGroup.value.email
            };
            users.push(new User(userType));
        });
        this.modal.close(users);
    };
    AddTempUsersModalComponent.prototype.cancel = function () {
        this.modal.dismiss('cancel');
    };
    AddTempUsersModalComponent.prototype.filterEmails = function (control) {
        var allRestrictedEmails = [];
        if (this.restrictedEmails != null) {
            this.restrictedEmails.forEach(function (restrictedEmail) {
                allRestrictedEmails.push(restrictedEmail);
            });
        }
        if (this.tempUserForms) {
            this.tempUserForms.controls.forEach(function (tempUserControl) {
                if (tempUserControl !== control) {
                    var controlEmail = tempUserControl.value.email;
                    if (controlEmail !== '') {
                        allRestrictedEmails.push(controlEmail);
                    }
                }
            });
        }
        if (allRestrictedEmails.length > 0 && control.value != null && control.value.length > 0) {
            var isNotRestricted = !contains(allRestrictedEmails, control.value.toLowerCase());
            return !isNotRestricted ? { 'emailExistsError': { value: control.value } } : null;
        }
        return null;
    };
    AddTempUsersModalComponent.prototype.validateEmail = function (control) {
        if (control.value != null && control.value.length > 0) {
            var isEmailValid = control.value.match(this.emailValidator);
            return !isEmailValid ? { 'emailValidationError': { value: control.value } } : null;
        }
        return null;
    };
    return AddTempUsersModalComponent;
}());
export { AddTempUsersModalComponent };
var AddTempUsersModalService = /** @class */ (function () {
    function AddTempUsersModalService(modalService) {
        this.modalService = modalService;
    }
    AddTempUsersModalService.prototype.open = function (titleLabel, addLabel, restrictedEmails, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        var modalRef = this.modalService.open(AddTempUsersModalComponent, { size: 'lg', backdrop: 'static', keyboard: false });
        var instance = modalRef.componentInstance;
        instance.titleLabel = titleLabel;
        instance.addLabel = addLabel;
        instance.restrictedEmails = restrictedEmails;
        instance.useUILanguage = useUILanguage;
        return modalRef.result;
    };
    return AddTempUsersModalService;
}());
export { AddTempUsersModalService };
