/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/yti-common-ui/components/information-symbol.component.ngfactory";
import * as i2 from "yti-common-ui/components/information-symbol.component";
import * as i3 from "@angular/common";
import * as i4 from "./literal-multilanguage";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../services/language.service";
var styles_LiteralMultilanguageComponent = [];
var RenderType_LiteralMultilanguageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LiteralMultilanguageComponent, data: {} });
export { RenderType_LiteralMultilanguageComponent as RenderType_LiteralMultilanguageComponent };
function View_LiteralMultilanguageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_LiteralMultilanguageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-information-symbol", [], null, null, null, i1.View_InformationSymbolComponent_0, i1.RenderType_InformationSymbolComponent)), i0.ɵdid(1, 49152, null, 0, i2.InformationSymbolComponent, [], { infoText: [0, "infoText"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.infoText; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LiteralMultilanguageComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "language"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.toUpperCase(); _ck(_v, 1, 0, currVal_0); }); }
function View_LiteralMultilanguageComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "localization"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value[_v.parent.context.$implicit]; _ck(_v, 1, 0, currVal_0); }); }
function View_LiteralMultilanguageComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "localization-without-id"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value[_v.parent.context.$implicit]; _ck(_v, 1, 0, currVal_0); }); }
function View_LiteralMultilanguageComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "localized"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiteralMultilanguageComponent_5)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiteralMultilanguageComponent_6)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiteralMultilanguageComponent_7)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLanguageIdentifier(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.showLanguageIdentifier(_v.context.$implicit); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.showLanguageIdentifier(_v.context.$implicit); _ck(_v, 6, 0, currVal_2); }, null); }
function View_LiteralMultilanguageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiteralMultilanguageComponent_4)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.valueLanguages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LiteralMultilanguageComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["-"]))], null, null); }
export function View_LiteralMultilanguageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "dl", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiteralMultilanguageComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiteralMultilanguageComponent_2)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiteralMultilanguageComponent_3)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LiteralMultilanguageComponent_8)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.infoText; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.valueLanguages.length > 0); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.valueLanguages.length === 0); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_LiteralMultilanguageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-literal-multilanguage", [], null, null, null, View_LiteralMultilanguageComponent_0, RenderType_LiteralMultilanguageComponent)), i0.ɵdid(1, 49152, null, 0, i4.LiteralMultilanguageComponent, [i5.TranslateService, i6.LanguageService], null, null)], null, null); }
var LiteralMultilanguageComponentNgFactory = i0.ɵccf("app-literal-multilanguage", i4.LiteralMultilanguageComponent, View_LiteralMultilanguageComponent_Host_0, { label: "label", value: "value", infoText: "infoText" }, {}, []);
export { LiteralMultilanguageComponentNgFactory as LiteralMultilanguageComponentNgFactory };
