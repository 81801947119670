
    <dl *ngIf="show">
      <dt>
        <label *ngIf="label">{{ label }}</label>
        <app-information-symbol *ngIf="label" [infoText]="infoText"></app-information-symbol>
        <app-required-symbol *ngIf="label && required && editing"></app-required-symbol>
      </dt>
      <dd>
        <div *ngIf="editing" class="form-group">
          <input [id]="id"
                 type="text"
                 class="form-control"
                 [ngClass]="{ 'is-invalid': !valid && !pending }"
                 [formControl]="control"/>
          <app-error-messages [id]="id + '_error_messages'" [control]="parentControl"></app-error-messages>
        </div>
        <div class="text-content-wrap" *ngIf="!editing && translate" translate>{{ control.value }}</div>
        <div class="text-content-wrap" *ngIf="!editing && !translate">{{ control.value }}</div>
      </dd>
    </dl>
  