<div class="modal-header">
  <h4 class="modal-title">
    <a><i id="close_modal_link" class="fa fa-times" (click)="cancel()"></i></a>
    <div>
      <span>{{titleLabel}}</span>
    </div>
  </h4>
</div>

<div class="modal-body full-height">
  <div class="modal-sections">

    <div class="modal-section-rigid pb-2">
      <p>{{instructionText}}</p>

      <dl class="selected-resources-container"
          [ngClass]="{'no-resources': selectedResources.length === 0}">
        <dt>
          <label translate>Resources</label>
        </dt>
        <dd>
          <div class="multi-input">
            <div class="added-item" *ngFor="let resource of selectedResources">
              <div class="box">
                <span style="padding-right: 5px">{{resource.getDisplayName(languageService, true)}}</span>
                <div class="badge delete-item" [id]="'remove_' + resource + '_organization_link'" (click)="removeResource(resource)">
                  &times;
                </div>
              </div>
            </div>
          </div>
        </dd>
      </dl>

      <div class="filter-info">
        <span class="search-label search-label with-info" translate>Filter results</span>
      </div>

      <div class="d-inline-block">
        <div class="input-group input-group-lg input-group-search">
          <input #searchInput id="search_linked_source_input" type="text" class="form-control"
                 [placeholder]="searchLabel"
                 [(ngModel)]="search"/>
        </div>
      </div>

      <app-filter-dropdown class="d-inline-block"
                           id="integration_status_filter_dropdown"
                           [filterSubject]="status$"
                           [options]="statusOptions"></app-filter-dropdown>

      <div class="d-inline-block">
        <div class="input-group input-group-lg pl-2">
          <button
                  id="pick_all_modal_button"
                  type="button"
                  class="btn btn-action"
                  (click)="pickAll()">
            <span translate>Pick all</span>
          </button>
        </div>
      </div>
    </div>

    <div class="modal-section-flexible content-box">
      <app-integration-resource-virtual-scroller
        *ngIf="virtualScrollerInstanceToggle"
        [containerUri]="containerUri"
        [containerType]="containerType"
        [language]="languageService.language"
        [items]="searchResults$"
        [status$]="status$"
        [search$]="search$"
        [restricts]="restricts"
        [selectedResources$]="selectedResources$"
        (selectResourceEvent)="selectResource($event)">
      </app-integration-resource-virtual-scroller>
      <app-integration-resource-virtual-scroller
        *ngIf="!virtualScrollerInstanceToggle"
        [containerUri]="containerUri"
        [containerType]="containerType"
        [language]="languageService.language"
        [items]="searchResults$"
        [status$]="status$"
        [search$]="search$"
        [restricts]="restricts"
        [selectedResources$]="selectedResources$"
        (selectResourceEvent)="selectResource($event)">
      </app-integration-resource-virtual-scroller>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button *ngIf="selectedResources && selectedResources.length > 0"
          id="select_modal_button"
          type="button"
          class="btn btn-action"
          (click)="select()">
    <span translate>Select</span>
  </button>

  <button id="cancel_modal_button"
          type="button"
          class="btn btn-link cancel"
          (click)="cancel()">
    <span translate>Cancel</span>
  </button>

  <button *ngIf="openThreads"
          id="create_suggestion_button"
          type="button"
          class="btn btn-secondary-action"
          (click)="createEmptyResource()">
    <span *ngIf="this.selectedResources && this.selectedResources.length > 0" translate>Create new suggestion and add selected resources</span>
    <span *ngIf="!this.selectedResources || this.selectedResources.length === 0" translate>Create new suggestion</span>
  </button>
</div>
