import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, concat, Observable } from 'rxjs';
import { LanguageService } from '../../services/language.service';
import { contains } from 'yti-common-ui/utils/array';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { debounceTime, map, skip, take, tap } from 'rxjs/operators';
import { comparingLocalizable } from 'yti-common-ui/utils/comparator';
var SearchLinkedOrganizationModalComponent = /** @class */ (function () {
    function SearchLinkedOrganizationModalComponent(modal, languageService) {
        this.modal = modal;
        this.languageService = languageService;
        this.search$ = new BehaviorSubject('');
        this.loading = false;
    }
    SearchLinkedOrganizationModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        var initialSearch = this.search$.pipe(take(1));
        var debouncedSearch = this.search$.pipe(skip(1), debounceTime(500));
        this.searchResults$ = combineLatest(this.organizations$, concat(initialSearch, debouncedSearch))
            .pipe(tap(function () { return _this.loading = false; }), map(function (_a) {
            var organizations = _a[0], search = _a[1];
            return organizations.filter(function (organization) {
                var label = _this.languageService.translate(organization.prefLabel, true);
                var searchMatches = !search || label.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                var isNotRestricted = !contains(_this.restricts, organization.id);
                return searchMatches && isNotRestricted;
            }).sort(comparingLocalizable(_this.languageService, function (organization) { return organization.prefLabel ? organization.prefLabel : {}; }));
        }));
    };
    SearchLinkedOrganizationModalComponent.prototype.select = function (organization) {
        this.modal.close(organization);
    };
    SearchLinkedOrganizationModalComponent.prototype.ngAfterViewInit = function () {
        this.searchInput.nativeElement.focus();
    };
    Object.defineProperty(SearchLinkedOrganizationModalComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    SearchLinkedOrganizationModalComponent.prototype.cancel = function () {
        this.modal.dismiss('cancel');
    };
    return SearchLinkedOrganizationModalComponent;
}());
export { SearchLinkedOrganizationModalComponent };
var SearchLinkedOrganizationModalService = /** @class */ (function () {
    function SearchLinkedOrganizationModalService(modalService) {
        this.modalService = modalService;
    }
    SearchLinkedOrganizationModalService.prototype.open = function (organizations$, titleLabel, searchLabel, restrictOrganizationIds, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        var modalRef = this.modalService.open(SearchLinkedOrganizationModalComponent, { size: 'sm' });
        var instance = modalRef.componentInstance;
        instance.organizations$ = organizations$;
        instance.titleLabel = titleLabel;
        instance.searchLabel = searchLabel;
        instance.restricts = restrictOrganizationIds;
        instance.useUILanguage = useUILanguage;
        return modalRef.result;
    };
    return SearchLinkedOrganizationModalService;
}());
export { SearchLinkedOrganizationModalService };
