/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown";
import * as i3 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown-config";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./comment-round-status-dropdown.component";
var styles_CommentRoundStatusDropdownComponent = [];
var RenderType_CommentRoundStatusDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommentRoundStatusDropdownComponent, data: {} });
export { RenderType_CommentRoundStatusDropdownComponent as RenderType_CommentRoundStatusDropdownComponent };
function View_CommentRoundStatusDropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "dropdown-item"]], [[8, "id", 0], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit + "_") + _co.id); var currVal_1 = _co.isSelected(_v.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_v.context.$implicit)); _ck(_v, 2, 0, currVal_2); }); }
export function View_CommentRoundStatusDropdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(1, 212992, null, 2, i2.NgbDropdown, [i0.ChangeDetectorRef, i3.NgbDropdownConfig, i4.DOCUMENT, i0.NgZone], { placement: [0, "placement"] }, null), i0.ɵqud(335544320, 1, { _menu: 0 }), i0.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 5, "button", [["aria-haspopup", "true"], ["class", "btn btn-dropdown dropdown-toggle"], ["ngbDropdownToggle", ""]], [[8, "id", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[2, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i0.ɵdid(11, 16384, [[1, 4]], 0, i2.NgbDropdownMenu, [i2.NgbDropdown, i0.ElementRef, i0.Renderer2], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CommentRoundStatusDropdownComponent_1)), i0.ɵdid(13, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.placement; _ck(_v, 1, 0, currVal_1); var currVal_8 = _co.options; _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = ("selected_" + _co.id); var currVal_3 = i0.ɵnov(_v, 5).dropdown.isOpen(); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.selection)); _ck(_v, 8, 0, currVal_4); var currVal_5 = true; var currVal_6 = i0.ɵnov(_v, 11).dropdown.isOpen(); var currVal_7 = i0.ɵnov(_v, 11).placement; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_CommentRoundStatusDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-comment-round-status-dropdown", [], null, null, null, View_CommentRoundStatusDropdownComponent_0, RenderType_CommentRoundStatusDropdownComponent)), i0.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.CommentRoundStatusDropdownComponent]), i0.ɵdid(2, 49152, null, 0, i6.CommentRoundStatusDropdownComponent, [], null, null)], null, null); }
var CommentRoundStatusDropdownComponentNgFactory = i0.ɵccf("app-comment-round-status-dropdown", i6.CommentRoundStatusDropdownComponent, View_CommentRoundStatusDropdownComponent_Host_0, { id: "id", placement: "placement", restrict: "restrict" }, {}, []);
export { CommentRoundStatusDropdownComponentNgFactory as CommentRoundStatusDropdownComponentNgFactory };
