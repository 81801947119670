import { EditableService } from '../../services/editable.service';
import { NgForm } from '@angular/forms';
import { AuthorizationManager } from '../../services/authorization-manager';
var EditableButtonsComponent = /** @class */ (function () {
    function EditableButtonsComponent(editableService, authorizationManager) {
        this.editableService = editableService;
        this.authorizationManager = authorizationManager;
    }
    Object.defineProperty(EditableButtonsComponent.prototype, "invalid", {
        get: function () {
            var invalid = this.form.invalid || false;
            var pending = this.form.pending || false;
            return invalid || pending;
        },
        enumerable: true,
        configurable: true
    });
    EditableButtonsComponent.prototype.edit = function () {
        this.editableService.edit();
    };
    EditableButtonsComponent.prototype.save = function () {
        this.editableService.save(this.form.form.value);
    };
    EditableButtonsComponent.prototype.cancel = function () {
        this.editableService.cancel();
    };
    EditableButtonsComponent.prototype.canEdit = function () {
        if (!this.entity) {
            return true;
        }
        else {
            return this.authorizationManager.canEdit(this.entity);
        }
    };
    EditableButtonsComponent.prototype.canSave = function () {
        return !this.form.invalid && !this.form.pending;
    };
    Object.defineProperty(EditableButtonsComponent.prototype, "operationPending", {
        get: function () {
            return this.saving;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableButtonsComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableButtonsComponent.prototype, "saving", {
        get: function () {
            return this.editableService.saving;
        },
        enumerable: true,
        configurable: true
    });
    return EditableButtonsComponent;
}());
export { EditableButtonsComponent };
