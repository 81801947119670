<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="tab-container">
  <div class="row float-right mb-3 mr-1">
    <div *ngIf="canStartCommenting">
      <button id="start_commenting_button"
              type="button"
              class="btn btn-action ml-3"
              (click)="startCommenting()">
        <span translate>Start commenting</span>
      </button>
    </div>

    <div *ngIf="showCancelCommenting">
      <button id="cancel_commenting_button"
              type="button"
              class="btn btn-link ml-3"
              (click)="cancelCommenting()">
        <span translate>Cancel</span>
      </button>
    </div>

    <div *ngIf="canSendComments">
      <button id="send_comments_button"
              type="button"
              class="btn btn-action ml-3"
              [disabled]="!commentsHaveContent"
              (click)="sendMyComments()">
        <span translate>Send comments</span>
      </button>
    </div>
  </div>

  <form [formGroup]="commentThreadForm" #form="ngForm">
    <div formArrayName="commentThreads">
      <table *ngIf="hasCommentThreads">
        <thead>
        <tr>
          <th id="resource_name_header" style="width: 10%">
            <div class="sort-content" (click)="sortContent('alphabetical')">
              <span translate>Resource</span>
              <span [class.sort-content-active]="sortOption === 'alphabetical'"> ▼</span>
            </div>
          </th>
          <th id="proposed_comment_header" style="width: 13%" translate>Proposer's comment</th>
          <th id="comment_header" style="width: 13%"
              translate>{{ commenting ? 'Your comment' : 'Commenter\'s comment' }}</th>
          <th id="added_header" style="width: 9%">
            <div class="sort-content" (click)="sortContent('created')">
              <span translate>Added</span>
              <span [class.sort-content-active]="sortOption === 'created'"> ▼</span>
            </div>
          </th>
          <th id="added_by_header" *ngIf="!commentRound.fixedThreads" style="width: 10%" translate>Added by</th>
          <th id="description_header" class="description" style="width: 15%" translate>Description</th>
          <th id="current_status_header" style="width: 10%" translate>Current status</th>
          <th id="proposed_status_header" style="width: 10%" translate>Proposed status</th>
          <th id="resource_commenters_status_header" style="width: 10%" translate>{{ commenting ? 'Propose status' :
            'Commenter\'s proposal for status' }}</th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let commentThread of commentThreadForms.controls; let i = index;" [formGroupName]="i">
          <td style="width: 10%">
            <a *ngIf="commentThread.value.resourceUri"
               [href]="getCommentThreadResourceUri(commentThread.value)"
               [id]="'commentthread_link_' + i"
               target="_blank"
               rel="noopener noreferrer">
              <div ngbPopover="{{ commentThread.value.description | translateValue:true }}" triggers="mouseenter:mouseleave" placement="right">
                <app-literal-multilanguage *ngIf="hasLocalization(commentThread.value.label)" [value]="commentThread.value.label"></app-literal-multilanguage>
                <app-literal *ngIf="!hasLocalization(commentThread.value.label) && commentThread.value.localName" [value]="commentThread.value.localName"></app-literal>
                <app-literal *ngIf="!hasLocalization(commentThread.value.label) && commentThread.value.localName == null"
                             [value]="commentThread.value.resourceUri"></app-literal>
              </div>
            </a>
            <app-literal-multilanguage *ngIf="!commentThread.value.resourceUri"
                                       [value]="commentThread.value.label"></app-literal-multilanguage>
          </td>
          <td style="width: 13%">
            <span>{{ commentThread.value.proposedText ? commentThread.value.proposedText : '-'}}</span>
          </td>
          <td style="width: 13%">
            <app-literal-textarea *ngIf="canModifyComment(commentThread.value.id)"
                                  [showRequired]=false
                                  [isEditing]="commenting"
                                  [id]="'commentthread_proposed_text_input_' + i"
                                  [formControlName]="'commentersProposedText'"></app-literal-textarea>
            <span *ngIf="!canModifyComment(commentThread.value.id)">{{ commentThread.value.commentersProposedText }}</span>
          </td>
          <td style="width: 9%">
            <span>{{ formatDisplayDate(commentThread.value.created) }}</span>
          </td>
          <td *ngIf="!commentRound.fixedThreads" style="width: 10%">
            <span>{{ commentThread.value.user ? getCommentThreadUserDisplayName(commentThread.value.user) : authorizationManager.user ? authorizationManager.user.name : '-'}}</span>
          </td>
          <td class="description" style="width: 15%">
                    <span class="wrap"
                          *ngIf="commentThread.value.resourceUri && !languageService.isLocalizableEmpty(commentThread.value.description)">{{ commentThread.value.description | translateValue }}</span>
            <span *ngIf="commentThread.value.resourceUri && languageService.isLocalizableEmpty(commentThread.value.description)">-</span>
            <app-localizable-undefined-textarea *ngIf="!commentThread.value.resourceUri"
                                                [required]=true
                                                [showRequired]=false
                                                [restrict]="true"
                                                [id]="'commenthread_' + i + '_description_text_input'"
                                                [formControlName]="'description'"></app-localizable-undefined-textarea>
          </td>
          <td style="width: 10%">
            <app-status *ngIf="commentThread.value.currentStatus" class="status"
                        [status]="commentThread.value.currentStatus !== 'NOSTATUS' ? commentThread.value.currentStatus : 'SUGGESTED'"></app-status>
            <span *ngIf="!commentThread.value.currentStatus">-</span>
          </td>
          <td style="width: 10%">
            <app-status class="status" [status]="commentThread.value.proposedStatus !== 'NOSTATUS' ? commentThread.value.proposedStatus : 'SUGGESTED'"></app-status>
          </td>
          <td style="width: 10%">
            <app-proposed-status-table-dropdown *ngIf="canModifyCommentProposedStatus()"
                                                [isEditing]="commenting"
                                                [id]="i"
                                                [defaultStatus]="commentThread.value.proposedStatus"
                                                [formControlName]="'commentersProposedEndStatus'"></app-proposed-status-table-dropdown>
            <app-status *ngIf="!canModifyCommentProposedStatus() && commentThread.value.commentersProposedStatus" class="status"
                        [status]="commentThread.value.commentersProposedStatus"></app-status>
            <span *ngIf="!canModifyCommentProposedStatus() && !commentThread.value.commentersProposedStatus">-</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </form>
</div>
