
    <div *ngIf="editing" ngbDropdown [placement]="placement">
      <button [id]="'selected_proposed_status_' + id" class="btn btn-dropdown" ngbDropdownToggle>
        <span translate>{{ selectedStatus }}</span>
      </button>
      <div ngbDropdownMenu>
        <button *ngFor="let option of options"
                [id]="option + '_' + id"
                (click)="select(option)"
                class="dropdown-item"
                [class.active]="isSelected(option)">
          {{option | translate}}
        </button>
      </div>
    </div>
    <div *ngIf="!editing">
      <span *ngIf="selectedStatus === 'NOSTATUS'">-</span>
      <app-status *ngIf="selectedStatus !== 'NOSTATUS'" class="status" [status]="selectedStatus"></app-status>
    </div>
  