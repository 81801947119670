/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./refresh.component";
import * as i2 from "@angular/router";
var styles_RefreshComponent = [];
var RenderType_RefreshComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RefreshComponent, data: {} });
export { RenderType_RefreshComponent as RenderType_RefreshComponent };
export function View_RefreshComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
export function View_RefreshComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-refresh", [], null, null, null, View_RefreshComponent_0, RenderType_RefreshComponent)), i0.ɵdid(1, 245760, null, 0, i1.RefreshComponent, [i2.ActivatedRoute, i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RefreshComponentNgFactory = i0.ɵccf("app-refresh", i1.RefreshComponent, View_RefreshComponent_Host_0, {}, {}, []);
export { RefreshComponentNgFactory as RefreshComponentNgFactory };
