<div class="content-box tab-container">
  <div class="main">
    <div>
      <h1 class="float-left" translate>Comment round information</h1>
    </div>

    <div>
      <app-editable-buttons [form]="form"></app-editable-buttons>
    </div>
  </div>

  <form [formGroup]="commentRoundForm" #form="ngForm">
    <div class="row">
      <div class="col-md-11">
        <div class="form-group">
          <app-error-messages id="commentround_form_error_messages" [control]="commentRoundForm"></app-error-messages>
        </div>

        <div class="row">
          <app-literal-input class="col-md-4"
                             [id]="'commentround_name_input'"
                             [label]="'Round name' | translate"
                             [required]="true"
                             [infoText]="'INFO_TEXT_COMMENTROUND_NAME'"
                             [formControlName]="'label'"></app-literal-input>

          <app-literal class="col-md-2"
                       [id]="'commentround_container_type'"
                       [label]="'Tool' | translate"
                       [infoText]="'INFO_TEXT_COMMENTROUND_TOOL_TYPE'"
                       [value]="toolType | translate"></app-literal>

          <app-comment-round-date-range-input class="col-md-6"
                                              [id]="'commentround_validity_input'"
                                              [label]="'Commenting period' | translate"
                                              [showLabel]="true"
                                              [startLabel]="'Start of commenting' | translate"
                                              [endLabel]="'End of commenting' | translate"
                                              [infoText]="'INFO_TEXT_COMMENTROUND_VALIDITY'"
                                              [formControlName]="'validity'"></app-comment-round-date-range-input>
        </div>

        <app-literal-textarea [id]="'commentround_description_input'"
                              [label]="'Description' | translate"
                              [required]="true"
                              [infoText]="'INFO_TEXT_COMMENTROUND_DESCRIPTION'"
                              [formControlName]="'description'"></app-literal-textarea>

        <div class="row main">
          <app-source-input class="col-md-6"
                            id="integration_resource_input"
                            [label]="'Source' | translate"
                            [required]="true"
                            [infoText]="'INFO_TEXT_COMMENTROUND_INTEGRATION_RESOURCE'"
                            [formControlName]="'resource'"></app-source-input>

          <app-boolean-input class="col-md-3 bottom"
                             [id]="'commentround_fixedthreads_textarea'"
                             [label]="'Only selected resources' | translate"
                             [infoText]="'INFO_TEXT_COMMENTROUND_FIXED_THREADS'"
                             [formControlName]="'fixedThreads'"></app-boolean-input>

          <app-boolean-input class="col-md-3 bottom"
                             [id]="'commentround_openthreads_input'"
                             [label]="'Allow new comment threads' | translate"
                             [infoText]="'INFO_TEXT_COMMENTROUND_OPEN_THREADS'"
                             [formControlName]="'openThreads'"></app-boolean-input>
        </div>

        <app-inline-clipboard *ngIf="getResourceUri"
                       [id]="'integration_resource_containeruri'"
                       [label]="'Source URI' | translate"
                       [value]="getResourceUri"
                       [showAsLink]="false"
                       [infoText]="'INFO_TEXT_COMMENTROUND_INTEGRATION_RESOURCE_URI'"></app-inline-clipboard>

        <app-organizations-input id="commentround_organizations_input"
                                 [label]="'Organization' | translate"
                                 [required]="true"
                                 [infoText]="'INFO_TEXT_COMMENTROUND_ORGANIZATION'"
                                 [formControlName]="'organizations'"></app-organizations-input>
      </div>
    </div>

  </form>
</div>
