/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./integration-resource-virtual-scroller.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./integration-resource-list-item-component.ngfactory";
import * as i3 from "./integration-resource-list-item-component";
import * as i4 from "../../services/configuration.service";
import * as i5 from "../../services/language.service";
import * as i6 from "../../../../node_modules/yti-common-ui/components/ajax-loading-indicator.component.ngfactory";
import * as i7 from "yti-common-ui/components/ajax-loading-indicator.component";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../node_modules/ngx-virtual-scroller/ngx-virtual-scroller.ngfactory";
import * as i10 from "ngx-virtual-scroller";
import * as i11 from "@angular/common";
import * as i12 from "./integration-resource-virtual-scroller-component";
import * as i13 from "../../services/data.service";
var styles_IntegrationResourceVirtualScrollerComponent = [i0.styles];
var RenderType_IntegrationResourceVirtualScrollerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntegrationResourceVirtualScrollerComponent, data: {} });
export { RenderType_IntegrationResourceVirtualScrollerComponent as RenderType_IntegrationResourceVirtualScrollerComponent };
function View_IntegrationResourceVirtualScrollerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-integration-resource-list-item", [], null, [[null, "selectResourceEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectResourceEvent" === en)) {
        var pd_0 = (_co.emitSelectResourceEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IntegrationResourceListItemComponent_0, i2.RenderType_IntegrationResourceListItemComponent)), i1.ɵdid(1, 245760, null, 0, i3.IntegrationResourceListItemComponent, [i4.ConfigurationService, i5.LanguageService], { resource: [0, "resource"], theLast: [1, "theLast"], expanded: [2, "expanded"], selectedResources$: [3, "selectedResources$"] }, { selectResourceEvent: "selectResourceEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.last; var currVal_2 = _v.context.$implicit.expanded; var currVal_3 = _co.selectedResources$; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_IntegrationResourceVirtualScrollerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-ajax-loading-indicator", [], null, null, null, i6.View_AjaxLoadingIndicatorComponent_0, i6.RenderType_AjaxLoadingIndicatorComponent)), i1.ɵdid(2, 49152, null, 0, i7.AjaxLoadingIndicatorComponent, [], null, null)], null, null); }
function View_IntegrationResourceVirtualScrollerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "infoText"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i8.TranslateDirective, [i8.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Search did not find in any resources for commenting."]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_IntegrationResourceVirtualScrollerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { virtualScroller: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "virtual-scroller", [], [[2, "horizontal", null], [2, "vertical", null], [2, "selfScroll", null]], [[null, "vsEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("vsEnd" === en)) {
        var pd_0 = (_co.fetchMore($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_VirtualScrollerComponent_0, i9.RenderType_VirtualScrollerComponent)), i1.ɵdid(2, 1032192, [[1, 4], ["scroll", 4]], 2, i10.VirtualScrollerComponent, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, "virtual-scroller-default-options"]], { enableUnequalChildrenSizes: [0, "enableUnequalChildrenSizes"], items: [1, "items"] }, { vsEnd: "vsEnd" }), i1.ɵqud(335544320, 2, { headerElementRef: 0 }), i1.ɵqud(335544320, 3, { containerElementRef: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_IntegrationResourceVirtualScrollerComponent_1)), i1.ɵdid(6, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_IntegrationResourceVirtualScrollerComponent_2)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_IntegrationResourceVirtualScrollerComponent_3)), i1.ɵdid(10, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = true; var currVal_4 = _co.buffer; _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 2).viewPortItems; _ck(_v, 6, 0, currVal_5); var currVal_6 = _co.loading; _ck(_v, 8, 0, currVal_6); var currVal_7 = ((_co.buffer.length == 0) && !_co.loading); _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).horizontal; var currVal_1 = !i1.ɵnov(_v, 2).horizontal; var currVal_2 = !i1.ɵnov(_v, 2).parentScroll; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_IntegrationResourceVirtualScrollerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-integration-resource-virtual-scroller", [], null, null, null, View_IntegrationResourceVirtualScrollerComponent_0, RenderType_IntegrationResourceVirtualScrollerComponent)), i1.ɵdid(1, 49152, null, 0, i12.IntegrationResourceVirtualScrollerComponent, [i4.ConfigurationService, i5.LanguageService, i13.DataService], null, null)], null, null); }
var IntegrationResourceVirtualScrollerComponentNgFactory = i1.ɵccf("app-integration-resource-virtual-scroller", i12.IntegrationResourceVirtualScrollerComponent, View_IntegrationResourceVirtualScrollerComponent_Host_0, { containerType: "containerType", containerUri: "containerUri", language: "language", items: "items", status$: "status$", search$: "search$", restricts: "restricts", selectedResources$: "selectedResources$" }, { selectResourceEvent: "selectResourceEvent" }, []);
export { IntegrationResourceVirtualScrollerComponentNgFactory as IntegrationResourceVirtualScrollerComponentNgFactory };
