import { formatDateTime, formatDisplayDateTime } from '../utils/date';
import { utc } from 'moment';
var IntegrationResource = /** @class */ (function () {
    function IntegrationResource(data) {
        this.prefLabel = {};
        this.description = {};
        this.modified = null;
        this.expanded = true; // only used in virtual scroller in the UI, no need to deal with this in the constructor or serialize() method
        this.id = data.id;
        this.uri = data.uri;
        this.prefLabel = data.prefLabel;
        this.description = data.description;
        if (data.localName) {
            this.localName = data.localName;
        }
        this.status = data.status;
        if (data.type) {
            this.type = data.type;
        }
        if (data.modified) {
            this.modified = utc(data.modified);
        }
    }
    IntegrationResource.prototype.serialize = function () {
        return {
            id: this.id,
            uri: this.uri,
            prefLabel: this.prefLabel,
            description: this.description,
            localName: this.localName,
            status: this.status,
            modified: formatDateTime(this.modified),
            type: this.type
        };
    };
    IntegrationResource.prototype.getDisplayName = function (localizer, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        if (!localizer.isLocalizableEmpty(this.prefLabel)) {
            return localizer.translate(this.prefLabel, useUILanguage);
        }
        else if (this.localName) {
            return this.localName;
        }
        return this.uri;
    };
    IntegrationResource.prototype.getDescription = function (localizer, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        return localizer.translate(this.description, useUILanguage);
    };
    Object.defineProperty(IntegrationResource.prototype, "modifiedDisplayValue", {
        get: function () {
            return formatDisplayDateTime(this.modified);
        },
        enumerable: true,
        configurable: true
    });
    return IntegrationResource;
}());
export { IntegrationResource };
