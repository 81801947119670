import { EditableService } from '../../services/editable.service';
import { FormControl, NgControl } from '@angular/forms';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { DataService } from '../../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { SearchLinkedContainerModalService } from './search-linked-integration-container-modal.component';
function addToControl(control, item) {
    control.setValue(item);
}
function removeFromControl(control) {
    control.setValue(null);
}
var SourceInputComponent = /** @class */ (function () {
    function SourceInputComponent(parentControl, editableService, translateService, dataService, searchLinkedContainerModalService, languageService) {
        var _this = this;
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.translateService = translateService;
        this.dataService = dataService;
        this.searchLinkedContainerModalService = searchLinkedContainerModalService;
        this.languageService = languageService;
        this.required = false;
        this.restricts = [];
        this.control = new FormControl(null);
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
    }
    Object.defineProperty(SourceInputComponent.prototype, "resource", {
        get: function () {
            return this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    SourceInputComponent.prototype.selectSource = function () {
        var _this = this;
        this.searchLinkedContainerModalService
            .open(this.containerType, this.restricts, true)
            .then(function (source) { return addToControl(_this.control, source); }, ignoreModalClose);
    };
    SourceInputComponent.prototype.removeSource = function () {
        removeFromControl(this.control);
    };
    Object.defineProperty(SourceInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    SourceInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    SourceInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    SourceInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return SourceInputComponent;
}());
export { SourceInputComponent };
