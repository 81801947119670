/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boolean-input-component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/yti-common-ui/components/required-symbol.component.ngfactory";
import * as i3 from "yti-common-ui/components/required-symbol.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../node_modules/yti-common-ui/components/information-symbol.component.ngfactory";
import * as i6 from "yti-common-ui/components/information-symbol.component";
import * as i7 from "@angular/common";
import * as i8 from "./boolean-input-component";
import * as i9 from "../../services/editable.service";
var styles_BooleanInputComponent = [i0.styles];
var RenderType_BooleanInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BooleanInputComponent, data: {} });
export { RenderType_BooleanInputComponent as RenderType_BooleanInputComponent };
function View_BooleanInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-required-symbol", [], null, null, null, i2.View_RequiredSymbolComponent_0, i2.RenderType_RequiredSymbolComponent)), i1.ɵdid(1, 49152, null, 0, i3.RequiredSymbolComponent, [], null, null)], null, null); }
function View_BooleanInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i4.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CheckboxControlValueAccessor]), i1.ɵdid(4, 540672, null, 0, i4.FormControlDirective, [[8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR], [2, i4.ɵangular_packages_forms_forms_j]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.NgControl, null, [i4.FormControlDirective]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["for", "id"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-information-symbol", [], null, null, null, i5.View_InformationSymbolComponent_0, i5.RenderType_InformationSymbolComponent)), i1.ɵdid(10, 49152, null, 0, i6.InformationSymbolComponent, [], { infoText: [0, "infoText"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BooleanInputComponent_2)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.control; _ck(_v, 4, 0, currVal_8); var currVal_10 = _co.infoText; _ck(_v, 10, 0, currVal_10); var currVal_11 = (_co.required && _co.editing); _ck(_v, 12, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = _co.label; _ck(_v, 8, 0, currVal_9); }); }
function View_BooleanInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["disabled", ""], ["type", "checkbox"]], [[8, "id", 0], [8, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-information-symbol", [], null, null, null, i5.View_InformationSymbolComponent_0, i5.RenderType_InformationSymbolComponent)), i1.ɵdid(5, 49152, null, 0, i6.InformationSymbolComponent, [], { infoText: [0, "infoText"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.infoText; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = _co.control.value; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.id; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.label; _ck(_v, 3, 0, currVal_3); }); }
export function View_BooleanInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "dl", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BooleanInputComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BooleanInputComponent_3)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editing; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.editing; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BooleanInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-boolean-input", [], null, null, null, View_BooleanInputComponent_0, RenderType_BooleanInputComponent)), i1.ɵdid(1, 49152, null, 0, i8.BooleanInputComponent, [[8, null], i9.EditableService], null, null)], null, null); }
var BooleanInputComponentNgFactory = i1.ɵccf("app-boolean-input", i8.BooleanInputComponent, View_BooleanInputComponent_Host_0, { label: "label", restrict: "restrict", required: "required", id: "id", infoText: "infoText" }, {}, []);
export { BooleanInputComponentNgFactory as BooleanInputComponentNgFactory };
