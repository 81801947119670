import { LanguageService } from '../../services/language.service';
import { LoginModalService } from 'yti-common-ui/components/login-modal.component';
import { UserService } from 'yti-common-ui/services/user.service';
import { DataService } from '../../services/data.service';
import { ConfigurationService } from '../../services/configuration.service';
var NavigationBarComponent = /** @class */ (function () {
    function NavigationBarComponent(languageService, userService, loginModal, dataService, configurationService) {
        var _this = this;
        this.languageService = languageService;
        this.userService = userService;
        this.loginModal = loginModal;
        this.dataService = dataService;
        this.configurationService = configurationService;
        this.availableLanguages = [
            { code: 'fi', name: 'Suomeksi (FI)' },
            { code: 'sv', name: 'På svenska (SV)' },
            { code: 'en', name: 'In English (EN)' }
        ];
        this.fakeableUsers = [];
        dataService.getFakeableUsers().subscribe(function (users) {
            _this.fakeableUsers = users;
        });
    }
    Object.defineProperty(NavigationBarComponent.prototype, "noMenuItemsAvailable", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    NavigationBarComponent.prototype.logIn = function () {
        this.loginModal.open();
    };
    NavigationBarComponent.prototype.logOut = function () {
        this.userService.logout();
    };
    Object.defineProperty(NavigationBarComponent.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    NavigationBarComponent.prototype.isLoggedIn = function () {
        return this.userService.isLoggedIn();
    };
    Object.defineProperty(NavigationBarComponent.prototype, "language", {
        get: function () {
            return this.languageService.language;
        },
        set: function (language) {
            this.languageService.language = language;
        },
        enumerable: true,
        configurable: true
    });
    NavigationBarComponent.prototype.isLanguageSelected = function (language) {
        return language === this.language;
    };
    NavigationBarComponent.prototype.fakeUser = function (userEmail) {
        var oldEmail = this.user.email;
        if (oldEmail !== userEmail) {
            this.userService.updateLoggedInUser(userEmail);
            this.refreshPageOnUserUpdate(userEmail);
        }
    };
    NavigationBarComponent.prototype.refreshPageOnUserUpdate = function (userEmail) {
        var _this = this;
        setTimeout(function () {
            if (_this.user.email === userEmail) {
                window.location.reload();
            }
            else {
                _this.refreshPageOnUserUpdate(userEmail);
            }
        }, 500);
    };
    NavigationBarComponent.prototype.showGroupManagementUrl = function () {
        return this.user.superuser || this.user.isAdminInAnyOrganization();
    };
    Object.defineProperty(NavigationBarComponent.prototype, "environmentIdentifier", {
        get: function () {
            return this.configurationService.getEnvironmentIdentifier('postfix');
        },
        enumerable: true,
        configurable: true
    });
    return NavigationBarComponent;
}());
export { NavigationBarComponent };
