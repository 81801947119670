import { OnDestroy, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { LocationService } from '../../services/location.service';
import { AuthorizationManager } from '../../services/authorization-manager';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, concat } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { allCommentRoundStatuses } from '../../entities/comment-round-status';
import { comparingLocalizable, comparingPrimitive } from 'yti-common-ui/utils/comparator';
import { labelNameToResourceIdIdentifier } from 'yti-common-ui/utils/resource';
import { LanguageService } from '../../services/language.service';
import { debounceTime, flatMap, skip, take, tap } from 'rxjs/operators';
import { containerTypes } from '../common/containertypes';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { IntegrationResourceService } from '../../services/integrationresource.service';
import { SearchLinkedContainerModalService } from '../form/search-linked-integration-container-modal.component';
import { UserService } from 'yti-common-ui/services/user.service';
var FrontpageComponent = /** @class */ (function () {
    function FrontpageComponent(dataService, integrationResourceService, locationService, authorizationManager, userService, router, translateService, languageService, searchLinkedContainerModalService) {
        this.dataService = dataService;
        this.integrationResourceService = integrationResourceService;
        this.locationService = locationService;
        this.authorizationManager = authorizationManager;
        this.userService = userService;
        this.router = router;
        this.translateService = translateService;
        this.languageService = languageService;
        this.searchLinkedContainerModalService = searchLinkedContainerModalService;
        this.searchInProgress = true;
        this.searchTerm$ = new BehaviorSubject('');
        this.status$ = new BehaviorSubject(null);
        this.organization$ = new BehaviorSubject(null);
        this.containerType$ = new BehaviorSubject(null);
        this.subscriptionToClean = [];
        this.searchError = false;
        locationService.atFrontPage();
    }
    FrontpageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptionToClean.push(combineLatest(this.dataService.getOrganizationsWithCommentRounds(), this.languageService.language$)
            .subscribe(function (_a) {
            var organizations = _a[0];
            _this.organizationOptions = [null].concat(organizations).map(function (organization) { return ({
                value: organization,
                name: function () { return organization ? _this.languageService.translate(organization.prefLabel, true)
                    : _this.translateService.instant('All organizations'); },
                idIdentifier: function () { return organization ? labelNameToResourceIdIdentifier(_this.languageService.translate(organization.prefLabel, true))
                    : 'all_selected'; }
            }); });
            _this.organizationOptions.sort(comparingLocalizable(_this.languageService, function (organization) {
                return organization.value ? organization.value.prefLabel : {};
            }));
        }));
        this.statusOptions = [null].concat(allCommentRoundStatuses).map(function (status) { return ({
            value: status,
            name: function () { return _this.translateService.instant(status ? status : 'All statuses'); },
            idIdentifier: function () { return status ? status : 'all_selected'; }
        }); });
        this.containerTypeOptions = [null].concat(containerTypes).map(function (containerType) { return ({
            value: containerType,
            name: function () { return _this.translateService.instant(containerType ? containerType : 'All tools'); },
            idIdentifier: function () { return containerType ? containerType : 'all_selected'; }
        }); });
        var initialSearchTerm = this.searchTerm$.pipe(take(1));
        var debouncedSearchTerm = this.searchTerm$.pipe(skip(1), debounceTime(500));
        var searchTerm$ = concat(initialSearchTerm, debouncedSearchTerm);
        combineLatest(this.status$, this.organization$, this.containerType$, searchTerm$)
            .pipe(tap(function () { return _this.searchInProgress = true; }), flatMap(function (_a) {
            var status = _a[0], organization = _a[1], containerType = _a[2], searchTerm = _a[3];
            var organizationId = organization ? organization.id : null;
            return _this.dataService.getCommentRounds(organizationId, status, containerType, searchTerm, true, true);
        }), tap(function () { return _this.searchInProgress = false; }))
            .subscribe(function (results) {
            _this.commentRounds = results;
            _this.commentRounds.sort(comparingPrimitive(function (commentRound) {
                return commentRound.label.toLowerCase();
            }));
        });
    };
    Object.defineProperty(FrontpageComponent.prototype, "loading", {
        get: function () {
            return this.commentRounds == null;
        },
        enumerable: true,
        configurable: true
    });
    FrontpageComponent.prototype.isLoggedIn = function () {
        return this.userService.isLoggedIn();
    };
    FrontpageComponent.prototype.canCreateCommentRound = function () {
        return this.authorizationManager.canCreateCommentRound();
    };
    FrontpageComponent.prototype.storeSourceAndNavigateToCreateCommentRound = function (integrationResource) {
        this.integrationResourceService.addIntegrationResource(integrationResource);
        this.router.navigate([
            'createround',
            {
                integrationResourceUri: integrationResource.uri
            }
        ]);
    };
    FrontpageComponent.prototype.createNewCommentRound = function () {
        var _this = this;
        this.searchLinkedContainerModalService
            .open(null, [], true)
            .then(function (source) { return _this.storeSourceAndNavigateToCreateCommentRound(source); }, ignoreModalClose);
    };
    FrontpageComponent.prototype.ngOnDestroy = function () {
        this.subscriptionToClean.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(FrontpageComponent.prototype, "searchTerm", {
        get: function () {
            return this.searchTerm$.getValue();
        },
        set: function (value) {
            this.searchTerm$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    return FrontpageComponent;
}());
export { FrontpageComponent };
