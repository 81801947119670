import { FormControl, NgControl } from '@angular/forms';
import { EditableService } from '../../services/editable.service';
var CommentRoundStatusInputComponent = /** @class */ (function () {
    function CommentRoundStatusInputComponent(parentControl, editableService) {
        var _this = this;
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.restrict = false;
        this.required = false;
        this.control = new FormControl();
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
    }
    Object.defineProperty(CommentRoundStatusInputComponent.prototype, "status", {
        get: function () {
            return this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundStatusInputComponent.prototype, "show", {
        get: function () {
            return this.editing || this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundStatusInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundStatusInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    CommentRoundStatusInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    CommentRoundStatusInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return CommentRoundStatusInputComponent;
}());
export { CommentRoundStatusInputComponent };
