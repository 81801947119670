import { EditableService } from '../../services/editable.service';
import { FormControl, NgControl } from '@angular/forms';
var BooleanInputComponent = /** @class */ (function () {
    function BooleanInputComponent(parentControl, editableService) {
        var _this = this;
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.restrict = false;
        this.required = false;
        this.control = new FormControl();
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
    }
    Object.defineProperty(BooleanInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing && !this.restrict;
        },
        enumerable: true,
        configurable: true
    });
    BooleanInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    BooleanInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    BooleanInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return BooleanInputComponent;
}());
export { BooleanInputComponent };
