<div *ngIf="loading">
  <ngb-tabset #tabSet="ngbTabset"></ngb-tabset>
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">
  <div class="main">
    <div>
      <h1 class="text-content-wrap">{{ commentRound.label }}</h1>
    </div>

    <div class="float-right">
      <div *ngIf="showMenu" ngbDropdown class="d-inline-block float-right ml-3" placement="bottom-right">
        <button class="btn btn-action" id="commentRoundDropdown" ngbDropdownToggle translate>Choose action</button>
        <div ngbDropdownMenu aria-labelledby="commentRoundDropdown">
          <button id="add_subscription_button"
                  *ngIf="canAddSubscription"
                  type="button"
                  class="dropdown-item"
                  (click)="addSubscription()"
                  translate>Add email subscription</button>
          <button id="delete_subscription_button"
                  *ngIf="canRemoveSubscription"
                  type="button"
                  class="dropdown-item"
                  (click)="removeSubscription()"
                  translate>Remove email subscription</button>
          <button *ngIf="canStartCommentRound"
                  id="start_commentround_button"
                  type="button"
                  class="dropdown-item"
                  (click)="startCommentRound()">
            <span translate>Start comment round</span>
          </button>

          <button *ngIf="canEndCommenting"
                  id="end_commentround_button"
                  type="button"
                  class="dropdown-item"
                  (click)="closeCommentRound()">
            <span translate>Close comment round</span>
          </button>

          <button *ngIf="canDeleteCommentRound"
                  id="delete_commentround_button"
                  type="button"
                  class="dropdown-item"
                  (click)="deleteCommentRound()">
            <span translate>Delete comment round</span>
          </button>
        </div>
      </div>

      <div *ngIf="showStartCommenting" class="ml-3 float-right d-inline-block">
        <button id="start_commenting_button"
                type="button"
                class="btn btn-action"
                (click)="goToOwnComments()">
          <span translate>Start commenting</span>
        </button>
      </div>

      <div *ngIf="showExcelExport" class="d-inline-block float-right ml-3">
        <a id="commentround_export_excel_link"
           [href]="exportUrl"
           target="_blank"
           rel="noopener noreferrer">
          <button id="export_commentround_button"
                  type="button"
                  class="btn btn-action">
            <span translate>Export Excel</span>
          </button>
        </a>
      </div>

      <div *ngIf="hasSubscription" class="subscription">
        <span class="subscription-icon icon-bell"
              ngbTooltip="{{ 'You will receive an email notification when this resource changes.' | translate }}"></span>
      </div>
    </div>
  </div>

  <div class="row metabar">
    <div class="col-12">
      <div class="metastatus">
        <app-commentround-status [status]="commentRound.status"></app-commentround-status>
      </div>
      <div class="metaitem">
        <span translate>Tool</span><span>: </span>
        <span>{{toolType | translate}}</span>
      </div>
      <div class="metaitem">
        <span translate>Resource</span><span>: </span>
        <a class="routedLink" href="{{configurationService.getUriWithEnv(commentRound.source.containerUri)}}" target="_blank" rel="noopener noreferrer"><span
          class="routedLink">{{commentRound.getDisplayName(languageService, true)}}</span></a>
      </div>
    </div>
  </div>

  <div class="row content-description-container" *ngIf="commentRound.description">
    <div class="col-12">
      <app-expandable-text [text]="commentRound.description"></app-expandable-text>
    </div>
  </div>

  <ngb-tabset #tabSet="ngbTabset" [activeId]="getInitialTabId()" (tabChange)="onTabChange($event)">
    <ngb-tab id="commentround_information_tab">
      <ng-template ngbTabTitle>
        <span translate>INFORMATION</span>
      </ng-template>

      <ng-template ngbTabContent>
        <app-comment-round-information [commentRound]="commentRound"
                                       [commentThreads]="commentThreads"
                                       (changeTabControl)="changeTabControl($event)"
                                       (refreshCommentRound)="refreshCommentRound()">
        </app-comment-round-information>
      </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="isEditorOrSuperUser || (myComments && myComments.length > 0)"
             id="commentround_resources_tab">
      <ng-template ngbTabTitle>
        <span translate>RESOURCES</span>
      </ng-template>

      <ng-template ngbTabContent>
        <app-comment-round-comment-threads [commentRound]="commentRound"
                                           [commentThreads]="commentThreads"
                                           [myComments]="myComments"
                                           [activeThreadSequenceId]="this.activeThreadId"
                                           [tabSet]="this.tabSet"
                                           (changeTabControl)="changeTabControl($event)"
                                           (refreshCommentThreads)="refreshCommentThreads()">
        </app-comment-round-comment-threads>
      </ng-template>
    </ngb-tab>

    <ngb-tab *ngIf="(isEditorOrSuperUser && this.commentRound.commentThreads && this.commentRound.commentThreads.length > 0) || (authorizationManager.canCreateComment(commentRound) && this.commentRound.commentThreads && this.commentRound.commentThreads.length > 0 && this.commentRound.status === 'INPROGRESS')"
             id="commentround_comments_tab">
      <ng-template ngbTabTitle>
        <span translate>OWN COMMENTS</span>
      </ng-template>

      <ng-template ngbTabContent>
        <app-comment-round-comments [commentRound]="commentRound"
                                    [commentThreads]="commentThreads"
                                    [myComments]="myComments"
                                    (changeTabControl)="changeTabControl($event)"
                                    (refreshMyComments)="refreshMyComments()"
                                    (refreshCommentThreads)="refreshCommentThreads()"
                                    [tabSet]="this.tabSet">
        </app-comment-round-comments>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
