import { EditableService } from '../../services/editable.service';
import { FormControl, NgControl } from '@angular/forms';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { comparingLocalizable } from 'yti-common-ui/utils/comparator';
import { DataService } from '../../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { SearchLinkedOrganizationModalService } from './search-linked-organization-modal.component';
import { LanguageService } from '../../services/language.service';
function addToControl(control, itemToAdd) {
    var previous = control.value;
    control.setValue(previous.concat([itemToAdd]));
}
function removeFromControl(control, itemToRemove) {
    var previous = control.value;
    control.setValue(previous.filter(function (item) { return item !== itemToRemove; }));
}
var OrganizationsInputComponent = /** @class */ (function () {
    function OrganizationsInputComponent(parentControl, editableService, translateService, dataService, searchLinkedOrganizationModalService, languageService) {
        var _this = this;
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.translateService = translateService;
        this.dataService = dataService;
        this.searchLinkedOrganizationModalService = searchLinkedOrganizationModalService;
        this.languageService = languageService;
        this.restrict = false;
        this.required = false;
        this.control = new FormControl([]);
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
        this.organizations$ = this.dataService.getOrganizations();
    }
    Object.defineProperty(OrganizationsInputComponent.prototype, "selectableOrganizations", {
        get: function () {
            return this.control.value
                .sort(comparingLocalizable(this.languageService, function (organization) { return organization.prefLabel ? organization.prefLabel : {}; }));
        },
        enumerable: true,
        configurable: true
    });
    OrganizationsInputComponent.prototype.addOrganization = function () {
        var _this = this;
        var titleLabel = this.translateService.instant('Select organization');
        var searchlabel = this.translateService.instant('Search organization');
        var restrictIds = this.selectableOrganizations.map(function (organization) { return organization.id; });
        this.searchLinkedOrganizationModalService.open(this.organizations, titleLabel, searchlabel, restrictIds, true)
            .then(function (organization) { return addToControl(_this.control, organization); }, ignoreModalClose);
    };
    OrganizationsInputComponent.prototype.removeOrganization = function (organization) {
        removeFromControl(this.control, organization);
    };
    Object.defineProperty(OrganizationsInputComponent.prototype, "organizations", {
        get: function () {
            return this.organizations$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrganizationsInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing && !this.restrict;
        },
        enumerable: true,
        configurable: true
    });
    OrganizationsInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    OrganizationsInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    OrganizationsInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return OrganizationsInputComponent;
}());
export { OrganizationsInputComponent };
