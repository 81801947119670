
    <div class="modal-header">
      <h4 class="modal-title">
        <a><i id="close_modal_link" class="fa fa-times" (click)="cancel()"></i></a>
        <span>{{titleLabel}}</span>
      </h4>
    </div>
    <div class="modal-body">
      <form [formGroup]="tempUsersForm" #form="ngForm">
        <div *ngIf="tempUserForms.controls.length > 0" formArrayName="tempUsers">
          <div class="row">
            <dt class="col-md-3">
              <label translate>First name</label>
              <app-information-symbol [infoText]="'INFO_TEXT_USER_FIRSTNAME'"></app-information-symbol>
              <app-required-symbol></app-required-symbol>
            </dt>
            <dt class="col-md-4">
              <label translate>Last name</label>
              <app-information-symbol [infoText]="'INFO_TEXT_USER_LASTNAME'"></app-information-symbol>
              <app-required-symbol></app-required-symbol>
            </dt>
            <dt class="col-md-4">
              <label translate>Email</label>
              <app-information-symbol [infoText]="'INFO_TEXT_USER_EMAIL'"></app-information-symbol>
              <app-required-symbol></app-required-symbol>
            </dt>
            <div class="col-md-1"></div>
          </div>
          <ng-container *ngFor="let userForm of tempUserForms.controls; let i = index" [formGroupName]="i">
            <div class="row">
              <app-literal-input class="col-md-3"
                                 [id]="'user_' + i + '_firstname_input'"
                                 [required]="true"
                                 [formControlName]="'firstName'"></app-literal-input>

              <app-literal-input class="col-md-4"
                                 [id]="'user_' + i + '_lastname_input'"
                                 [required]="true"
                                 [formControlName]="'lastName'"></app-literal-input>

              <app-literal-input class="col-md-4"
                                 [id]="'user_' + i + '_email_input'"
                                 [required]="true"
                                 [formControlName]="'email'"></app-literal-input>
              <div class="col-md-1">
                <button *ngIf="tempUserForms.controls.length > 1"
                        [id]="'remove_row_' + i + '_button'"
                        type="button"
                        class="icon icon-trash"
                        (click)="removeRow(i)">
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
      <button id="add_user_button"
              type="button"
              class="btn btn-secondary-action"
              (click)="addNewRowToUserForm()">
        <span translate>+ Add row</span>
      </button>
    </div>
    <div class="modal-footer">
      <button id="add_users_button"
              type="button"
              class="btn btn-action"
              [disabled]="!tempUsersForm.valid"
              (click)="addUsers()">
        <span translate>Add</span>
      </button>
      <button id="cancel_modal_button"
              type="button"
              class="btn btn-link cancel"
              (click)="cancel()">
        <span translate>Cancel</span>
      </button>
    </div>
  