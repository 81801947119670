import { EditableService } from '../../services/editable.service';
import { FormControl, NgControl } from '@angular/forms';
import { formatDisplayDateRange, validDate } from '../../utils/date';
import { combineLatest } from 'rxjs';
var DateRangeInputComponent = /** @class */ (function () {
    function DateRangeInputComponent(parentControl, editableService) {
        var _this = this;
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.restrict = false;
        this.required = false;
        this.showLabel = true;
        this.startControl = new FormControl(null, validDate);
        this.endControl = new FormControl(null, validDate);
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        combineLatest(this.startControl.valueChanges, this.endControl.valueChanges)
            .subscribe(function () { return _this.propagateChange(_this.value); });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
    }
    Object.defineProperty(DateRangeInputComponent.prototype, "show", {
        get: function () {
            return this.editing || this.displayName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing && !this.restrict;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "invalid", {
        get: function () {
            return this.invalidStart || this.invalidEnd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "invalidStart", {
        get: function () {
            return this.startControl.value === undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "invalidEnd", {
        get: function () {
            return this.endControl.value === undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "displayName", {
        get: function () {
            return this.value ? formatDisplayDateRange(this.value.start, this.value.end) : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "value", {
        get: function () {
            if (this.invalid) {
                return undefined;
            }
            return {
                start: this.startControl.value,
                end: this.endControl.value
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "startDateInfoText", {
        get: function () {
            return this.infoText ? this.infoText + '_START_DATE' : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeInputComponent.prototype, "endDateInfoText", {
        get: function () {
            return this.infoText ? this.infoText + '_END_DATE' : '';
        },
        enumerable: true,
        configurable: true
    });
    DateRangeInputComponent.prototype.writeValue = function (obj) {
        this.startControl.setValue(obj ? obj.start : null);
        this.endControl.setValue(obj ? obj.end : null);
    };
    DateRangeInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    DateRangeInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return DateRangeInputComponent;
}());
export { DateRangeInputComponent };
