var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { formatDate, formatDateTime, formatDisplayDateTime, parseDate } from '../utils/date';
import { utc } from 'moment';
import { AbstractResource } from './abstract-resource';
import { Source } from './source';
import { OrganizationSimple } from './organization-simple';
import { User } from './user';
import { CommentThreadSimple } from './commentthread-simple';
import { TempUser } from './tempuser';
var CommentRound = /** @class */ (function (_super) {
    __extends(CommentRound, _super);
    function CommentRound(data) {
        var _this = _super.call(this, data) || this;
        _this.sourceLabel = {};
        _this.created = null;
        _this.modified = null;
        _this.startDate = null;
        _this.endDate = null;
        _this.organizations = [];
        _this.tempUsers = [];
        _this.commentThreads = [];
        if (data.user) {
            _this.user = new User(data.user);
        }
        _this.label = data.label;
        _this.description = data.description;
        _this.status = data.status;
        _this.fixedThreads = data.fixedThreads;
        _this.openThreads = data.openThreads;
        if (data.sourceLocalName) {
            _this.sourceLocalName = data.sourceLocalName;
        }
        _this.sourceLabel = data.sourceLabel || {};
        if (data.source) {
            _this.source = new Source(data.source);
        }
        if (data.created) {
            _this.created = utc(data.created);
        }
        if (data.modified) {
            _this.modified = utc(data.modified);
        }
        if (data.startDate) {
            _this.startDate = parseDate(data.startDate);
        }
        if (data.endDate) {
            _this.endDate = parseDate(data.endDate);
        }
        if (data.organizations) {
            _this.organizations = (data.organizations || []).map(function (org) { return new OrganizationSimple(org); });
        }
        if (data.tempUsers) {
            _this.tempUsers = (data.tempUsers || []).map(function (tempUser) { return new TempUser(tempUser); });
        }
        if (data.commentThreads) {
            _this.commentThreads = (data.commentThreads || []).map(function (commentThread) { return new CommentThreadSimple(commentThread); });
        }
        return _this;
    }
    Object.defineProperty(CommentRound.prototype, "createdDisplayValue", {
        get: function () {
            return formatDisplayDateTime(this.created);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRound.prototype, "modifiedDisplayValue", {
        get: function () {
            return formatDisplayDateTime(this.modified);
        },
        enumerable: true,
        configurable: true
    });
    CommentRound.prototype.getDisplayName = function (localizer, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        if (!localizer.isLocalizableEmpty(this.sourceLabel)) {
            return localizer.translate(this.sourceLabel, useUILanguage);
        }
        else if (this.sourceLocalName) {
            return this.sourceLocalName;
        }
        return '';
    };
    Object.defineProperty(CommentRound.prototype, "location", {
        get: function () {
            return [
                {
                    localizationKey: 'Comment round',
                    label: undefined,
                    value: undefined,
                    route: this.route
                }
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRound.prototype, "route", {
        get: function () {
            return [
                'round',
                {
                    round: this.sequenceId
                }
            ];
        },
        enumerable: true,
        configurable: true
    });
    CommentRound.prototype.serialize = function () {
        return {
            id: this.id,
            url: this.url,
            uri: this.uri,
            sequenceId: this.sequenceId,
            user: this.user ? this.user.serialize() : undefined,
            status: this.status,
            created: formatDateTime(this.created),
            modified: formatDateTime(this.modified),
            startDate: formatDate(this.startDate),
            endDate: formatDate(this.endDate),
            label: this.label,
            description: this.description,
            sourceLocalName: this.sourceLocalName,
            sourceLabel: __assign({}, this.sourceLabel),
            fixedThreads: this.fixedThreads,
            openThreads: this.openThreads,
            source: this.source.serialize(),
            organizations: this.organizations.map(function (org) { return org.serialize(); }),
            tempUsers: (this.tempUsers || []).map(function (tempUser) { return tempUser.serialize(); }),
            commentThreads: this.commentThreads ? this.commentThreads.map(function (commentThread) { return commentThread.serialize(); }) : null
        };
    };
    CommentRound.prototype.clone = function () {
        return new CommentRound(this.serialize());
    };
    CommentRound.prototype.getUser = function () {
        return this.user;
    };
    CommentRound.prototype.allowUserEdit = function () {
        return true;
    };
    CommentRound.prototype.allowOrganizationEdit = function () {
        return this.status === 'INPROGRESS' && !this.fixedThreads;
    };
    CommentRound.prototype.allowOrganizationComment = function () {
        return this.status === 'INPROGRESS';
    };
    CommentRound.prototype.getOwningOrganizationIds = function () {
        return this.organizations.map(function (org) { return org.id; });
    };
    CommentRound.prototype.getContainerUri = function () {
        return this.uri;
    };
    return CommentRound;
}(AbstractResource));
export { CommentRound };
