var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbstractResource } from './abstract-resource';
import { CommentRound } from './commentround';
import { formatDateTime } from '../utils/date';
import { utc } from 'moment';
import { User } from './user';
import { CommentThreadResult } from './commentthreadresult';
var CommentThread = /** @class */ (function (_super) {
    __extends(CommentThread, _super);
    function CommentThread(data) {
        var _this = _super.call(this, data) || this;
        _this.label = {};
        _this.description = {};
        _this.created = null;
        _this.resourceUri = data.resourceUri;
        _this.label = data.label || {};
        _this.description = data.description || {};
        if (data.localName) {
            _this.localName = data.localName;
        }
        _this.proposedText = data.proposedText;
        _this.currentStatus = data.currentStatus;
        _this.proposedStatus = data.proposedStatus;
        _this.commentCount = data.commentCount;
        if (data.user) {
            _this.user = new User(data.user);
        }
        if (data.created) {
            _this.created = utc(data.created);
        }
        if (data.results) {
            _this.results = (data.results || []).map(function (result) { return new CommentThreadResult(result); });
        }
        _this.commentRound = new CommentRound(data.commentRound);
        return _this;
    }
    Object.defineProperty(CommentThread.prototype, "route", {
        get: function () {
            return [
                'round',
                {
                    round: this.commentRound.sequenceId,
                    thread: this.sequenceId
                }
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentThread.prototype, "location", {
        get: function () {
            return this.commentRound.location.concat([
                {
                    localizationKey: 'Comment thread',
                    label: undefined,
                    value: undefined,
                    route: this.route
                }
            ]);
        },
        enumerable: true,
        configurable: true
    });
    CommentThread.prototype.serialize = function () {
        return {
            id: this.id,
            url: this.url,
            uri: this.uri,
            sequenceId: this.sequenceId,
            user: this.user ? this.user.serialize() : undefined,
            resourceUri: this.resourceUri,
            description: this.description,
            localName: this.localName,
            created: formatDateTime(this.created),
            label: this.label,
            proposedText: this.proposedText,
            currentStatus: this.currentStatus,
            proposedStatus: this.proposedStatus,
            commentRound: this.commentRound.serialize(),
            results: this.results.map(function (result) { return result.serialize(); }),
            commentCount: this.commentCount
        };
    };
    CommentThread.prototype.clone = function () {
        return new CommentThread(this.serialize());
    };
    return CommentThread;
}(AbstractResource));
export { CommentThread };
