
    <dl>
      <dt *ngIf="label">
        <label>{{ label }}</label>
        <app-information-symbol [infoText]="infoText"></app-information-symbol>
        <app-required-symbol *ngIf="showRequired && required && editing"></app-required-symbol>
      </dt>
      <dd>
        <div *ngIf="editing" class="form-group">
          <textarea [id]="id"
                    autosize
                    class="form-control"
                    [ngClass]="{ 'is-invalid': !valid }"
                    [ngModel]="value[contentLanguage]"
                    (ngModelChange)="onChange($event)"></textarea>
          <app-error-messages [id]="id + '_error_messages'" [control]="parentControl"></app-error-messages>
        </div>
        <app-literal-multilanguage *ngIf="!editing && value[contentLanguage] && value[contentLanguage].length > 0"
                                   [value]="value"></app-literal-multilanguage>
        <span *ngIf="!editing && (!value[contentLanguage] || value[contentLanguage].length === 0)">-</span>
      </dd>
    </dl>
  