/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/yti-common-ui/components/information-symbol.component.ngfactory";
import * as i2 from "yti-common-ui/components/information-symbol.component";
import * as i3 from "./literal";
var styles_LiteralComponent = [];
var RenderType_LiteralComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LiteralComponent, data: {} });
export { RenderType_LiteralComponent as RenderType_LiteralComponent };
export function View_LiteralComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "dl", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-information-symbol", [], null, null, null, i1.View_InformationSymbolComponent_0, i1.RenderType_InformationSymbolComponent)), i0.ɵdid(5, 49152, null, 0, i2.InformationSymbolComponent, [], { infoText: [0, "infoText"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.infoText; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); var currVal_2 = (_co.value ? _co.value : "-"); _ck(_v, 7, 0, currVal_2); }); }
export function View_LiteralComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-literal", [], null, null, null, View_LiteralComponent_0, RenderType_LiteralComponent)), i0.ɵdid(1, 49152, null, 0, i3.LiteralComponent, [], null, null)], null, null); }
var LiteralComponentNgFactory = i0.ɵccf("app-literal", i3.LiteralComponent, View_LiteralComponent_Host_0, { label: "label", value: "value", infoText: "infoText" }, {}, []);
export { LiteralComponentNgFactory as LiteralComponentNgFactory };
