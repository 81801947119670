import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { EditableService } from '../../services/editable.service';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from '../../services/language.service';
import { ConfigurationService } from '../../services/configuration.service';
import { AuthorizationManager } from '../../services/authorization-manager';
import { DataService } from '../../services/data.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommentRound } from '../../entities/commentround';
import { formatDisplayDateTime } from '../../utils/date';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { CommentsConfirmationModalService } from '../common/confirmation-modal.service';
import { CommentsErrorModalService } from '../common/error-modal.service';
import { comparingLocalizable, comparingPrimitive } from 'yti-common-ui/utils/comparator';
import { hasLocalization } from 'yti-common-ui/utils/localization';
import { TranslateService } from '@ngx-translate/core';
var CommentRoundCommentsComponent = /** @class */ (function () {
    function CommentRoundCommentsComponent(configurationService, languageService, translateService, authorizationManager, dataService, editableService, confirmationModalService, errorModalService) {
        var _this = this;
        this.configurationService = configurationService;
        this.languageService = languageService;
        this.translateService = translateService;
        this.authorizationManager = authorizationManager;
        this.dataService = dataService;
        this.editableService = editableService;
        this.confirmationModalService = confirmationModalService;
        this.errorModalService = errorModalService;
        this.changeTabControl = new EventEmitter();
        this.refreshCommentThreads = new EventEmitter();
        this.refreshMyComments = new EventEmitter();
        this.commenting$ = new BehaviorSubject(false);
        this.sortOption = 'alphabetical';
        this.commentThreadForm = new FormGroup({
            commentThreads: new FormArray([])
        }, null);
        this.cancelSubscription = editableService.cancel$.subscribe(function () { return _this.reset(); });
    }
    CommentRoundCommentsComponent.prototype.ngOnInit = function () {
        this.resolveCommentedThreadIds();
        this.reset();
    };
    CommentRoundCommentsComponent.prototype.resolveCommentedThreadIds = function () {
        var _this = this;
        this.commentedThreadIds = [];
        this.myComments.forEach(function (comment) {
            if (comment.commentThread.id !== null) {
                _this.commentedThreadIds.push(comment.commentThread.id);
            }
        });
    };
    CommentRoundCommentsComponent.prototype.ngOnChanges = function (changes) {
        var commentRoundChange = changes['commentRound'];
        if (commentRoundChange && !commentRoundChange.isFirstChange()) {
        }
        var commentThreadsChange = changes['commentThreads'];
        if (commentThreadsChange && !commentThreadsChange.isFirstChange()) {
            this.reset();
        }
        var myCommentsChange = changes['myComments'];
        if (myCommentsChange && !myCommentsChange.isFirstChange()) {
            this.reset();
        }
    };
    CommentRoundCommentsComponent.prototype.ngOnDestroy = function () {
        this.cancelSubscription.unsubscribe();
    };
    CommentRoundCommentsComponent.prototype.reset = function () {
        var _this = this;
        if (this.loading) {
            return;
        }
        this.commenting$.next(false);
        this.changeTabControl.emit(false);
        this.commentThreadForms.controls = [];
        this.commentThreads.sort(comparingPrimitive(function (commentThread) { return _this.languageService.isLocalizableEmpty(commentThread.label); })
            .andThen(comparingPrimitive(function (commentThread) {
            return _this.languageService.isLocalizableEmpty(commentThread.label) ? commentThread.resourceUri.toLowerCase() : null;
        }))
            .andThen(comparingPrimitive(function (commentThread) {
            return commentThread.localName ? commentThread.localName : null;
        }))
            .andThen(comparingLocalizable(this.languageService, function (commentThread) { return commentThread.label ? commentThread.label : {}; }, true)));
        this.commentThreads.forEach(function (commentThread) {
            var commentThreadFormGroup = new FormGroup({
                id: new FormControl(commentThread.id),
                url: new FormControl(commentThread.url),
                resourceUri: new FormControl(commentThread.resourceUri),
                label: new FormControl(commentThread.label, Validators.required),
                description: new FormControl(commentThread.description, Validators.required),
                localName: new FormControl(commentThread.localName),
                created: new FormControl(commentThread.created),
                user: new FormControl(commentThread.user),
                currentStatus: new FormControl(commentThread.currentStatus),
                proposedStatus: new FormControl(commentThread.proposedStatus),
                proposedText: new FormControl(commentThread.proposedText),
                commentersProposedStatus: new FormControl(_this.getMyProposedStatusForCommentThread(commentThread)),
                commentersProposedEndStatus: new FormControl(_this.getMyProposedEndStatusForCommentThread(commentThread)),
                commentersProposedText: new FormControl(_this.getMyCommentContentForCommentThread(commentThread.id)),
                results: new FormControl(commentThread.results),
                commentCount: new FormControl(commentThread.commentCount)
            });
            _this.commentThreadForms.push(commentThreadFormGroup);
        });
    };
    Object.defineProperty(CommentRoundCommentsComponent.prototype, "loading", {
        get: function () {
            return this.commentRound == null || this.commentThreads == null || this.myComments == null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundCommentsComponent.prototype, "commentThreadForms", {
        get: function () {
            return this.commentThreadForm.get('commentThreads');
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentsComponent.prototype.getMyCommentContentForCommentThread = function (commentThreadId) {
        var content = '';
        if (this.myComments) {
            this.myComments.forEach(function (comment) {
                if (comment.commentThread.id === commentThreadId) {
                    content = comment.content;
                }
            });
        }
        return content;
    };
    CommentRoundCommentsComponent.prototype.getMyProposedStatusForCommentThread = function (commentThread) {
        var proposedStatus = commentThread.proposedStatus;
        if (this.myComments) {
            this.myComments.forEach(function (comment) {
                if (comment.commentThread.id === commentThread.id && comment.proposedStatus != null) {
                    proposedStatus = comment.proposedStatus;
                }
            });
        }
        return proposedStatus;
    };
    CommentRoundCommentsComponent.prototype.getMyProposedEndStatusForCommentThread = function (commentThread) {
        var proposedEndStatus = 'NOSTATUS';
        if (this.myComments) {
            this.myComments.forEach(function (comment) {
                if (comment.commentThread.id === commentThread.id && comment.endStatus != null) {
                    proposedEndStatus = comment.endStatus;
                }
            });
        }
        return proposedEndStatus;
    };
    Object.defineProperty(CommentRoundCommentsComponent.prototype, "hasCommentThreads", {
        get: function () {
            return this.commentThreads && this.commentThreads.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentsComponent.prototype.formatDisplayDate = function (created) {
        return formatDisplayDateTime(created);
    };
    Object.defineProperty(CommentRoundCommentsComponent.prototype, "canSendComments", {
        get: function () {
            return this.commenting &&
                this.authorizationManager.canCreateComment(this.commentRound) &&
                this.commentRound.status === 'INPROGRESS';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundCommentsComponent.prototype, "commentsHaveContent", {
        get: function () {
            var hasContent = false;
            this.commentThreadForms.controls.forEach(function (commentThread) {
                if (commentThread.value.commentersProposedText != null && commentThread.value.commentersProposedText.trim().length > 0) {
                    hasContent = true;
                }
            });
            return hasContent;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentsComponent.prototype.sendMyComments = function () {
        var _this = this;
        if (this.hasPartialComments) {
            this.confirmationModalService.sendPartialComments()
                .then(function () {
                _this.sendComments();
            }, function () {
            });
        }
        else {
            this.sendComments();
        }
    };
    CommentRoundCommentsComponent.prototype.sendComments = function () {
        var _this = this;
        var comments = [];
        this.commentThreadForms.controls.forEach(function (commentThreadInput) {
            var commentThreadInputValue = commentThreadInput.value;
            var commentType = {
                commentThread: { id: commentThreadInputValue.id },
                proposedStatus: commentThreadInputValue.commentersProposedStatus,
                endStatus: commentThreadInputValue.commentersProposedEndStatus,
                content: commentThreadInputValue.commentersProposedText
            };
            if (commentThreadInputValue.commentersProposedText && commentThreadInputValue.commentersProposedText.trim().length > 0) {
                comments.push(commentType);
            }
        });
        this.dataService.createCommentsToCommentRound(this.commentRound.id, comments).subscribe(function () {
            _this.refreshCommentThreads.emit();
            _this.refreshMyComments.emit();
            _this.cancelCommenting();
        }, function (error) {
            _this.cancelCommenting();
            _this.reset();
            _this.errorModalService.openSubmitError(error);
        });
    };
    Object.defineProperty(CommentRoundCommentsComponent.prototype, "commenting", {
        get: function () {
            return this.commenting$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentsComponent.prototype.startCommenting = function () {
        this.changeTabControl.emit(true);
        this.editableService.edit();
        this.commenting$.next(true);
    };
    CommentRoundCommentsComponent.prototype.cancelCommenting = function () {
        this.reset();
        this.editableService.cancel();
        this.commenting$.next(false);
        this.changeTabControl.emit(false);
    };
    Object.defineProperty(CommentRoundCommentsComponent.prototype, "canStartCommenting", {
        get: function () {
            return !this.commenting &&
                this.authorizationManager.canCreateComment(this.commentRound) &&
                this.commentRound.status === 'INPROGRESS';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundCommentsComponent.prototype, "showCancelCommenting", {
        get: function () {
            return this.commenting &&
                this.authorizationManager.canCreateComment(this.commentRound) &&
                this.commentRound.status === 'INPROGRESS';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundCommentsComponent.prototype, "hasPartialComments", {
        get: function () {
            var partialComments = false;
            this.commentThreadForms.controls.forEach(function (commentThread) {
                if (commentThread.value.commentersProposedText == null || commentThread.value.commentersProposedText.trim() === '') {
                    partialComments = true;
                }
            });
            return partialComments;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentsComponent.prototype.canModifyCommentProposedStatus = function () {
        return this.authorizationManager.canCreateComment(this.commentRound) && this.commentRound.status === 'INPROGRESS';
    };
    CommentRoundCommentsComponent.prototype.canModifyComment = function (commentThreadId) {
        if (this.commentedThreadIds != null) {
            for (var _i = 0, _a = this.commentedThreadIds; _i < _a.length; _i++) {
                var threadId = _a[_i];
                if (threadId === commentThreadId) {
                    return false;
                }
            }
        }
        return true;
    };
    CommentRoundCommentsComponent.prototype.sortContent = function (sortingType) {
        var _this = this;
        this.sortOption = sortingType;
        switch (sortingType) {
            case 'alphabetical':
                this.commentThreadForms.controls.sort(comparingPrimitive(function (commentThread) { return _this.languageService.isLocalizableEmpty(commentThread.value.label); })
                    .andThen(comparingPrimitive(function (commentThread) {
                    return _this.languageService.isLocalizableEmpty(commentThread.value.label) ? (commentThread.value.url ? commentThread.value.url.toLowerCase() : null) : null;
                }))
                    .andThen(comparingLocalizable(this.languageService, function (commentThread) { return commentThread.value.label ? commentThread.value.label : {}; })));
                break;
            case 'created':
                this.commentThreadForms.controls.sort(comparingPrimitive(function (commentThread) { return commentThread.value.created; }));
                break;
            default:
                break;
        }
    };
    CommentRoundCommentsComponent.prototype.hasLocalization = function (localizable) {
        return hasLocalization(localizable);
    };
    CommentRoundCommentsComponent.prototype.getCommentThreadResourceUri = function (commentThread) {
        return this.configurationService.getUriWithEnv(commentThread.resourceUri);
    };
    CommentRoundCommentsComponent.prototype.getCommentThreadUserDisplayName = function (user) {
        if (user) {
            var userDisplayName = user.getDisplayName();
            if (userDisplayName.length > 0) {
                return userDisplayName;
            }
            else {
                return this.translateService.instant('Removed user');
            }
        }
        else {
            return '-';
        }
    };
    return CommentRoundCommentsComponent;
}());
export { CommentRoundCommentsComponent };
