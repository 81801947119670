import { Router } from '@angular/router';
import { LanguageService } from '../../services/language.service';
import { CommentRound } from '../../entities/commentround';
import { formatDisplayDateRange } from '../../utils/date';
import { TranslateService } from '@ngx-translate/core';
var CommentRoundListitemComponent = /** @class */ (function () {
    function CommentRoundListitemComponent(router, languageService, translateService) {
        this.router = router;
        this.languageService = languageService;
        this.translateService = translateService;
    }
    CommentRoundListitemComponent.prototype.getIdIdentifier = function () {
        return "" + this.commentRound.id;
    };
    Object.defineProperty(CommentRoundListitemComponent.prototype, "commentingPeriod", {
        get: function () {
            return formatDisplayDateRange(this.commentRound.startDate, this.commentRound.endDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundListitemComponent.prototype, "typeIcon", {
        get: function () {
            var type = this.commentRound.source.containerType;
            switch (type) {
                case 'codelist':
                    return 'account_balance';
                case 'terminology':
                    return 'chat_bubble';
                case 'datamodel':
                    return 'view_quilt';
                default:
                    return 'account_balance';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundListitemComponent.prototype, "containerTypeLabel", {
        get: function () {
            var type = this.commentRound.source.containerType;
            return this.translateService.instant(type + '-type');
        },
        enumerable: true,
        configurable: true
    });
    return CommentRoundListitemComponent;
}());
export { CommentRoundListitemComponent };
