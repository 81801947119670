/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../../../node_modules/yti-common-ui/components/ajax-loading-indicator-small.component.ngfactory";
import * as i3 from "yti-common-ui/components/ajax-loading-indicator-small.component";
import * as i4 from "@angular/common";
import * as i5 from "./editable-buttons.component";
import * as i6 from "../../services/editable.service";
import * as i7 from "../../services/authorization-manager";
var styles_EditableButtonsComponent = [];
var RenderType_EditableButtonsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditableButtonsComponent, data: {} });
export { RenderType_EditableButtonsComponent as RenderType_EditableButtonsComponent };
function View_EditableButtonsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-action float-right ml-3"], ["id", "editable_edit_button"], ["type", "button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Edit"]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editing; _ck(_v, 0, 0, currVal_0); }); }
function View_EditableButtonsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ajax-loading-indicator-small", [["class", "ml-3 float-right"]], null, null, null, i2.View_AjaxLoadingIndicatorSmallComponent_0, i2.RenderType_AjaxLoadingIndicatorSmallComponent)), i0.ɵdid(1, 49152, null, 0, i3.AjaxLoadingIndicatorSmallComponent, [], null, null)], null, null); }
export function View_EditableButtonsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-action float-right ml-3"], ["id", "editable_save_button"], ["type", "button"]], [[8, "hidden", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Save"])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "button", [["class", "btn btn-link float-right"], ["id", "editable_cancel_button"], ["type", "button"]], [[8, "hidden", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(6, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Cancel"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableButtonsComponent_1)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditableButtonsComponent_2)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); var currVal_5 = ""; _ck(_v, 6, 0, currVal_5); var currVal_6 = _co.canEdit(); _ck(_v, 9, 0, currVal_6); var currVal_7 = _co.operationPending; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editing; var currVal_1 = ((!_co.canSave() || _co.operationPending) || _co.invalid); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = !_co.editing; var currVal_4 = _co.operationPending; _ck(_v, 4, 0, currVal_3, currVal_4); }); }
export function View_EditableButtonsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-editable-buttons", [], null, null, null, View_EditableButtonsComponent_0, RenderType_EditableButtonsComponent)), i0.ɵdid(1, 49152, null, 0, i5.EditableButtonsComponent, [i6.EditableService, i7.AuthorizationManager], null, null)], null, null); }
var EditableButtonsComponentNgFactory = i0.ɵccf("app-editable-buttons", i5.EditableButtonsComponent, View_EditableButtonsComponent_Host_0, { form: "form", entity: "entity" }, {}, []);
export { EditableButtonsComponentNgFactory as EditableButtonsComponentNgFactory };
