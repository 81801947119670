import { EditableService } from '../../services/editable.service';
import { FormControl, NgControl } from '@angular/forms';
import { LanguageService } from '../../services/language.service';
import { CommentRound } from '../../entities/commentround';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { TranslateService } from '@ngx-translate/core';
import { AddTempUsersModalService } from './add-temp-users-modal.component';
import { ConfirmationModalService } from 'yti-common-ui/components/confirmation-modal.component';
import { comparingPrimitive } from 'yti-common-ui/utils/comparator';
function addToControl(control, itemToAdd) {
    var previous = control.value;
    control.setValue(previous.concat([itemToAdd]));
}
function removeFromControl(control, itemToRemove) {
    var previous = control.value;
    control.setValue(previous.filter(function (item) { return item !== itemToRemove; }));
}
var TempUserInputComponent = /** @class */ (function () {
    function TempUserInputComponent(parentControl, editableService, translateService, addTempUsersModalService, confirmationModalService, languageService) {
        var _this = this;
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.translateService = translateService;
        this.addTempUsersModalService = addTempUsersModalService;
        this.confirmationModalService = confirmationModalService;
        this.languageService = languageService;
        this.restrict = false;
        this.required = false;
        this.control = new FormControl([]);
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
    }
    Object.defineProperty(TempUserInputComponent.prototype, "tempUsers", {
        get: function () {
            return this.control.value
                .sort(comparingPrimitive(function (tempUser) { return tempUser.lastName; }));
        },
        enumerable: true,
        configurable: true
    });
    TempUserInputComponent.prototype.addTempUser = function () {
        var _this = this;
        var titleLabel = this.translateService.instant('Add users to comment round');
        var addLabel = this.translateService.instant('Add user');
        var restrictedEmails = this.tempUsers ? this.tempUsers.map(function (tempUser) { return tempUser.email; }) : [];
        this.addTempUsersModalService.open(titleLabel, addLabel, restrictedEmails, true)
            .then(function (tempUsers) { return tempUsers.forEach(function (tempUser) { return addToControl(_this.control, tempUser); }); }, ignoreModalClose);
    };
    TempUserInputComponent.prototype.removeTempUser = function (tempUser) {
        var _this = this;
        this.confirmationModalService.open('REMOVE USER FROM COMMENT ROUND?')
            .then(function () {
            removeFromControl(_this.control, tempUser);
        }, ignoreModalClose);
    };
    Object.defineProperty(TempUserInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing && !this.restrict;
        },
        enumerable: true,
        configurable: true
    });
    TempUserInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    TempUserInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    TempUserInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return TempUserInputComponent;
}());
export { TempUserInputComponent };
