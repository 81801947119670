import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { comparingPrimitive } from 'yti-common-ui/utils/comparator';
var LiteralMultilanguageComponent = /** @class */ (function () {
    function LiteralMultilanguageComponent(translateService, languageService) {
        this.translateService = translateService;
        this.languageService = languageService;
    }
    Object.defineProperty(LiteralMultilanguageComponent.prototype, "valueLanguages", {
        get: function () {
            var languages = Object.keys(this.value);
            languages.sort(comparingPrimitive(function (language) { return language; }));
            var sortedLanguages = [];
            if (languages.includes('fi')) {
                sortedLanguages.push('fi');
            }
            if (languages.includes('sv')) {
                sortedLanguages.push('sv');
            }
            if (languages.includes('en')) {
                sortedLanguages.push('en');
            }
            languages.forEach(function (key) {
                if (key !== 'fi' && key !== 'sv' && key !== 'en') {
                    sortedLanguages.push(key);
                }
            });
            return sortedLanguages;
        },
        enumerable: true,
        configurable: true
    });
    LiteralMultilanguageComponent.prototype.showLanguageIdentifier = function (language) {
        return language.toUpperCase() !== 'UND';
    };
    return LiteralMultilanguageComponent;
}());
export { LiteralMultilanguageComponent };
