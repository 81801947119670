var CommentRoundStatusComponent = /** @class */ (function () {
    function CommentRoundStatusComponent() {
    }
    Object.defineProperty(CommentRoundStatusComponent.prototype, "incomplete", {
        get: function () {
            return this.status === 'INCOMPLETE';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundStatusComponent.prototype, "awaitOrIncomplete", {
        get: function () {
            return this.status === 'AWAIT' || this.status === 'INCOMPLETE';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundStatusComponent.prototype, "danger", {
        get: function () {
            return this.status === 'ENDED';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundStatusComponent.prototype, "warning", {
        get: function () {
            return this.status === 'CLOSED';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundStatusComponent.prototype, "success", {
        get: function () {
            return this.status === 'INPROGRESS';
        },
        enumerable: true,
        configurable: true
    });
    return CommentRoundStatusComponent;
}());
export { CommentRoundStatusComponent };
