import { FormControl } from '@angular/forms';
var ErrorMessagesComponent = /** @class */ (function () {
    function ErrorMessagesComponent() {
    }
    ErrorMessagesComponent.prototype.isVisible = function () {
        return this.control && !this.control.valid;
    };
    Object.defineProperty(ErrorMessagesComponent.prototype, "errorKeys", {
        get: function () {
            return this.control.errors ? Object.keys(this.control.errors) : [];
        },
        enumerable: true,
        configurable: true
    });
    return ErrorMessagesComponent;
}());
export { ErrorMessagesComponent };
