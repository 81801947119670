/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/yti-common-ui/components/required-symbol.component.ngfactory";
import * as i2 from "yti-common-ui/components/required-symbol.component";
import * as i3 from "../../../../node_modules/yti-common-ui/components/information-symbol.component.ngfactory";
import * as i4 from "yti-common-ui/components/information-symbol.component";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "ngx-autosize";
import * as i8 from "./error-messages.component.ngfactory";
import * as i9 from "./error-messages.component";
import * as i10 from "./literal-multilanguage.ngfactory";
import * as i11 from "./literal-multilanguage";
import * as i12 from "@ngx-translate/core";
import * as i13 from "../../services/language.service";
import * as i14 from "./literal-und-textarea";
import * as i15 from "../../services/editable.service";
var styles_LocalizableUndefinedTextareaComponent = [];
var RenderType_LocalizableUndefinedTextareaComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LocalizableUndefinedTextareaComponent, data: {} });
export { RenderType_LocalizableUndefinedTextareaComponent as RenderType_LocalizableUndefinedTextareaComponent };
function View_LocalizableUndefinedTextareaComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-required-symbol", [], null, null, null, i1.View_RequiredSymbolComponent_0, i1.RenderType_RequiredSymbolComponent)), i0.ɵdid(1, 49152, null, 0, i2.RequiredSymbolComponent, [], null, null)], null, null); }
function View_LocalizableUndefinedTextareaComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-information-symbol", [], null, null, null, i3.View_InformationSymbolComponent_0, i3.RenderType_InformationSymbolComponent)), i0.ɵdid(4, 49152, null, 0, i4.InformationSymbolComponent, [], { infoText: [0, "infoText"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LocalizableUndefinedTextareaComponent_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.infoText; _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.showRequired && _co.required) && _co.editing); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); }); }
function View_LocalizableUndefinedTextareaComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "textarea", [["autosize", ""], ["class", "form-control"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i0.ɵnov(_v, 9).onInput($event.target) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = (_co.onChange($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "is-invalid": 0 }), i0.ɵdid(4, 16384, null, 0, i6.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i6.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.DefaultValueAccessor]), i0.ɵdid(6, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i0.ɵdid(8, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i0.ɵdid(9, 2113536, null, 0, i7.AutosizeDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "app-error-messages", [], null, null, null, i8.View_ErrorMessagesComponent_0, i8.RenderType_ErrorMessagesComponent)), i0.ɵdid(11, 49152, null, 0, i9.ErrorMessagesComponent, [], { control: [0, "control"], id: [1, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "form-control"; var currVal_9 = _ck(_v, 3, 0, !_co.valid); _ck(_v, 2, 0, currVal_8, currVal_9); var currVal_10 = _co.value[_co.contentLanguage]; _ck(_v, 6, 0, currVal_10); var currVal_11 = _co.parentControl; var currVal_12 = (_co.id + "_error_messages"); _ck(_v, 11, 0, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_LocalizableUndefinedTextareaComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-literal-multilanguage", [], null, null, null, i10.View_LiteralMultilanguageComponent_0, i10.RenderType_LiteralMultilanguageComponent)), i0.ɵdid(1, 49152, null, 0, i11.LiteralMultilanguageComponent, [i12.TranslateService, i13.LanguageService], { value: [0, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LocalizableUndefinedTextareaComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["-"]))], null, null); }
export function View_LocalizableUndefinedTextareaComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "dl", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LocalizableUndefinedTextareaComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LocalizableUndefinedTextareaComponent_3)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LocalizableUndefinedTextareaComponent_4)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LocalizableUndefinedTextareaComponent_5)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.editing; _ck(_v, 5, 0, currVal_1); var currVal_2 = ((!_co.editing && _co.value[_co.contentLanguage]) && (_co.value[_co.contentLanguage].length > 0)); _ck(_v, 7, 0, currVal_2); var currVal_3 = (!_co.editing && (!_co.value[_co.contentLanguage] || (_co.value[_co.contentLanguage].length === 0))); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_LocalizableUndefinedTextareaComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-localizable-undefined-textarea", [], null, null, null, View_LocalizableUndefinedTextareaComponent_0, RenderType_LocalizableUndefinedTextareaComponent)), i0.ɵdid(1, 49152, null, 0, i14.LocalizableUndefinedTextareaComponent, [[8, null], i15.EditableService], null, null)], null, null); }
var LocalizableUndefinedTextareaComponentNgFactory = i0.ɵccf("app-localizable-undefined-textarea", i14.LocalizableUndefinedTextareaComponent, View_LocalizableUndefinedTextareaComponent_Host_0, { label: "label", restrict: "restrict", required: "required", showRequired: "showRequired", id: "id", infoText: "infoText" }, {}, []);
export { LocalizableUndefinedTextareaComponentNgFactory as LocalizableUndefinedTextareaComponentNgFactory };
