import { HttpClient, HttpParams } from '@angular/common/http';
import { Comment } from '../entities/comment';
import { CommentRound } from '../entities/commentround';
import { map } from 'rxjs/internal/operators';
import { CommentThread } from '../entities/commentthread';
import { IntegrationResource } from '../entities/integration-resource';
import { CommentThreadSimple } from '../entities/commentthread-simple';
import { CommentSimple } from '../entities/comment-simple';
import { IntegrationRequest } from '../entities/integration-request';
import { AuthorizationManager } from './authorization-manager';
import { OrganizationSimple } from '../entities/organization-simple';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authorization-manager";
var apiContext = 'comments-api';
var api = 'api';
var version = 'v1';
var system = 'system';
var commentRounds = 'commentrounds';
var commentThreads = 'commentthreads';
var config = 'config';
var comments = 'comments';
var organizations = 'organizations';
var containers = 'containers';
var resources = 'resources';
var fakeableUsers = 'fakeableUsers';
var groupmanagement = 'groupmanagement';
var request = 'request';
var requests = 'requests';
var codelist = 'codelist';
var terminology = 'terminology';
var datamodel = 'datamodel';
var baseApiPath = "/" + apiContext + "/" + api + "/" + version;
var commentRoundsApiPath = baseApiPath + "/" + commentRounds;
var fakeableUsersPath = "/" + apiContext + "/" + api + "/" + fakeableUsers;
var configurationPath = baseApiPath + "/" + system + "/" + config;
var organizationsBasePath = baseApiPath + "/" + organizations;
var codelistBasePath = baseApiPath + "/" + codelist;
var terminologyBasePath = baseApiPath + "/" + terminology;
var datamodelBasePath = baseApiPath + "/" + datamodel;
var groupManagementRequestBasePath = baseApiPath + "/" + groupmanagement + "/" + request;
var groupManagementRequestsBasePath = baseApiPath + "/" + groupmanagement + "/" + requests;
var DataService = /** @class */ (function () {
    function DataService(http, authorizationManager) {
        this.http = http;
        this.authorizationManager = authorizationManager;
    }
    DataService.resolveIntegrationApiPathForContainerType = function (containerType) {
        var containerPath;
        switch (containerType) {
            case codelist:
                containerPath = codelistBasePath;
                break;
            case terminology:
                containerPath = terminologyBasePath;
                break;
            case datamodel:
                containerPath = datamodelBasePath;
                break;
            default:
                // TODO: Produce error in case container type is not known!
                containerPath = codelistBasePath;
                break;
        }
        return containerPath;
    };
    DataService.prototype.getFakeableUsers = function () {
        return this.http.get(fakeableUsersPath);
    };
    DataService.prototype.getServiceConfiguration = function () {
        return this.http.get("" + configurationPath);
    };
    DataService.prototype.getCommentRounds = function (organizationId, status, containerType, searchTerm, filterIncomplete, filterContent) {
        var params = new HttpParams()
            .set('expand', 'source,organization');
        if (containerType) {
            params = params.append('containerType', containerType);
        }
        if (searchTerm) {
            params = params.append('searchTerm', searchTerm);
        }
        if (organizationId) {
            params = params.append('organizationId', organizationId);
        }
        if (status) {
            params = params.append('status', status);
        }
        else {
            params = params.append('status', 'INCOMPLETE,AWAIT,INPROGRESS');
        }
        if (filterIncomplete) {
            params = params.append('filterIncomplete', String(filterIncomplete));
        }
        if (filterContent) {
            params = params.append('filterContent', String(filterContent));
        }
        return this.http.get(commentRoundsApiPath, { params: params, responseType: 'json' })
            .pipe(map(function (res) {
            return res.results.map(function (data) { return new CommentRound(data); });
        }));
    };
    DataService.prototype.getCommentRound = function (commentRoundId) {
        var params = new HttpParams()
            .set('expand', 'source,organization,commentThread');
        return this.http.get(commentRoundsApiPath + '/' + commentRoundId, { params: params, responseType: 'json' })
            .pipe(map(function (res) {
            return new CommentRound(res);
        }));
    };
    DataService.prototype.getCommentRoundCommenterComments = function (commentRoundId) {
        var params = new HttpParams()
            .set('expand', 'source,organization,comment,commentThread');
        return this.http.get(commentRoundsApiPath + '/' + commentRoundId + '/' + 'mycomments', { params: params, responseType: 'json' })
            .pipe(map(function (res) {
            return res.results.map(function (data) { return new Comment(data); });
        }));
    };
    DataService.prototype.getCommentRoundCommentThread = function (commentRoundId, commentThreadId) {
        var params = new HttpParams()
            .set('expand', 'comment,commentRound,organization,source');
        return this.http.get(commentRoundsApiPath + '/' + commentRoundId + '/' + commentThreads + '/' + commentThreadId, { params: params, responseType: 'json' })
            .pipe(map(function (res) {
            return new CommentThread(res);
        }));
    };
    DataService.prototype.getCommentRoundCommentThreads = function (commentRoundId) {
        var params = new HttpParams()
            .set('expand', 'comment');
        return this.http.get(commentRoundsApiPath + '/' + commentRoundId + '/' + commentThreads, { params: params, responseType: 'json' })
            .pipe(map(function (res) {
            return res.results.map(function (data) { return new CommentThreadSimple(data); });
        }));
    };
    DataService.prototype.getCommentRoundCommentThreadComments = function (commentRoundSequenceId, commentThreadSequenceId) {
        return this.http.get(commentRoundsApiPath + '/' +
            commentRoundSequenceId + '/' +
            commentThreads + '/' +
            commentThreadSequenceId + '/' +
            comments, { responseType: 'json' })
            .pipe(map(function (res) {
            return res.results.map(function (data) { return new CommentSimple(data); });
        }));
    };
    DataService.prototype.createCommentRound = function (commentRoundToCreate) {
        return this.createCommentRounds([commentRoundToCreate]).pipe(map(function (createdCommentRounds) {
            if (createdCommentRounds.length !== 1) {
                throw new Error('Exactly one comment round needs to be created');
            }
            else {
                return createdCommentRounds[0];
            }
        }));
    };
    DataService.prototype.deleteCommentRound = function (commentRound) {
        var commentRoundId = commentRound.id;
        return this.http.delete(commentRoundsApiPath + "/" + commentRoundId);
    };
    DataService.prototype.createCommentRounds = function (commentRoundList) {
        var params = new HttpParams()
            .set('expand', 'source,organization,commentThread');
        return this.http.post(commentRoundsApiPath + "/", commentRoundList, { params: params })
            .pipe(map(function (res) { return res.results.map(function (data) { return new CommentRound(data); }); }));
    };
    DataService.prototype.updateCommentRound = function (commentRoundToUpdate) {
        var params = new HttpParams()
            .set('expand', 'source,organization,commentThread');
        var commentRoundId = commentRoundToUpdate.id;
        return this.http.post(commentRoundsApiPath + "/" + commentRoundId + "/", commentRoundToUpdate, { params: params });
    };
    DataService.prototype.createCommentThread = function (commentThreadToCreate) {
        var commentRoundId = commentThreadToCreate.commentRound.id;
        return this.createCommentThreads(commentRoundId, [commentThreadToCreate], true).pipe(map(function (createdCommentThreads) {
            if (createdCommentThreads.length !== 1) {
                throw new Error('Exactly one comment thread needs to be created');
            }
            else {
                return createdCommentThreads[0];
            }
        }));
    };
    DataService.prototype.createCommentThreads = function (commentRoundId, commentThreadList, removeOrphans) {
        var params = new HttpParams()
            .set('removeOrphans', removeOrphans ? 'true' : 'false')
            .set('expand', 'comment');
        return this.http.post(commentRoundsApiPath + "/" + commentRoundId + "/" + commentThreads + "/", commentThreadList, { params: params })
            .pipe(map(function (res) { return res.results.map(function (data) { return new CommentThread(data); }); }));
    };
    DataService.prototype.updateCommentThread = function (commentThreadToUpdate) {
        var commentRoundId = commentThreadToUpdate.commentRound.id;
        var commentThreadId = commentThreadToUpdate.id;
        var params = new HttpParams()
            .set('expand', 'comment');
        return this.http.post(commentRoundsApiPath + "/" + commentRoundId + "/" + commentThreads + "/" + commentThreadId + "/", commentThreadToUpdate, { params: params });
    };
    DataService.prototype.createComment = function (commentRoundId, commentToCreate) {
        var commentThreadId = commentToCreate.commentThread.id;
        return this.createCommentToCommentThread(commentRoundId, commentThreadId, [commentToCreate]).pipe(map(function (createdComments) {
            if (createdComments.length !== 1) {
                throw new Error('Exactly one comment needs to be created');
            }
            else {
                return createdComments[0];
            }
        }));
    };
    DataService.prototype.createCommentToCommentThread = function (commentRoundId, commentThreadId, commentsList) {
        var params = new HttpParams()
            .set('expand', 'commentThread,commentRound');
        return this.http.post(commentRoundsApiPath + "/" + commentRoundId + "/" + commentThreads + "/" + commentThreadId + "/" + comments, commentsList, { params: params })
            .pipe(map(function (res) { return res.results.map(function (data) { return new Comment(data); }); }));
    };
    DataService.prototype.createCommentsToCommentRound = function (commentRoundId, commentsList) {
        var params = new HttpParams()
            .set('expand', 'commentThread,commentRound');
        return this.http.post(commentRoundsApiPath + "/" + commentRoundId + "/" + comments, commentsList, { params: params })
            .pipe(map(function (res) { return res.results.map(function (data) { return new CommentSimple(data); }); }));
    };
    DataService.prototype.updateComment = function (commentRoundId, commentToUpdate) {
        var commentThreadId = commentToUpdate.commentThread.id;
        var commentId = commentToUpdate.id;
        var params = new HttpParams()
            .set('expand', 'commentThread,commentRound');
        return this.http.post(commentRoundsApiPath + "/" + commentRoundId + "/" + commentThreads + "/" + commentThreadId + "/" + comments + "/" + commentId + "/", commentToUpdate, { params: params });
    };
    DataService.prototype.deleteComment = function (commentRoundId, commentToDelete) {
        var commentThreadId = commentToDelete.commentThread.id;
        var commentId = commentToDelete.id;
        return this.http.delete(commentRoundsApiPath + "/" + commentRoundId + "/" + commentThreads + "/" + commentThreadId + "/" + comments + "/" + commentId + "/delete");
    };
    DataService.prototype.getOrganizations = function () {
        return this.http.get(organizationsBasePath)
            .pipe(map(function (res) { return res.results.map(function (data) { return new OrganizationSimple(data); }); }));
    };
    DataService.prototype.getOrganizationsWithCommentRounds = function () {
        var params = new HttpParams()
            .set('expand', 'commentRound')
            .set('hasCommentRounds', 'true');
        return this.http.get(organizationsBasePath, { params: params })
            .pipe(map(function (res) { return res.results.map(function (data) { return new OrganizationSimple(data); }); }));
    };
    DataService.prototype.getContainers = function (containerType, language) {
        var integrationRequest = new IntegrationRequest();
        if (language) {
            integrationRequest.language = language;
        }
        if (this.authorizationManager.user.superuser) {
            integrationRequest.includeIncomplete = true;
        }
        else {
            var userOrganizations = Array.from(this.authorizationManager.user
                .getOrganizations(['ADMIN', 'CODE_LIST_EDITOR', 'TERMINOLOGY_EDITOR', 'DATA_MODEL_EDITOR', 'MEMBER']));
            if (userOrganizations && userOrganizations.length > 0) {
                integrationRequest.includeIncompleteFrom = userOrganizations;
            }
        }
        var containerPath = DataService.resolveIntegrationApiPathForContainerType(containerType) + '/' + containers;
        return this.http.post(containerPath, integrationRequest, { responseType: 'json' })
            .pipe(map(function (res) { return res.results.map(function (data) { return new IntegrationResource(data); }); }));
    };
    DataService.prototype.getResourcesPaged = function (containerType, uri, language, pageSize, from, status, searchTerm, restrictedResourceUris) {
        var integrationRequest = new IntegrationRequest();
        if (uri) {
            integrationRequest.container = [];
            integrationRequest.container.push(uri);
        }
        integrationRequest.pageSize = pageSize;
        integrationRequest.pageFrom = from;
        integrationRequest.language = language;
        integrationRequest.includeIncomplete = true;
        if (containerType === 'codelist') {
            integrationRequest.type = 'code';
        }
        if (searchTerm) {
            integrationRequest.searchTerm = searchTerm;
        }
        if (status) {
            integrationRequest.status = [];
            integrationRequest.status.push(status);
        }
        if (restrictedResourceUris && restrictedResourceUris.length > 0) {
            integrationRequest.filter = restrictedResourceUris;
        }
        var resourcePath = DataService.resolveIntegrationApiPathForContainerType(containerType) + '/' + resources;
        var fetchResult = this.http.post(resourcePath, integrationRequest, { responseType: 'json' })
            .pipe(map(function (res) { return res.results.map(function (data) { return new IntegrationResource(data); }); }));
        return fetchResult.toPromise();
    };
    DataService.prototype.getUserRequests = function () {
        return this.http.get(groupManagementRequestsBasePath + "/")
            .pipe(map(function (response) { return response.results; }));
    };
    DataService.prototype.sendUserRequest = function (organizationId) {
        return this.http.post(groupManagementRequestBasePath + "/?organizationId=" + organizationId, null);
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(i0.inject(i1.HttpClient), i0.inject(i2.AuthorizationManager)); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
