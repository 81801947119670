import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { formatDate, validDateRange } from '../../utils/date';
import { requiredList } from 'yti-common-ui/utils/validator';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { EditableService } from '../../services/editable.service';
import { LanguageService } from '../../services/language.service';
import { LocationService } from '../../services/location.service';
import { tap } from 'rxjs/operators';
import { IntegrationResourceService } from '../../services/integrationresource.service';
import { ConfigurationService } from '../../services/configuration.service';
var CommentRoundCreateComponent = /** @class */ (function () {
    function CommentRoundCreateComponent(router, route, dataService, integrationResourceService, editableService, activatedRoute, location, languageService, locationService, configurationService) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.dataService = dataService;
        this.integrationResourceService = integrationResourceService;
        this.editableService = editableService;
        this.activatedRoute = activatedRoute;
        this.location = location;
        this.languageService = languageService;
        this.locationService = locationService;
        this.configurationService = configurationService;
        this.commentRoundForm = new FormGroup({
            label: new FormControl(''),
            description: new FormControl(''),
            fixedThreads: new FormControl(true),
            openThreads: new FormControl(false),
            validity: new FormControl({ start: null, end: null }, validDateRange),
            status: new FormControl('INCOMPLETE'),
            organizations: new FormControl([], [requiredList]),
            resource: new FormControl(null)
        }, null);
        editableService.onSave = function (formValue) { return _this.save(formValue); };
        editableService.cancel$.subscribe(function () { return _this.back(); });
        this.editableService.edit();
    }
    CommentRoundCreateComponent.prototype.ngOnInit = function () {
        var integrationResourceUri = this.route.snapshot.params.integrationResourceUri;
        if (integrationResourceUri) {
            this.integrationResource = this.integrationResourceService.getIntegrationResource(integrationResourceUri);
            if (this.integrationResource) {
                this.commentRoundForm.patchValue({ resource: this.integrationResource });
            }
        }
        this.locationService.atCommentRoundCreatePage();
    };
    Object.defineProperty(CommentRoundCreateComponent.prototype, "toolType", {
        get: function () {
            var integrationResource = this.commentRoundForm.controls['resource'].value;
            if (integrationResource && integrationResource.type) {
                return integrationResource.type;
            }
            return '-';
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCreateComponent.prototype.back = function () {
        this.location.back();
    };
    CommentRoundCreateComponent.prototype.save = function (formData) {
        var _this = this;
        var label = formData.label, description = formData.description, fixedThreads = formData.fixedThreads, openThreads = formData.openThreads, validity = formData.validity, organizations = formData.organizations, resource = formData.resource, status = formData.status;
        var source = {
            'containerType': resource.type,
            'containerUri': resource.uri
        };
        var commentRound = {
            label: label,
            description: description,
            fixedThreads: fixedThreads,
            openThreads: openThreads,
            startDate: formatDate(validity.start),
            endDate: formatDate(validity.end),
            organizations: organizations.map(function (organization) { return organization.serialize(); }),
            source: source,
            sourceLocalName: resource.localName,
            sourceLabel: resource.prefLabel,
            status: status
        };
        var save = function () {
            return _this.dataService.createCommentRound(commentRound)
                .pipe(tap(function (createdCommentRound) {
                _this.router.navigate(createdCommentRound.route);
            }));
        };
        return save();
    };
    Object.defineProperty(CommentRoundCreateComponent.prototype, "getResourceUri", {
        get: function () {
            if (this.commentRoundForm.controls['resource'].value) {
                var uri = this.commentRoundForm.controls['resource'].value.uri;
                return this.configurationService.getUriWithEnv(uri);
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    return CommentRoundCreateComponent;
}());
export { CommentRoundCreateComponent };
