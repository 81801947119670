
    <dl>
      <dt>
        <div *ngIf="editing" class="checkbox">
          <input [id]="id"
                 type="checkbox"
                 [formControl]="control"/>
          <label for="id">{{ label }}</label>
          <app-information-symbol [infoText]="infoText"></app-information-symbol>
          <app-required-symbol *ngIf="required && editing"></app-required-symbol>
        </div>
        <div *ngIf="!editing" class="checkbox">
          <input [id]="id"
                 type="checkbox"
                 disabled
                 [checked]="control.value"/>
          <label [for]="id">{{ label }}</label>
          <app-information-symbol [infoText]="infoText"></app-information-symbol>
        </div>
      </dt>
    </dl>
  