
    <dl *ngIf="show">
      <dt>
        <label translate>Status</label>
        <app-information-symbol [infoText]="infoText"></app-information-symbol>
        <app-required-symbol *ngIf="required && editing"></app-required-symbol>
      </dt>
      <dd>
        <span *ngIf="!editing">{{status | translate}}</span>

        <app-comment-round-status-dropdown id="comment_round_status_input_dropdown"
                                           *ngIf="editing"
                                           [formControl]="control"
                                           [restrict]="restrict"></app-comment-round-status-dropdown>

        <app-error-messages id="status_input_error_messages" [control]="parentControl"></app-error-messages>
      </dd>
    </dl>
  