import { EditableService } from '../../services/editable.service';
import { FormControl, NgControl } from '@angular/forms';
var LiteralInputComponent = /** @class */ (function () {
    function LiteralInputComponent(parentControl, editableService) {
        var _this = this;
        this.parentControl = parentControl;
        this.editableService = editableService;
        this.restrict = false;
        this.required = false;
        this.isEditing = false;
        this.translate = false;
        this.control = new FormControl();
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
    }
    Object.defineProperty(LiteralInputComponent.prototype, "valid", {
        get: function () {
            return !this.parentControl || this.parentControl.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiteralInputComponent.prototype, "pending", {
        get: function () {
            return !this.parentControl || this.parentControl.pending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiteralInputComponent.prototype, "show", {
        get: function () {
            return this.editing || this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiteralInputComponent.prototype, "editing", {
        get: function () {
            return (this.editableService.editing || this.isEditing) && !this.restrict;
        },
        enumerable: true,
        configurable: true
    });
    LiteralInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    LiteralInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    LiteralInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return LiteralInputComponent;
}());
export { LiteralInputComponent };
