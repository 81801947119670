
    <div class="row">
      <div>
        <i id="hierarchy_code_expand" [hidden]="!hasChildComments || expanded" class="collapseIcon fa fa-plus" (click)="expand()"></i>
        <i id="hierarchy_code_collapse" [hidden]="!hasChildComments || collapsed" class="collapseIcon fa fa-minus" (click)="collapse()"></i>
        <i id="hierarchy_code_aligner" [hidden]="hasChildComments" class="collapseIcon fa"></i>
      </div>

      <div class="comment" x-ms-format-detection="none">
        <span class="name">{{ this.getCommentUserDisplayName() }}</span>
        <span *ngIf="comment.created && comment.modified && isSameMoment(comment.created, comment.modified)"
              class="created">{{ comment.createdDisplayValue}}</span>
        <span *ngIf="comment.created && comment.modified && !isSameMoment(comment.created, comment.modified)"
              class="modified">{{ comment.modifiedDisplayValue}}</span>
        <span *ngIf="comment.created && comment.modified && !isSameMoment(comment.created, comment.modified)">&nbsp;</span>
        <span *ngIf="comment.created && comment.modified && !isSameMoment(comment.created, comment.modified)" translate>(modified)</span>
        <span *ngIf="comment.proposedStatus != null && comment.proposedStatus === comment.endStatus"
              class="proposedStatus">, {{ comment.endStatus | translate }}</span>
        <span *ngIf="comment.proposedStatus !== comment.endStatus"
              class="proposedStatus">, <s>{{ comment.proposedStatus | translate }}</s> &#x2192; {{ comment.endStatus | translate }}</span>
        <span class="actions"
              *ngIf="!this.commenting && !this.updating && canComment"
              [id]="'comment_' + this.comment.id + '_reply_button'"
              (click)="toggleCommenting()"
              translate>Reply</span>
        <span class="actions"
              *ngIf="!this.commenting && !this.updating && canModifyOrDeleteComment && !hasChildComments && comment.parentComment"
              [id]="'comment_' + this.comment.id + '_modify_button'"
              (click)="toggleUpdatingComment()"
              translate>Modify</span>
        <span class="actions"
              *ngIf="!this.commenting && !this.updating && canModifyOrDeleteComment && !hasChildComments && comment.parentComment"
              [id]="'comment_' + this.comment.id + '_delete_button'"
              (click)="deleteComment()"
              translate>Delete</span>
        <br>
        <span class="content">{{ comment.content }}</span>
      </div>
    </div>

    <div class="row">
      <app-literal-input *ngIf="this.commenting"
                         class="input col-md-6"
                         [isEditing]="this.commenting"
                         [id]="'comment_' + this.comment.id + '_input'"
                         [(ngModel)]="this.commentContent"></app-literal-input>
      <div class="col-md-6">
        <button *ngIf="this.commenting && canComment"
                [id]="'comment_' + this.comment.id + '_send_button'"
                class="btn btn-secondary-action"
                [disabled]="commentContent.trim().length == 0"
                type="button"
                (click)="sendComment()">
          <span translate>Send</span>
        </button>
        <button *ngIf="this.commenting"
                [id]="'comment_' + this.comment.id + '_cancel_button'"
                class="btn btn-link cancel"
                type="button"
                (click)="toggleCommenting()">
          <span translate>Cancel</span>
        </button>
      </div>
    </div>

    <div class="row">
      <app-literal-input *ngIf="this.updating"
                         class="input col-md-6"
                         [isEditing]="updating"
                         [id]="'comment_' + this.comment.id + '_input'"
                         [(ngModel)]="comment.content"></app-literal-input>
      <div class="col-md-6">
        <button *ngIf="this.updating && canComment"
                [id]="'comment_' + this.comment.id + '_send_button'"
                class="btn btn-secondary-action"
                [disabled]="comment.content.trim().length == 0"
                type="button"
                (click)="updateComment()">
          <span translate>Save</span>
        </button>
        <button *ngIf="this.updating"
                [id]="'comment_' + this.comment.id + '_cancel_button'"
                class="btn btn-link cancel"
                type="button"
                (click)="toggleUpdatingComment()">
          <span translate>Cancel</span>
        </button>
      </div>
    </div>

    <ul *ngIf="expanded && hasChildComments">
      <li class="child-comment" *ngFor="let childComment of childComments; trackBy: commentIdentity">
        <app-hierarchical-comment (refreshComments)="emitRefreshComments()"
                                  (expandComment)="emitExpandComment($event)"
                                  (collapseComment)="emitCollapseComment($event)"
                                  [id]="childComment.id"
                                  [comment]="childComment"
                                  [comments]="comments"
                                  [commentRound]="commentRound"
                                  [commentThreadId]="commentThreadId"
                                  [commentThreadSequenceId]="commentThreadSequenceId"
                                  [activeCommentId$]="activeCommentId$"
                                  [canComment]="canComment"
                                  [canModifyOrDeleteComment]="canModifyOrDeleteInlineComment(childComment)"></app-hierarchical-comment>
      </li>
    </ul>
  