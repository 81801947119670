import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import { EditableService } from '../../services/editable.service';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { LanguageService } from '../../services/language.service';
import { ConfigurationService } from '../../services/configuration.service';
import { AuthorizationManager } from '../../services/authorization-manager';
import { DataService } from '../../services/data.service';
import { BehaviorSubject } from 'rxjs';
import { formatDisplayDateTime } from '../../utils/date';
import { CommentRound } from '../../entities/commentround';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { CommentsConfirmationModalService } from '../common/confirmation-modal.service';
import { comparingLocalizable, comparingPrimitive } from 'yti-common-ui/utils/comparator';
import { CommentsErrorModalService } from '../common/error-modal.service';
import { hasLocalization } from 'yti-common-ui/utils/localization';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuid } from 'uuid';
import { CommentThreadSimple } from '../../entities/commentthread-simple';
import { tap } from 'rxjs/operators';
import { SearchLinkedIntegrationResourceMultiModalService } from '../form/search-linked-integration-resource-multi-modal.component';
import { nonEmptyLocalizableValidator } from '../../utils/validators';
import { TranslateService } from '@ngx-translate/core';
var CommentRoundCommentThreadsComponent = /** @class */ (function () {
    function CommentRoundCommentThreadsComponent(languageService, configurationService, translateService, authorizationManager, dataService, confirmationModalService, errorModalService, searchLinkedIntegrationResourceMultiModalService, editableService) {
        var _this = this;
        this.languageService = languageService;
        this.configurationService = configurationService;
        this.translateService = translateService;
        this.authorizationManager = authorizationManager;
        this.dataService = dataService;
        this.confirmationModalService = confirmationModalService;
        this.errorModalService = errorModalService;
        this.searchLinkedIntegrationResourceMultiModalService = searchLinkedIntegrationResourceMultiModalService;
        this.editableService = editableService;
        this.changeTabControl = new EventEmitter();
        this.refreshCommentThreads = new EventEmitter();
        this.newIds = [];
        this.showCommentsId = undefined;
        this.activeCommentId$ = new BehaviorSubject(null);
        this.sortOption = 'alphabetical';
        this.commentThreadForm = new FormGroup({
            commentThreads: new FormArray([])
        }, null);
        this.editSubscription = editableService.edit$.subscribe(function () { return _this.changeTabControl.emit(true); });
        this.cancelSubscription = editableService.cancel$.subscribe(function () {
            _this.reset();
            _this.changeTabControl.emit(false);
        });
        editableService.onSave = function () { return _this.save(); };
    }
    CommentRoundCommentThreadsComponent.prototype.ngOnInit = function () {
        this.refreshCommentThreads.emit();
        this.reset();
    };
    CommentRoundCommentThreadsComponent.prototype.ngOnChanges = function (changes) {
        var commentThreadsChange = changes['commentThreads'];
        if (commentThreadsChange && !commentThreadsChange.isFirstChange()) {
            this.reset();
        }
    };
    CommentRoundCommentThreadsComponent.prototype.ngOnDestroy = function () {
        this.cancelSubscription.unsubscribe();
    };
    CommentRoundCommentThreadsComponent.prototype.reset = function () {
        var _this = this;
        if (this.loading) {
            return;
        }
        this.newIds = [];
        this.commentThreadForms.controls = [];
        this.commentThreads.sort(comparingPrimitive(function (commentThread) { return _this.languageService.isLocalizableEmpty(commentThread.label); })
            .andThen(comparingPrimitive(function (commentThread) {
            return _this.languageService.isLocalizableEmpty(commentThread.label) ? commentThread.resourceUri.toLowerCase() : null;
        }))
            .andThen(comparingPrimitive(function (commentThread) {
            return commentThread.localName ? commentThread.localName : null;
        }))
            .andThen(comparingLocalizable(this.languageService, function (commentThread) { return commentThread.label ? commentThread.label : {}; }, true)));
        this.commentThreads.forEach(function (commentThread) {
            var commentThreadFormGroup = new FormGroup({
                id: new FormControl(commentThread.id),
                sequenceId: new FormControl(commentThread.sequenceId),
                url: new FormControl(commentThread.url),
                resourceUri: new FormControl(commentThread.resourceUri),
                label: new FormControl(commentThread.label),
                description: new FormControl(commentThread.description),
                localName: new FormControl(commentThread.localName),
                created: new FormControl(commentThread.created),
                user: new FormControl(commentThread.user),
                currentStatus: new FormControl(commentThread.currentStatus),
                proposedStatus: new FormControl(commentThread.proposedStatus),
                proposedText: new FormControl(commentThread.proposedText),
                commentersProposedStatus: new FormControl(_this.getMyProposedStatusForCommentThread(commentThread)),
                commentersProposedEndStatus: new FormControl(_this.getMyProposedEndStatusForCommentThread(commentThread)),
                commentersProposedText: new FormControl(_this.getMyCommentContentForCommentThread(commentThread.id)),
                results: new FormControl(commentThread.results),
                commentCount: new FormControl(commentThread.commentCount)
            });
            _this.commentThreadForms.push(commentThreadFormGroup);
        });
    };
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "loading", {
        get: function () {
            return this.commentRound == null || this.commentThreads == null || this.myComments == null;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentThreadsComponent.prototype.toggleShowThreadComments = function (commentThreadSequenceId, index) {
        if (index === this.showCommentsId) {
            this.showCommentsId = undefined;
            this.activeThreadSequenceId = undefined;
            this.activeThreadComments = [];
        }
        else {
            this.loadCommentThreadComments(commentThreadSequenceId, index);
        }
    };
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "showActions", {
        get: function () {
            return this.isEditing &&
                (this.commentRound.status === 'INCOMPLETE' || this.commentRound.status === 'INPROGRESS' || this.commentRound.status === 'AWAIT');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "showResults", {
        get: function () {
            return this.commentRound.status === 'ENDED' || this.commentRound.status === 'CLOSED';
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentThreadsComponent.prototype.isNewResource = function (id) {
        return this.newIds.indexOf(id) !== -1;
    };
    CommentRoundCommentThreadsComponent.prototype.enableShowComments = function (commentRoundSequenceId, commentThreadSequenceId, index) {
        var commentThreadSequenceIdNumber = commentThreadSequenceId;
        if (this.showCommentsId === index) {
            return true;
        }
        else if (this.activeThreadSequenceId !== undefined && this.activeThreadSequenceId.toString() === commentThreadSequenceId.toString()) {
            this.loadCommentThreadComments(commentThreadSequenceIdNumber, index);
            return undefined;
        }
        else {
            return false;
        }
    };
    CommentRoundCommentThreadsComponent.prototype.loadCommentThreadComments = function (commentRoundSequenceId, index) {
        var _this = this;
        this.dataService.getCommentRoundCommentThreadComments(this.commentRound.sequenceId, commentRoundSequenceId).subscribe(function (comments) {
            if (comments.length > 0) {
                _this.showCommentsId = index;
                _this.activeThreadSequenceId = commentRoundSequenceId;
                _this.sortCommentsByCreated(comments);
                _this.activeThreadComments = comments;
            }
        });
    };
    CommentRoundCommentThreadsComponent.prototype.removeCommentThread = function (i) {
        var _this = this;
        this.confirmationModalService.deleteCommentThread()
            .then(function () {
            _this.commentThreadForms.removeAt(i);
        }, ignoreModalClose);
    };
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "isEditorOrSuperUser", {
        get: function () {
            return this.authorizationManager.user.superuser || this.commentRound.user.id === this.authorizationManager.user.id;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentThreadsComponent.prototype.filterTopLevelComments = function (comments) {
        return comments.filter(function (comment) { return comment.parentComment == null; });
    };
    CommentRoundCommentThreadsComponent.prototype.refreshComments = function (commentThreadSequenceId) {
        var _this = this;
        this.dataService.getCommentRoundCommentThreadComments(this.commentRound.sequenceId, commentThreadSequenceId).subscribe(function (comments) {
            _this.updateCommentCountForCommentThread(commentThreadSequenceId, comments.length);
            _this.sortCommentsByCreated(comments);
            comments.forEach(function (comment) {
                _this.activeThreadComments.forEach(function (c) {
                    if (comment.id === c.id) {
                        comment.expanded = c.expanded;
                    }
                });
            });
            _this.activeThreadComments = comments;
        }, function (error) {
            _this.errorModalService.openSubmitError(error);
        });
    };
    CommentRoundCommentThreadsComponent.prototype.expandComment = function (commentId) {
        var _this = this;
        this.activeThreadComments.forEach(function (comment) {
            if (comment.id === commentId) {
                comment.expanded = true;
                _this.expandChildComments(comment.id);
            }
        });
    };
    CommentRoundCommentThreadsComponent.prototype.expandChildComments = function (parentCommentId) {
        var childComments = this.getRecursiveChildComments(parentCommentId);
        childComments.forEach(function (comment) {
            comment.expanded = true;
        });
    };
    CommentRoundCommentThreadsComponent.prototype.getRecursiveChildComments = function (parentCommentId) {
        var _this = this;
        var childComments = [];
        this.activeThreadComments.forEach(function (comment) {
            if (comment.parentComment && comment.parentComment.id === parentCommentId) {
                childComments.push(comment);
                var grandChildComments = _this.getRecursiveChildComments(comment.id);
                if (grandChildComments.length > 0) {
                    grandChildComments.forEach(function (grandChildComment) {
                        childComments.push(grandChildComment);
                    });
                }
            }
        });
        return childComments;
    };
    CommentRoundCommentThreadsComponent.prototype.collapseComment = function (commentId) {
        var _this = this;
        this.activeThreadComments.forEach(function (comment) {
            if (comment.id === commentId) {
                comment.expanded = false;
                var grandChildComments = _this.getRecursiveChildComments(comment.id);
                if (grandChildComments.length > 0) {
                    grandChildComments.forEach(function (grandChildComment) {
                        grandChildComment.expanded = true;
                    });
                }
            }
        });
    };
    CommentRoundCommentThreadsComponent.prototype.updateCommentCountForCommentThread = function (commentThreadSequenceId, count) {
        this.commentThreadForms.controls.forEach(function (commentThread) {
            if (commentThread.value.sequenceId === commentThreadSequenceId) {
                commentThread.value.commentCount = count;
            }
        });
    };
    CommentRoundCommentThreadsComponent.prototype.sortCommentsByCreated = function (comments) {
        comments.sort(comparingPrimitive(function (comment) { return comment.created ? comment.created.toString() : undefined; }));
    };
    CommentRoundCommentThreadsComponent.prototype.formatDisplayDate = function (created) {
        return formatDisplayDateTime(created);
    };
    CommentRoundCommentThreadsComponent.prototype.hasLocalization = function (localizable) {
        return hasLocalization(localizable);
    };
    CommentRoundCommentThreadsComponent.prototype.getCommentThreadResourceUri = function (commentThread) {
        return this.configurationService.getUriWithEnv(commentThread.resourceUri);
    };
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "canInlineComment", {
        get: function () {
            return this.authorizationManager.canCreateComment(this.commentRound) &&
                this.commentRound.status === 'INPROGRESS';
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentThreadsComponent.prototype.canModifyOrDeleteInlineComment = function (comment) {
        return (this.authorizationManager.user.id === comment.user.id) &&
            this.commentRound.status === 'INPROGRESS';
    };
    CommentRoundCommentThreadsComponent.prototype.sortContent = function (sortingType) {
        var _this = this;
        this.sortOption = sortingType;
        switch (sortingType) {
            case 'alphabetical':
                this.commentThreadForms.controls.sort(comparingPrimitive(function (commentThread) { return _this.languageService.isLocalizableEmpty(commentThread.value.label); })
                    .andThen(comparingPrimitive(function (commentThread) {
                    return _this.languageService.isLocalizableEmpty(commentThread.value.label) ? (commentThread.value.url ? commentThread.value.url.toLowerCase() : null) : null;
                }))
                    .andThen(comparingLocalizable(this.languageService, function (commentThread) { return commentThread.value.label ? commentThread.value.label : {}; })));
                break;
            case 'created':
                this.commentThreadForms.controls.sort(comparingPrimitive(function (commentThread) { return commentThread.value.created; }));
                break;
            default:
                break;
        }
    };
    CommentRoundCommentThreadsComponent.prototype.addCommentThreadToCommentRound = function () {
        var _this = this;
        this.searchLinkedIntegrationResourceMultiModalService
            .open(this.commentRound.source.containerType, this.commentRound.source.containerUri, this.commentRound.openThreads, this.restrictedThreads, true)
            .then(function (source) { return _this.createNewCommentThreadsWithSources(source); }, ignoreModalClose);
    };
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "canCreateCommentThread", {
        get: function () {
            if (this.commentRound.status === 'INCOMPLETE') {
                return this.isEditorOrSuperUser;
            }
            else if (this.commentRound.status === 'INPROGRESS' && !this.commentRound.fixedThreads) {
                return this.authorizationManager.canCreateCommentThread(this.commentRound);
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "showCreateThreadButton", {
        get: function () {
            return ((this.isEditing && this.isEditorOrSuperUser) ||
                (this.isEditing && !this.commentRound.fixedThreads && this.authorizationManager.canCreateCommentThread(this.commentRound)));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "showEditableButtons", {
        get: function () {
            return (this.isEditorOrSuperUser ||
                (!this.commentRound.fixedThreads && this.authorizationManager.canCreateCommentThread(this.commentRound)));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "isEditing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentThreadsComponent.prototype.createNewCommentThreadWithSource = function (integrationResource) {
        var id = uuid();
        this.newIds.push(id);
        var commentThreadFormGroup = new FormGroup({
            id: new FormControl(id),
            resourceUri: new FormControl(integrationResource.uri),
            resourceType: new FormControl(integrationResource.type),
            created: new FormControl(),
            user: new FormControl(),
            label: integrationResource.uri ? new FormControl(integrationResource.prefLabel) :
                new FormControl(integrationResource.prefLabel ? integrationResource.prefLabel : {}, nonEmptyLocalizableValidator),
            description: integrationResource.uri ? new FormControl(integrationResource.description) :
                new FormControl(integrationResource.description ? integrationResource.description : {}, nonEmptyLocalizableValidator),
            localName: new FormControl(integrationResource.localName),
            currentStatus: new FormControl(integrationResource.status),
            proposedStatus: new FormControl(integrationResource.uri != null ? integrationResource.status : 'SUGGESTED'),
            proposedText: new FormControl(''),
            commentersProposedStatus: new FormControl('NOSTATUS'),
            commentersProposedEndStatus: new FormControl('NOSTATUS'),
            commentersProposedText: new FormControl(''),
            results: new FormControl([])
        });
        this.commentThreadForms.push(commentThreadFormGroup);
    };
    CommentRoundCommentThreadsComponent.prototype.createNewCommentThreadsWithSources = function (integrationResources) {
        var _this = this;
        integrationResources.forEach(function (integrationResource) { return _this.createNewCommentThreadWithSource(integrationResource); });
    };
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "commentThreadForms", {
        get: function () {
            return this.commentThreadForm.get('commentThreads');
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentThreadsComponent.prototype.getMyCommentContentForCommentThread = function (commentThreadId) {
        var content = '';
        if (this.myComments) {
            this.myComments.forEach(function (comment) {
                if (comment.commentThread.id === commentThreadId) {
                    content = comment.content;
                }
            });
        }
        return content;
    };
    CommentRoundCommentThreadsComponent.prototype.getMyProposedStatusForCommentThread = function (commentThread) {
        var proposedStatus = commentThread.proposedStatus;
        if (this.myComments) {
            this.myComments.forEach(function (comment) {
                if (comment.commentThread.id === commentThread.id && comment.proposedStatus != null) {
                    proposedStatus = comment.proposedStatus;
                }
            });
        }
        return proposedStatus;
    };
    CommentRoundCommentThreadsComponent.prototype.getMyProposedEndStatusForCommentThread = function (commentThread) {
        var proposedEndStatus = 'NOSTATUS';
        if (this.myComments) {
            this.myComments.forEach(function (comment) {
                if (comment.commentThread.id === commentThread.id && comment.endStatus != null) {
                    proposedEndStatus = comment.endStatus;
                }
            });
        }
        return proposedEndStatus;
    };
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "restrictedThreads", {
        get: function () {
            var restrictedIds = [];
            var commentThreads = this.commentThreadForms;
            if (commentThreads) {
                commentThreads.controls.forEach(function (thread) {
                    var threadValue = thread.value;
                    if (threadValue.resourceUri) {
                        restrictedIds.push(threadValue.resourceUri);
                    }
                });
            }
            return restrictedIds;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentThreadsComponent.prototype.mapCommentThreads = function (commentThreadsFormArray) {
        var commentThreads = [];
        if (commentThreadsFormArray) {
            commentThreadsFormArray.controls.forEach(function (commentThreadInput) {
                var commentThreadInputValue = commentThreadInput.value;
                var commentThreadFromFormInput = {
                    id: commentThreadInputValue.id,
                    url: commentThreadInputValue.url,
                    resourceUri: commentThreadInputValue.resourceUri,
                    label: commentThreadInputValue.label,
                    description: commentThreadInputValue.description,
                    localName: commentThreadInputValue.localName,
                    created: commentThreadInputValue.created,
                    user: commentThreadInputValue.user,
                    currentStatus: commentThreadInputValue.currentStatus,
                    proposedStatus: commentThreadInputValue.proposedStatus,
                    proposedText: commentThreadInputValue.proposedText,
                    commentersProposedStatus: commentThreadInputValue.commentersProposedStatus,
                    commentersProposedEndStatus: commentThreadInputValue.commentersProposedEndStatus,
                    commentersProposedText: commentThreadInputValue.commentersProposedText,
                    results: commentThreadInputValue.results,
                    commentCount: commentThreadInputValue.commentCount
                };
                var commentThread = new CommentThreadSimple(commentThreadFromFormInput);
                commentThreads.push(commentThread);
            });
        }
        return commentThreads;
    };
    CommentRoundCommentThreadsComponent.prototype.save = function () {
        var _this = this;
        var removeOrphans = false;
        if (this.isEditorOrSuperUser && this.commentRound.status === 'INCOMPLETE') {
            removeOrphans = true;
        }
        var commentThreadsToBeUpdated = this.mapCommentThreads(this.commentThreadForms).map(function (ct) { return ct.serialize(); });
        var save = function () {
            return _this.dataService.createCommentThreads(_this.commentRound.id, commentThreadsToBeUpdated, removeOrphans).pipe(tap(function () {
                _this.refreshCommentThreads.emit();
                _this.editableService.cancel();
                _this.changeTabControl.emit(false);
            }));
        };
        return save();
    };
    CommentRoundCommentThreadsComponent.prototype.commentIdentity = function (index, item) {
        return item.id;
    };
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "numberOfExpanded", {
        get: function () {
            var _this = this;
            return this.filterTopLevelComments(this.activeThreadComments)
                .filter(function (comment) { return comment.expanded && _this.hasChildComments(comment.id); }).length;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentThreadsComponent.prototype.hasChildComments = function (parentCommentId) {
        return this.activeThreadComments.filter(function (comment) { return comment.parentComment && comment.parentComment.id === parentCommentId; }).length > 0;
    };
    Object.defineProperty(CommentRoundCommentThreadsComponent.prototype, "numberOfCollapsed", {
        get: function () {
            return this.activeThreadComments.filter(function (comment) { return !comment.expanded; }).length;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundCommentThreadsComponent.prototype.hasExpanded = function () {
        return this.numberOfExpanded > 0;
    };
    CommentRoundCommentThreadsComponent.prototype.hasCollapsed = function () {
        return this.numberOfCollapsed > 0;
    };
    CommentRoundCommentThreadsComponent.prototype.expandAll = function () {
        this.activeThreadComments.forEach(function (comment) {
            comment.expanded = true;
        });
    };
    CommentRoundCommentThreadsComponent.prototype.collapseAll = function () {
        this.activeThreadComments.forEach(function (comment) {
            comment.expanded = false;
        });
    };
    CommentRoundCommentThreadsComponent.prototype.showExpandAll = function () {
        return this.hasCollapsed();
    };
    CommentRoundCommentThreadsComponent.prototype.showCollapseAll = function () {
        return this.hasExpanded();
    };
    CommentRoundCommentThreadsComponent.prototype.hasHierarchy = function () {
        return this.activeThreadComments.filter(function (comment) { return comment.parentComment !== undefined; }).length > 0;
    };
    CommentRoundCommentThreadsComponent.prototype.allowExpandAllAndCollapseAll = function () {
        return this.hasHierarchy && this.activeThreadComments.length <= 500;
    };
    CommentRoundCommentThreadsComponent.prototype.getCommentThreadUserDisplayName = function (user) {
        if (user) {
            var userDisplayName = user.getDisplayName();
            if (userDisplayName.length > 0) {
                return userDisplayName;
            }
            else {
                return this.translateService.instant('Removed user');
            }
        }
        else {
            return '-';
        }
    };
    return CommentRoundCommentThreadsComponent;
}());
export { CommentRoundCommentThreadsComponent };
