<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading">
  <div class="yti-tool-front-page" *ngIf="!loading">
    <div class="row mb-12">
      <div class="col-md-9">
        <p translate>Frontpage long description</p>
      </div>

      <div *ngIf="canCreateCommentRound()" class="col-md-3 float-right mb-4 d-inline-block">
        <button id="create_commentround_button"
                type="button"
                class="btn btn-action float-right"
                (click)="createNewCommentRound()">
          <span translate>Add new comment round</span>
        </button>
      </div>

      <div *ngIf="isLoggedIn()" class="col-md-12">
        <div><span class="search-label" translate>Search for comment rounds</span>
          <app-information-symbol [infoText]="'INFO_TEXT_FRONTPAGE_SEARCH'"></app-information-symbol>
        </div>
        <div class="col-md-6 mb-3" style="padding-left: 0px">
          <div class="input-group input-group-lg input-group-search">
            <input class="form-control"
                   #commentRoundSearch
                   id="search_box_input"
                   type="text"
                   [ngClass]="{'is-invalid': searchError}"
                   [(ngModel)]="searchTerm"
                   placeholder="{{'Search term' | translate}}"/>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div *ngIf="searchInProgress" class="content-box">
          <app-ajax-loading-indicator></app-ajax-loading-indicator>
        </div>

        <div class="content-box result-list-container" *ngIf="!searchInProgress">

          <div class="row mb-4">
            <div class="col-md-12 result-list-filter-row">
            <span class="search-label search-label-inline with-info" translate>Filter results</span>
              <app-information-symbol [infoText]="'INFO_TEXT_FRONTPAGE_FILTER_RESULTS'"></app-information-symbol>

              <div class="result-list-filter-dropdowns">
                <app-filter-dropdown id="integration_filter_dropdown"
                                     [filterSubject]="containerType$"
                                     [options]="containerTypeOptions"></app-filter-dropdown>

                <app-filter-dropdown id="organization_filter_dropdown"
                                     [filterSubject]="organization$"
                                     [options]="organizationOptions"></app-filter-dropdown>

                <app-filter-dropdown id="status_filter_dropdown"
                                     [filterSubject]="status$"
                                     [options]="statusOptions"></app-filter-dropdown>
              </div>
            </div>
          </div>

          <div class="col-md-12 mb-4 result-list-filter-row">
            <span *ngIf="commentRounds.length == 0" translate>Did not find any comment rounds.</span>
            <span *ngIf="commentRounds.length == 1">1 {{'comment round' | translate}}</span>
            <span *ngIf="commentRounds.length > 1">{{commentRounds.length}} {{'comment rounds' | translate}}</span>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div *ngFor="let commentRound of commentRounds" class="result-list-item">
                <app-comment-round-listitem [commentRound]="commentRound"></app-comment-round-listitem>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
