
    <div id="{{resource.id + '_resource_link'}}"
         class="resource-result" *ngIf="resource.expanded" [class.last]="theLast">
      <div class="scrollable-content">
        <app-status class="status" [status]="resource.status"></app-status>
        <span class="title" (click)="emitSelectResourceEvent(resource)">{{ resource.getDisplayName(languageService, true) }}</span>
        <div *ngIf="resource.getDescription(languageService, true) as descriptionText"
             class="description-container">
          <app-expandable-text [text]="descriptionText" [rows]="2" [captureClick]="true"></app-expandable-text>
        </div>
        <div>
          <span translate>Last modification</span><span>: {{ resource.modifiedDisplayValue }}</span>
        </div>
        <div>
          <a class="uri"
             href="{{configurationService.getUriWithEnv(resource.uri)}}" target="_blank" rel="noopener noreferrer">
            {{ configurationService.getUriWithEnv(resource.uri) }}
          </a>
        </div>
      </div>
    </div>
  