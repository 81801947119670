var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import { ConfigurationService } from '../../services/configuration.service';
import { LanguageService } from '../../services/language.service';
import { DataService } from '../../services/data.service';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { BehaviorSubject } from 'rxjs';
import { comparingLocalizable, comparingPrimitive } from 'yti-common-ui/utils/comparator';
var IntegrationResourceVirtualScrollerComponent = /** @class */ (function () {
    function IntegrationResourceVirtualScrollerComponent(configurationService, languageService, dataService) {
        this.configurationService = configurationService;
        this.languageService = languageService;
        this.dataService = dataService;
        this.items = new BehaviorSubject([]);
        this.status$ = new BehaviorSubject(null);
        this.search$ = new BehaviorSubject('');
        this.buffer = [];
        this.loading = false;
        this.previousRequestGotZeroResults = false; // this variable is used to stop an eternal loop in case of 0 results (due to filtering)
        this.thePageSize = 200;
        this.selectResourceEvent = new EventEmitter();
    }
    // pick all search results as selections
    // this works separately from the virtual scrolling by fetching
    // (or often re-fetching) the data and adding all of them to the selections
    IntegrationResourceVirtualScrollerComponent.prototype.pickAll = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var count, results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading = true;
                        count = 0;
                        _a.label = 1;
                    case 1:
                        if (!true) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.dataService.getResourcesPaged(this.containerType, this.containerUri, this.language, this.thePageSize, count, this.status$.value, this.search$.value, this.restricts)];
                    case 2:
                        results = _a.sent();
                        results.forEach(function (x) {
                            // mark any item already downloaded as hidden (unexpanded)
                            _this.buffer
                                .filter(function (item) { return item.uri === x.uri; })
                                .forEach(function (item) {
                                item.expanded = false;
                            });
                            // add as selected resource
                            _this.selectResourceEvent.emit(x);
                        });
                        count += results.length;
                        if (results.length !== this.thePageSize) {
                            return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 1];
                    case 3:
                        this.virtualScroller.invalidateAllCachedMeasurements();
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    IntegrationResourceVirtualScrollerComponent.prototype.fetchMore = function (event) {
        var _this = this;
        if (this.loading || this.previousRequestGotZeroResults) {
            return;
        }
        if (event.endIndex !== this.buffer.length - 1) {
            return;
        }
        this.loading = true;
        this.fetchNextChunk(this.buffer.length, this.thePageSize).then(function (chunk) {
            for (var _i = 0, chunk_1 = chunk; _i < chunk_1.length; _i++) {
                var resource = chunk_1[_i];
                var found = false;
                for (var _a = 0, _b = _this.selectedResources$.value; _a < _b.length; _a++) {
                    var selectedResource = _b[_a];
                    if (resource.uri === selectedResource.uri) {
                        found = true;
                    }
                }
                if (!found) {
                    if (!resource.expanded) {
                        _this.virtualScroller.invalidateCachedMeasurementForItem(resource); // need to call every time expanded-boolean changes
                    }
                    resource.expanded = true;
                }
                else {
                    if (resource.expanded) {
                        _this.virtualScroller.invalidateCachedMeasurementForItem(resource); // need to call every time expanded-boolean changes
                    }
                    resource.expanded = false;
                }
            }
            _this.buffer = _this.buffer.concat(chunk.sort(comparingPrimitive(function (integrationResource) { return _this.languageService.isLocalizableEmpty(integrationResource.prefLabel); })
                .andThen(comparingPrimitive(function (integrationResource) {
                return _this.languageService.isLocalizableEmpty(integrationResource.prefLabel) ? integrationResource.localName : null;
            }))
                .andThen(comparingPrimitive(function (integrationResource) {
                return _this.languageService.isLocalizableEmpty(integrationResource.prefLabel) && integrationResource.localName ?
                    integrationResource.uri.toLowerCase() : null;
            }))
                .andThen(comparingLocalizable(_this.languageService, function (integrationResource) { return integrationResource.prefLabel ? integrationResource.prefLabel : {}; }))));
            if (chunk.length === 0) {
                _this.previousRequestGotZeroResults = true;
            }
            else {
                _this.previousRequestGotZeroResults = false;
            }
            _this.loading = false;
        }, function () {
            _this.loading = false;
        });
    };
    IntegrationResourceVirtualScrollerComponent.prototype.fetchNextChunk = function (skip, limit) {
        return this.dataService.getResourcesPaged(this.containerType, this.containerUri, this.language, limit, skip, this.status$.value, this.search$.value, this.restricts);
    };
    IntegrationResourceVirtualScrollerComponent.prototype.emitSelectResourceEvent = function (resource) {
        this.virtualScroller.invalidateCachedMeasurementForItem(resource);
        this.selectResourceEvent.emit(resource);
    };
    return IntegrationResourceVirtualScrollerComponent;
}());
export { IntegrationResourceVirtualScrollerComponent };
