/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-round-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./comment-round-status.component";
var styles_CommentRoundStatusComponent = [i0.styles];
var RenderType_CommentRoundStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentRoundStatusComponent, data: {} });
export { RenderType_CommentRoundStatusComponent as RenderType_CommentRoundStatusComponent };
export function View_CommentRoundStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], [[2, "bg-danger", null], [2, "bg-pending", null], [2, "bg-warning", null], [2, "bg-success", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.danger; var currVal_1 = _co.awaitOrIncomplete; var currVal_2 = _co.warning; var currVal_3 = _co.success; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.status)); _ck(_v, 1, 0, currVal_4); }); }
export function View_CommentRoundStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-commentround-status", [], null, null, null, View_CommentRoundStatusComponent_0, RenderType_CommentRoundStatusComponent)), i1.ɵdid(1, 49152, null, 0, i3.CommentRoundStatusComponent, [], null, null)], null, null); }
var CommentRoundStatusComponentNgFactory = i1.ɵccf("app-commentround-status", i3.CommentRoundStatusComponent, View_CommentRoundStatusComponent_Host_0, { status: "status" }, {}, []);
export { CommentRoundStatusComponentNgFactory as CommentRoundStatusComponentNgFactory };
