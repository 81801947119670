<div class="content-box">

  <h1>Tietoa Kommentit-työkalusta</h1>

  <p>
    Yhteentoimivuusalustan Kommentit-palvelussa voi laatia kommentointikierroksia Sanastot-, Koodistot- ja Tietomallit-työkalujen tietosisällöistä.
    Lisäksi palvelun kautta voi kommentoida kommentointikierrokselle lähetettyjä tietosisältöjä sekä lukea muiden antamia kommentteja.
  </p>

  <h2>Kommentit-työkalu on tarkoitettu</h2>

  <ul>
    <li>organisaation sisällöistä (sanastot, koodistot, tietomallit) vastaaville, jotka haluavat palautetta työstään</li>
    <li>organisaatioiden käyttäjille, jotka voivat kommentoida sisältöjä kommentointikierroksilla</li>
  </ul>

  <h2>Kommentit-työkalulla</h2>

  <ul>
    <li>pääkäyttäjä voi luoda kommentointikierroksen muiden työkalujen sisällöstä, vaikkapa sanastoon lisättävistä käsitteistä</li>
    <li>käyttäjä voi kommentoida kierroksen sisältöjä, niiden tiloja ja ehdottaa uusia sisältöjä</li>
    <li>käyttäjä voi käydä keskustelua muiden kierrokselle osallistuvien kanssa sisällöistä</li>
    <li>saadut kommentit voidaan ladata Exceliin jatkotyöskentelyä varten</li>
  </ul>

  <h2>Hyödyt käyttäjille</h2>

  <ul>
    <li>Kommentointikierroksen sisällöt voidaan hakea kätevästi työkalusta, jonne ne on syötetty</li>
    <li>Kommentointi tapahtuu kirjautumisen takana, jolloin kaikki kommentit annetaan omalla nimellä</li>
    <li>Sisällöistä voi käydä helposti keskustelua muiden käyttäjien kanssa</li>
    <li>Käyttäjät pysyvät ajan tasalla esim. sanastotyön tilanteesta, kun sisältöjä avataan kommentoitavaksi</li>
  </ul>

  <h2>Miten voin liittyä työkalun käyttäjäksi?</h2>

  <p>
    Kommentit-työkalua voi käyttää verkossa osoitteesta <a id="service_link" rel="noopener noreferrer" href="https://kommentit.suomi.fi" target="_blank">https://kommentit.suomi.fi</a>.
    Työkalun käyttö edellyttää organisaatiokohtaisia käyttöoikeuksia tai sähköpostikutsua kommentointikierrokselle.
  </p>

  <h2>Katso myös</h2>
  <ul>
    <li>
      <a id="interoperability_site_link" rel="noopener noreferrer" href="https://yhteentoimiva.suomi.fi" target="_blank">yhteentoimiva.suomi.fi</a>
    </li>
    <li>
      Yhteentoimivuusalustan julkinen dokumentaatio Confluencessa
      <ul>
        <li><a id="service_initialization_link" rel="noopener noreferrer" href="https://wiki.dvv.fi/pages/viewpage.action?pageId=21779517" target="_blank">Palvelun käyttöönotto</a></li>
        <li><a id="service_instructions_link" rel="noopener noreferrer" href="https://wiki.dvv.fi/pages/viewpage.action?pageId=21783347" target="_blank">Käyttöohjeet</a></li>
      </ul>
    </li>
    <li>
      <a id="instructions_to_comment_round_link" rel="noopener noreferrer" href="https://wiki.dvv.fi/pages/viewpage.action?pageId=53418000" target="_blank">Käyttöohje kierrokselle osallistuville </a>
    </li>
   </ul>

   <h2>Ota yhteyttä</h2>

   <p>Sähköpostilla osoitteeseen yhteentoimivuus[at]dvv.fi</p>

</div>
