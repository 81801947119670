import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentRound } from '../../entities/commentround';
import { DataService } from '../../services/data.service';
import { Location } from '@angular/common';
import { LocationService } from '../../services/location.service';
import { AuthorizationManager } from '../../services/authorization-manager';
import { CommentsConfirmationModalService } from '../common/confirmation-modal.service';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { LanguageService } from '../../services/language.service';
import { ConfigurationService } from '../../services/configuration.service';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { CommentsErrorModalService } from '../common/error-modal.service';
import { EditableService } from '../../services/editable.service';
import { UserService } from 'yti-common-ui/services/user.service';
import { MessagingService } from '../../services/messaging-service';
var CommentRoundComponent = /** @class */ (function () {
    function CommentRoundComponent(languageService, configurationService, router, route, dataService, messagingService, locationService, location, authorizationManager, confirmationModalService, errorModalService, editableService, userService) {
        this.languageService = languageService;
        this.configurationService = configurationService;
        this.router = router;
        this.route = route;
        this.dataService = dataService;
        this.messagingService = messagingService;
        this.locationService = locationService;
        this.location = location;
        this.authorizationManager = authorizationManager;
        this.confirmationModalService = confirmationModalService;
        this.errorModalService = errorModalService;
        this.editableService = editableService;
        this.userService = userService;
        this.activeThreadId = undefined;
        this.hasSubscription = undefined;
    }
    CommentRoundComponent.prototype.ngOnInit = function () {
        var _this = this;
        var commentRoundIdentifier = this.route.snapshot.params.round;
        this.activeThreadId = this.route.snapshot.params.thread;
        if (!commentRoundIdentifier) {
            throw new Error("Illegal route, commentRound: '" + commentRoundIdentifier + "'");
        }
        this.dataService.getCommentRound(commentRoundIdentifier).subscribe(function (commentRound) {
            _this.commentRound = commentRound;
            _this.checkSubscription();
            _this.locationService.atCommentRoundPage(commentRound);
        });
        this.dataService.getCommentRoundCommenterComments(commentRoundIdentifier).subscribe(function (comments) {
            _this.myComments = comments;
        });
        this.dataService.getCommentRoundCommentThreads(commentRoundIdentifier).subscribe(function (commentThreads) {
            _this.commentThreads = commentThreads;
        });
    };
    CommentRoundComponent.prototype.getInitialTabId = function () {
        if (this.activeThreadId) {
            return 'commentround_resources_tab';
        }
        return 'commentround_information_tab';
    };
    Object.defineProperty(CommentRoundComponent.prototype, "showStartCommenting", {
        get: function () {
            return (this.authorizationManager.canCreateComment(this.commentRound) &&
                this.commentRound.status === 'INPROGRESS' &&
                this.tabSet && this.tabSet.activeId !== 'commentround_comments_tab');
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundComponent.prototype.goToOwnComments = function () {
        this.tabSet.activeId = 'commentround_comments_tab';
    };
    CommentRoundComponent.prototype.goToResources = function () {
        this.tabSet.activeId = 'commentround_resources_tab';
    };
    CommentRoundComponent.prototype.startCommentRound = function () {
        var _this = this;
        this.confirmationModalService.startCommentRound()
            .then(function () {
            _this.commentRound.status = 'INPROGRESS';
            _this.dataService.updateCommentRound(_this.commentRound.serialize()).subscribe(function (commentRound) {
                _this.commentRound = new CommentRound(commentRound);
            }, function (error) {
                _this.errorModalService.openSubmitError(error);
            });
        }, ignoreModalClose);
    };
    CommentRoundComponent.prototype.closeCommentRound = function () {
        var _this = this;
        this.confirmationModalService.closeCommentRound()
            .then(function () {
            _this.commentRound.status = 'CLOSED';
            _this.dataService.updateCommentRound(_this.commentRound.serialize()).subscribe(function (commentRound) {
                _this.commentRound = new CommentRound(commentRound);
            }, function (error) {
                _this.errorModalService.openSubmitError(error);
            });
        }, ignoreModalClose);
    };
    CommentRoundComponent.prototype.deleteCommentRound = function () {
        var _this = this;
        this.confirmationModalService.deleteCommentRound()
            .then(function () {
            _this.dataService.deleteCommentRound(_this.commentRound).subscribe(function (commentRound) {
                _this.router.navigate(['frontpage']);
            }, function (error) {
                _this.errorModalService.openSubmitError(error);
            });
        }, ignoreModalClose);
    };
    Object.defineProperty(CommentRoundComponent.prototype, "loading", {
        get: function () {
            return this.commentRound == null || this.myComments == null || this.commentThreads == null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundComponent.prototype, "isEditorOrSuperUser", {
        get: function () {
            return this.authorizationManager.user.superuser || this.commentRound.user.id === this.authorizationManager.user.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundComponent.prototype, "toolType", {
        get: function () {
            return this.commentRound.source.containerType;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundComponent.prototype.onTabChange = function (event) {
        var _this = this;
        if (this.blockTabChange) {
            event.preventDefault();
            this.confirmationModalService.openEditInProgress()
                .then(function () {
                _this.blockTabChange = false;
                _this.editableService.cancel();
                _this.tabSet.activeId = event.nextId;
            }, ignoreModalClose);
        }
        else {
            this.tabSet.activeId = event.nextId;
        }
    };
    Object.defineProperty(CommentRoundComponent.prototype, "canStartCommentRound", {
        get: function () {
            return this.commentRound.status === 'INCOMPLETE' && this.commentThreads && this.commentThreads.length > 0 && this.isEditorOrSuperUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundComponent.prototype, "canEndCommenting", {
        get: function () {
            return this.commentRound.status === 'INPROGRESS' && this.isEditorOrSuperUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundComponent.prototype, "canDeleteCommentRound", {
        get: function () {
            return this.authorizationManager.canDeleteCommentRound(this.commentRound);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundComponent.prototype, "showExcelExport", {
        get: function () {
            return this.authorizationManager.canExportExcel(this.commentRound);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundComponent.prototype, "exportUrl", {
        get: function () {
            return this.commentRound.url + '/?format=excel';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundComponent.prototype, "showMenu", {
        get: function () {
            return this.isEditorOrSuperUser || this.canSubscribe;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundComponent.prototype.checkSubscription = function () {
        var _this = this;
        if (this.canSubscribe) {
            this.messagingService.getSubscription(this.commentRound.uri).subscribe(function (resource) {
                if (resource) {
                    _this.hasSubscription = true;
                }
                else {
                    _this.hasSubscription = false;
                }
            });
        }
    };
    Object.defineProperty(CommentRoundComponent.prototype, "canSubscribe", {
        get: function () {
            return this.configurationService.isMessagingEnabled &&
                this.userService.isLoggedIn() &&
                this.userService.user.containerUri === undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundComponent.prototype, "canAddSubscription", {
        get: function () {
            return this.canSubscribe && !this.hasSubscription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommentRoundComponent.prototype, "canRemoveSubscription", {
        get: function () {
            return this.canSubscribe && this.hasSubscription === true;
        },
        enumerable: true,
        configurable: true
    });
    CommentRoundComponent.prototype.addSubscription = function () {
        var _this = this;
        this.confirmationModalService.openAddSubscription()
            .then(function () {
            _this.messagingService.addSubscription(_this.commentRound.uri, 'commentround').subscribe(function (success) {
                if (success) {
                    _this.hasSubscription = true;
                }
                else {
                    _this.hasSubscription = false;
                    _this.errorModalService.open('Submit error', 'Adding subscription failed.', null);
                }
            });
        }, ignoreModalClose);
    };
    CommentRoundComponent.prototype.removeSubscription = function () {
        var _this = this;
        this.confirmationModalService.openRemoveSubscription()
            .then(function () {
            _this.messagingService.deleteSubscription(_this.commentRound.uri).subscribe(function (success) {
                if (success) {
                    _this.hasSubscription = false;
                }
                else {
                    _this.hasSubscription = true;
                    _this.errorModalService.open('Submit error', 'Subscription deletion failed.', null);
                }
            });
        }, ignoreModalClose);
    };
    CommentRoundComponent.prototype.changeTabControl = function (blockChange) {
        this.blockTabChange = blockChange;
    };
    CommentRoundComponent.prototype.refreshCommentRound = function () {
        var _this = this;
        this.dataService.getCommentRound(this.commentRound.id).subscribe(function (commentRound) {
            _this.commentRound = commentRound;
        });
    };
    CommentRoundComponent.prototype.refreshCommentThreads = function () {
        var _this = this;
        this.refreshCommentRound();
        this.dataService.getCommentRoundCommentThreads(this.commentRound.id).subscribe(function (commentThreads) {
            _this.commentThreads = commentThreads;
        });
    };
    CommentRoundComponent.prototype.refreshMyComments = function () {
        var _this = this;
        this.dataService.getCommentRoundCommenterComments(this.commentRound.id).subscribe(function (comments) {
            _this.myComments = comments;
            _this.goToResources();
        });
    };
    CommentRoundComponent.prototype.isMessagingEnabled = function () {
        return this.configurationService.isMessagingEnabled;
    };
    return CommentRoundComponent;
}());
export { CommentRoundComponent };
