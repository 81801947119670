/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./cookie-cleanup.component";
import * as i2 from "ngx-cookie-service";
var styles_CookieCleanupComponent = [];
var RenderType_CookieCleanupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CookieCleanupComponent, data: {} });
export { RenderType_CookieCleanupComponent as RenderType_CookieCleanupComponent };
export function View_CookieCleanupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row content-box"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cookies cleaned!"]))], null, null); }
export function View_CookieCleanupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_CookieCleanupComponent_0, RenderType_CookieCleanupComponent)), i0.ɵdid(1, 114688, null, 0, i1.CookieCleanupComponent, [i2.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookieCleanupComponentNgFactory = i0.ɵccf("ng-component", i1.CookieCleanupComponent, View_CookieCleanupComponent_Host_0, {}, {}, []);
export { CookieCleanupComponentNgFactory as CookieCleanupComponentNgFactory };
