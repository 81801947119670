
    <dl *ngIf="show">
      <dt>
        <label *ngIf="showLabel">{{label}}</label>
        <app-information-symbol *ngIf="showLabel" [infoText]="infoText"></app-information-symbol>
        <app-required-symbol *ngIf="required && editing"></app-required-symbol>
      </dt>
      <dd>
        <div *ngIf="editing">
          <div class="input-group">
            <input [id]="id"
                   class="form-control"
                   placeholder="yyyy-mm-dd"
                   [formControl]="control"
                   ngbDatepicker
                   #date="ngbDatepicker">
            <button [id]="id + '_toggle_calendar_button'"
                    class="input-group-addon icon-calendar calendar-selector"
                    (click)="date.toggle()"
                    type="button"></button>
          </div>
          <app-error-messages [id]="id + '_error_messages'" [control]="parentControl"></app-error-messages>
        </div>
        <span *ngIf="!editing">{{displayName}}</span>
      </dd>
    </dl>
  