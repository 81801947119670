import { ControlValueAccessor } from '@angular/forms';
import { selectableProposedStatuses } from '../../entities/proposed-status';
import { EditableService } from '../../services/editable.service';
var ProposedStatusTableDropdownComponent = /** @class */ (function () {
    function ProposedStatusTableDropdownComponent(editableService) {
        this.editableService = editableService;
        this.placement = 'bottom-left';
        this.restrict = false;
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
    }
    Object.defineProperty(ProposedStatusTableDropdownComponent.prototype, "editing", {
        get: function () {
            return (this.editableService.editing || this.isEditing) && !this.restrict;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProposedStatusTableDropdownComponent.prototype, "options", {
        get: function () {
            return selectableProposedStatuses;
        },
        enumerable: true,
        configurable: true
    });
    ProposedStatusTableDropdownComponent.prototype.isSelected = function (option) {
        if (this.selectedStatus == null || this.selectedStatus === 'NOSTATUS') {
            this.selectedStatus = this.defaultStatus;
            this.propagateChange(this.selectedStatus);
        }
        return this.selectedStatus === option;
    };
    ProposedStatusTableDropdownComponent.prototype.select = function (option) {
        this.selectedStatus = option;
        this.propagateChange(option);
    };
    ProposedStatusTableDropdownComponent.prototype.writeValue = function (obj) {
        this.selectedStatus = obj;
    };
    ProposedStatusTableDropdownComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    ProposedStatusTableDropdownComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return ProposedStatusTableDropdownComponent;
}());
export { ProposedStatusTableDropdownComponent };
