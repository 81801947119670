
    <dl *ngIf="show">
      <dt *ngIf="label">
        <label>{{ label }}</label>
        <app-information-symbol [infoText]="infoText"></app-information-symbol>
        <app-required-symbol *ngIf="showRequired && required && editing"></app-required-symbol>
      </dt>
      <dd>
        <div *ngIf="editing" class="form-group">
          <textarea [id]="id"
                    autosize
                    class="form-control"
                    [ngClass]="{ 'is-invalid': !valid}"
                    [formControl]="control"></textarea>
          <app-error-messages [id]="id + '_error_messages'" [control]="parentControl"></app-error-messages>
        </div>
        <div class="text-content-wrap" *ngIf="!editing">{{ control.value }}</div>
      </dd>
    </dl>
  