
    <dl>
      <dt>
        <label *ngIf="label">{{label}}</label>
        <app-information-symbol *ngIf="infoText" [infoText]="infoText"></app-information-symbol>
      </dt>
      <dd *ngIf="valueLanguages.length > 0">
        <div class="localized" *ngFor="let language of valueLanguages">
          <div *ngIf="showLanguageIdentifier(language)" class="language">{{ language.toUpperCase()}}</div>
          <div *ngIf="showLanguageIdentifier(language)" class="localization">{{ value[language] }}</div>
          <div *ngIf="!showLanguageIdentifier(language)" class="localization-without-id">{{ value[language] }}</div>
        </div>
      </dd>
      <dd *ngIf="valueLanguages.length === 0">
        <span>-</span>
      </dd>
    </dl>
  