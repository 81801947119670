/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown";
import * as i3 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown-config";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/yti-common-ui/components/status.component.ngfactory";
import * as i6 from "yti-common-ui/components/status.component";
import * as i7 from "@angular/forms";
import * as i8 from "./proposed-status-dropdown";
import * as i9 from "../../services/editable.service";
var styles_ProposedStatusTableDropdownComponent = [];
var RenderType_ProposedStatusTableDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProposedStatusTableDropdownComponent, data: {} });
export { RenderType_ProposedStatusTableDropdownComponent as RenderType_ProposedStatusTableDropdownComponent };
function View_ProposedStatusTableDropdownComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "dropdown-item"]], [[8, "id", 0], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit + "_") + _co.id); var currVal_1 = _co.isSelected(_v.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_v.context.$implicit)); _ck(_v, 1, 0, currVal_2); }); }
function View_ProposedStatusTableDropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(1, 212992, null, 2, i2.NgbDropdown, [i0.ChangeDetectorRef, i3.NgbDropdownConfig, i4.DOCUMENT, i0.NgZone], { placement: [0, "placement"] }, null), i0.ɵqud(335544320, 1, { _menu: 0 }), i0.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 5, "button", [["aria-haspopup", "true"], ["class", "btn btn-dropdown dropdown-toggle"], ["ngbDropdownToggle", ""]], [[8, "id", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[2, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i0.ɵeld(7, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(8, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i0.ɵdid(11, 16384, [[1, 4]], 0, i2.NgbDropdownMenu, [i2.NgbDropdown, i0.ElementRef, i0.Renderer2], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProposedStatusTableDropdownComponent_2)), i0.ɵdid(13, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.placement; _ck(_v, 1, 0, currVal_1); var currVal_4 = ""; _ck(_v, 8, 0, currVal_4); var currVal_9 = _co.options; _ck(_v, 13, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = ("selected_proposed_status_" + _co.id); var currVal_3 = i0.ɵnov(_v, 5).dropdown.isOpen(); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = _co.selectedStatus; _ck(_v, 9, 0, currVal_5); var currVal_6 = true; var currVal_7 = i0.ɵnov(_v, 11).dropdown.isOpen(); var currVal_8 = i0.ɵnov(_v, 11).placement; _ck(_v, 10, 0, currVal_6, currVal_7, currVal_8); }); }
function View_ProposedStatusTableDropdownComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["-"]))], null, null); }
function View_ProposedStatusTableDropdownComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-status", [["class", "status"]], null, null, null, i5.View_StatusComponent_0, i5.RenderType_StatusComponent)), i0.ɵdid(1, 49152, null, 0, i6.StatusComponent, [], { status: [0, "status"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedStatus; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProposedStatusTableDropdownComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProposedStatusTableDropdownComponent_4)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProposedStatusTableDropdownComponent_5)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedStatus === "NOSTATUS"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.selectedStatus !== "NOSTATUS"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProposedStatusTableDropdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProposedStatusTableDropdownComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProposedStatusTableDropdownComponent_3)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editing; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.editing; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ProposedStatusTableDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-proposed-status-table-dropdown", [], null, null, null, View_ProposedStatusTableDropdownComponent_0, RenderType_ProposedStatusTableDropdownComponent)), i0.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.ProposedStatusTableDropdownComponent]), i0.ɵdid(2, 49152, null, 0, i8.ProposedStatusTableDropdownComponent, [i9.EditableService], null, null)], null, null); }
var ProposedStatusTableDropdownComponentNgFactory = i0.ɵccf("app-proposed-status-table-dropdown", i8.ProposedStatusTableDropdownComponent, View_ProposedStatusTableDropdownComponent_Host_0, { id: "id", placement: "placement", isEditing: "isEditing", restrict: "restrict", defaultStatus: "defaultStatus" }, {}, []);
export { ProposedStatusTableDropdownComponentNgFactory as ProposedStatusTableDropdownComponentNgFactory };
